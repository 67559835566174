import { BuySellMarketingChannelImpressions } from '../../services/generated-api-client';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import useViewsPerChannel from './useViewsPerChannel';
import ImpressionsContainer from '../Impressions/ImpressionsContainer';
import ApplicationOptions from './../../utils/options';
import { ReactComponent as QuestionMark } from './questionMarkButton.svg';
import React, { useState } from 'react';

interface Props {
  channels: BuySellMarketingChannelImpressions[];
}

export default function ViewsPerChannel({ channels }: Props): JSX.Element {
  const { t } = useTranslation('views-per-channel');
  const { getChartData, getChartOptions, getTotalViews, getChannels } = useViewsPerChannel();
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null);

  const percentageValue = function (value: number, total: number): string {
    return (value ? ((value / total) * 100).toFixed(0) : '0') + '%';
  };

  const handleMouseEnter = (label: string): void => {
    setVisibleTooltip(label);
  };

  const handleMouseLeave = (): void => {
    setVisibleTooltip(null);
  };

  const getTooltipText = (label: string): string => {
    switch (label) {
      case 'Sociala medier':
        return t('tooltip-social-media');
      case 'Intern trafik':
        return t('tooltip-internal-traffic');
      case 'Övriga källor':
        return t('tooltip-other-sources');
      default:
        return '';
    }
  };

  return (
    <ImpressionsContainer title={t('title')}>
      {ApplicationOptions.theme === 'svenska-maklarhuset' ? (
        <span className="description-text">
          Samlad data över hur besökare hittar till din bostadssida på smh.se. <br />
        </span>
      ) : (
        <span className="description-text">
          Samlad data över hur besökare hittar till din bostadssida på vår webbplats. <br />
        </span>
      )}
      <div className="container">
        <div className="chart-cc">
          <Doughnut className="chart" data={getChartData(channels)} options={getChartOptions()} />
          <div className="total-views">
            {getTotalViews(channels)} <br />
            <span className="total-views-text">{t('total-views')}</span>
          </div>
        </div>
        <div className="labels">
          {getChannels(channels).map((label) => (
            <div key={label.name} className="label">
              <span className="square" style={{ background: label.color }}></span>
              <div className="label-row">
                <span className="label-name">
                  {label.name}
                  {['Sociala medier', 'Intern trafik', 'Övriga källor'].includes(label.name) && (
                    <>
                      <QuestionMark
                        className="info-icon"
                        onMouseEnter={(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => handleMouseEnter(label.name)}
                        onMouseLeave={handleMouseLeave}
                        aria-hidden="true"
                      />
                      {visibleTooltip === label.name && <div className="tooltip">{getTooltipText(label.name)}</div>}
                    </>
                  )}
                </span>
                <span className="label-value">{label.value}</span>
                <span className="label-percentage">({percentageValue(label.value, getTotalViews(channels))})</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ImpressionsContainer>
  );
}
