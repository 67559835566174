import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Recommendation from '../../components/Recommendation/Recommendation';
import ChecklistList from '../../components/Lists/ChecklistList';
import DocumentList from '../../components/Lists/DocumentList';
import StepDescription from '../../components/StepDescription/StepDescription';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import Event from '../../components/Event/Event';
import ApiClient from '../../services/api-client';
import { BuySellSellSellContractView } from '../../services/generated-api-client';
import ErrorPage from '../Error/ErrorPage';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import ApplicationOptions from './../../utils/options';
import FormList from '../../components/Lists/FormList';

export default function SellContractPage(): JSX.Element {
  const { estateId } = useParams();
  const { t: tProcess } = useTranslation('process');
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<BuySellSellSellContractView>();
  const step = 'Contract';

  useEffect(() => {
    ApiClient.authenticated
      .getSellContractView(estateId!, { cancelToken: 'getSellContractView' })
      .then((response) => setContent(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getSellContractView');
    };
  }, [estateId]);

  const renameBeoOffice = (): boolean => {
    //When running in test, ApplicationOptions fails, to pass test we check for JEST_WORKER_ID and return false
    if (process.env.JEST_WORKER_ID !== undefined) return false;

    if (ApplicationOptions.theme === 'erik-olsson') return true;
    else return false;
  };

  const getSubText = (text: string | undefined): string | undefined => {
    if (renameBeoOffice()) {
      if (text && text.indexOf(',') > 0) {
        const strSplit = text.split(',');
        return 'Erik Olsson Fastighetsförmedling, ' + strSplit[1];
      }
    }
    return text;
  };

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.Contract')}</h1>

          <div className="process-content">
            {!!content.SigningEvent && (
              <Event
                title={content.SigningEvent.Title}
                subText={getSubText(content.SigningEvent.SubText)}
                pushText={content.SigningEvent.PushText}
                subTextLink={content.SigningEvent.SubTextLink}
              />
            )}

            {!!content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}

            {!!content.Forms?.length && <FormList forms={content.Forms} title={tProcess('forms-title')} />}

            <Recommendation />
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <ErrorPage text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
