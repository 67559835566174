import { useEffect, useState } from 'react';

interface AsyncError {
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

export const useAsyncError = (): AsyncError => {
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (error != null) {
      throw error;
    }
  }, [error]);

  return { setError };
};
