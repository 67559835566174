import { TFunction } from 'i18next';
import { BuySellBid } from '../services/generated-api-client';

export const getChartColors = (): string[] => {
  const style = getComputedStyle(document.body);
  const colors = [];

  let numberOfColors = 0;
  while (true) {
    const color = style.getPropertyValue(`--chart-data-color-${numberOfColors + 1}`);
    if (color === '') break;

    numberOfColors++;
    colors.push(color);
  }

  return colors;
};

export const bidsWithUser = (bids: BuySellBid[], t: TFunction): BuySellBid[] => {
  const returnBids = bids.map((bid) => {
    if (bid.IsUser) {
      bid.BidderName = t('user');
    } else if (bid.IsUser == null) {
      bid.IsUser = false;
    }
    return bid;
  });

  return returnBids;
};

export const userFirst = (bids: BuySellBid[]): BuySellBid[] => {
  const userFirst = bids.sort((a, b) => Number(b.IsUser) - Number(a.IsUser));

  return userFirst;
};
