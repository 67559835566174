import { useTranslation } from 'react-i18next';
import { ReactComponent as OpenDocumentIcon } from './opendocument.svg';

interface Props {
  title: string;
  link: string;
}

export default function DocumentLink({ title, link }: Props): JSX.Element {
  const { t } = useTranslation('document-link');

  return (
    <div className="document-link" data-testid="document-link">
      <a href={link} target="_blank" data-testid="link" aria-label={t('link-label', { title: title })} rel="noreferrer">
        <span className="title">{title}</span>
        <OpenDocumentIcon />
      </a>
    </div>
  );
}
