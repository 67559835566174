interface Props {
  title: string;
  info: string;
}

export default function TaxInformationItem({ title, info }: Props): JSX.Element {
  return (
    <div className="tax-information-item" data-testid="tax-information-item">
      <div className="title"> {title} </div>
      <div className="info"> {info} </div>
    </div>
  );
}
