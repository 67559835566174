import { Line } from 'react-chartjs-2';
import { BuySellBid } from '../../services/generated-api-client';
import useBidHistoryChart from './useBidHistoryChart';

interface Props {
  bids: BuySellBid[];
  startingPrice: number;
}

export default function BidHistoryChart({ bids, startingPrice }: Props): JSX.Element {
  const { getChartData, getBidders, getOptions } = useBidHistoryChart();

  return (
    <div className="bid-history-chart">
      <Line className="chart" data={getChartData(bids, startingPrice)} options={getOptions(bids)} />
      {getBidders(bids).map((bidder) => (
        <span key={bidder.name} className="label">
          <span className="color" style={{ backgroundColor: bidder.color }}></span>
          <span className="title">{bidder.name}</span>
        </span>
      ))}
    </div>
  );
}
