import { Suspense, useState } from 'react';
import { ReactComponent as LeftArrow } from './leftarrow.svg';
import { ReactComponent as RighArrow } from './rightarrow.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  slides: React.ReactNode[];
}

export default function Carousel({ slides }: Props): JSX.Element {
  const { t } = useTranslation('carousel');
  const [current, setCurrent] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const length = slides.length;

  function nextSlide(): void {
    setCurrent(current === length - 1 ? current : current + 1);
  }

  function previousSlide(): void {
    setCurrent(current === 0 ? current : current - 1);
  }

  function handleDotClick(index: number): void {
    setCurrent(index);
  }

  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>): void {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  }

  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>): void {
    if (touchPosition === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchPosition - currentTouch;

    if (diff > 10) {
      nextSlide();
      setTouchPosition(null);
    }

    if (diff < -10) {
      previousSlide();
      setTouchPosition(null);
    }
  }

  return (
    <section className="carousel" data-testid="carousel" aria-roledescription="carousel" aria-label={t('carousel-content')}>
      <div className="wrapper">
        <div className="content" style={{ transform: `translateX(-${current * 100}%)` }}>
          {slides.map((slide, index) => {
            return (
              <div
                key={index}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                data-testid="content"
                role="group"
                aria-roledescription="slide"
              >
                <Suspense fallback={<></>}>{slide}</Suspense>
              </div>
            );
          })}
        </div>
        <button onClick={previousSlide} className="button left" aria-label="previous-slide">
          <LeftArrow />
        </button>
        <button onClick={nextSlide} className="button right" aria-label="next-slide">
          <RighArrow />
        </button>
      </div>
      <div className="dots">
        {slides.map((slide, index) => {
          return (
            <button
              onClick={(): void => handleDotClick(index)}
              key={index}
              className={index === current ? 'dot active' : 'dot'}
              data-testid="dot"
              aria-label={t('slide', { count: index + 1, total: length })}
            />
          );
        })}
      </div>
    </section>
  );
}
