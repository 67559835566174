import { useTranslation } from 'react-i18next';

export default function HeaderDefault(): JSX.Element {
  const { t } = useTranslation('home');

  return (
    <h1 className="component-heading" data-testid="default-header">
      {t('heading')}
    </h1>
  );
}
