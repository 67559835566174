import { useRef, useState } from 'react';

interface Props {
  label: string;
  onChange: () => void;
  image?: string;
  checked?: boolean;
}

export default function BankRadioInput({ label, onChange, image, checked }: Props): JSX.Element {
  const [focused, setFocused] = useState<boolean>();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <label className="bank-radio-input">
      <span className="label">
        {image && <img src={image} alt=""></img>}
        {label}
      </span>
      <span>
        <input
          type="radio"
          name="bank"
          onChange={(): void => onChange()}
          value={label}
          onFocus={(): void => setFocused(true)}
          onBlur={(): void => setFocused(false)}
          checked={checked}
          ref={inputRef}
        />
        <span className={`radio-button ${focused ? 'focused' : ''}`} aria-hidden="true">
          {inputRef.current?.checked && <span className="checked-mark"></span>}
        </span>
      </span>
    </label>
  );
}
