import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FullscreenPage from '../FullscreenPage/FullscreenPage';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../services/api-client';
import TextInput from '../../components/Form/TextInput';
import { useState } from 'react';
import { ChecklistChecklistItem } from '../../services/generated-api-client';
import Alert from '../../components/Alert/Alert';

export default function AddChecklistItemPage(): JSX.Element {
  const { t } = useTranslation('checklist');
  const navigate = useNavigate();
  const { view, estateId, checklistId } = useParams();

  const [searchParams] = useSearchParams();
  const returnToStep = searchParams.get('returnToStep');
  const returnToStepQuery = returnToStep ? `?returnToStep=${returnToStep}` : '';
  const backUrl = `/${view}/${estateId}/checklists/${checklistId}${returnToStepQuery}`;

  const [heading, setHeading] = useState<string>('');
  const [headingError, setHeadingError] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setSubmitError(false);

    if (heading === '') {
      setHeadingError(true);
      return;
    }

    const checklistItem: ChecklistChecklistItem = {
      Title: heading,
      Description: description,
    };

    setSubmitting(true);
    ApiClient.authenticated
      .addChecklistItem(estateId!, checklistId!, checklistItem)
      .then(() => navigate({ pathname: backUrl }))
      .catch((e) => {
        setSubmitting(false);
        setSubmitError(true);
        console.error(e);
      });
  };

  const handleHeadingInputChange = (value: string): void => {
    if (headingError) setHeadingError(false);

    setHeading(value);
  };

  return (
    <FullscreenPage title={t('add-item.title')} className="add-checklist-item-page" backUrl={backUrl}>
      {submitError && <Alert type="error" text={t('add-item.submit-error-text')} />}

      {headingError && <div className="error-summary-text">{t('add-item.heading-input-error-summary')}</div>}

      <form onSubmit={handleSubmit}>
        <TextInput
          value={heading}
          label={t('add-item.heading-input-label')}
          name="heading"
          onChange={(inputValue): void => handleHeadingInputChange(inputValue)}
          errorMode={headingError}
          required
        />

        <TextInput
          value={description}
          label={t('add-item.description-input-label')}
          name="description"
          onChange={(inputValue): void => setDescription(inputValue)}
          type="textarea"
        />

        <button type="submit" disabled={submitting}>
          {t('add-item.save-button-text')}
        </button>
      </form>
    </FullscreenPage>
  );
}
