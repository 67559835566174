import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { ReactComponent as NotificationIcon } from './notification.svg';
import { ReactComponent as ProfileIcon } from './profile.svg';
import ApplicationOptions from './../../utils/options';

interface Props {
  hasNewNotifications: boolean;
  hideIcons?: boolean | undefined;
}

export default function TopNavBar({ hasNewNotifications, hideIcons }: Props): JSX.Element {
  const { t } = useTranslation('header');
  const [profileMenuVisible, setProfileMenuVisible] = useState(false);
  const navigate = useNavigate();

  const checkForSecretWayToGetToGallery = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    const shiftModifierState = e.getModifierState('Shift');
    if (shiftModifierState) {
      navigate('/gallery');
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const profileMenuClick = (): void => {
    setProfileMenuVisible(!profileMenuVisible);
  };

  const profileMenuClosed = (): void => {
    setProfileMenuVisible(false);
  };
  //om vi bara vill ha vill ha centrerad logga på någon specifik kund ha med if
  const useCenteredLogo = (): boolean => {
    // return true;
    if (ApplicationOptions.themeLayout === 'HomePageLayout') return false;
    else return true;
  };
  //remove custom-menu in mobile view
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = (): void => {
      // Specify the return type void
      setMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <nav className={useCenteredLogo() === true ? 'top-nav-bar-logo-center' : 'top-nav-bar'} data-testid="top-nav-bar">
        <Link to="/" onClick={checkForSecretWayToGetToGallery} className="logoLink">
          <img className="logo" alt={t('logo-link-text')} />
        </Link>
        <div className="menu-position">
          {ApplicationOptions.theme === 'svenska-maklarhuset' && !mobileView ? (
            <>
              <div className="menu">
                <span className="active-menu">MINA SIDOR</span>
                <span className="inactive-menu">
                  <a href="https://svenskamaklarhuset.se/kopa/bostader-tillsalu/" target="_blank" rel="noreferrer">
                    TILL SALU
                  </a>
                </span>
                <span className="inactive-menu">
                  <a href="https://svenskamaklarhuset.se/guider/" target="_blank" rel="noreferrer">
                    GUIDER
                  </a>
                </span>
                <span className="inactive-menu">
                  <a href="https://svenskamaklarhuset.se/kontor/" target="_blank" rel="noreferrer">
                    KONTOR
                  </a>
                </span>
              </div>
            </>
          ) : (
            ''
          )}
          {hideIcons === true ? (
            ''
          ) : (
            <div className="icons">
              <Link to="notifications">
                <NotificationIcon
                  className={hasNewNotifications ? 'new-notifications' : ''}
                  role="img"
                  aria-label={t('notifications-link-text', {
                    hasNewNotifications: hasNewNotifications,
                  })}
                  data-testid="notification-icon"
                />
              </Link>
              <button onClick={profileMenuClick} data-testid="profile-button">
                <ProfileIcon role="img" aria-label={t('profile-button-text')} />
              </button>
            </div>
          )}
        </div>
      </nav>

      <ProfileMenu closeCallback={profileMenuClosed} visible={profileMenuVisible} />
    </>
  );
}
