import { EffectCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DocumentDocument } from '../../services/generated-api-client';
import ApiClient from '../../services/api-client';
import DocumentLink from '../../components/DocumentLink/DocumentLink';
import List from '../../components/List/List';
import Alert from '../../components/Alert/Alert';
import LoaderContainer, { ListLoader } from '../../components/Loader';

export default function DocumentsPage(): JSX.Element {
  const { t } = useTranslation('estate-page');
  const { estateId } = useParams();
  const [documents, setDocuments] = useState<DocumentDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect((): ReturnType<EffectCallback> => {
    ApiClient.authenticated
      .getDocumentsForEstate(estateId!, {
        cancelToken: 'getDocumentsForEstate',
      })
      .then((response) => setDocuments(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getDocumentsForEstate');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ListLoader />
        </>
      }
    >
      <div className="inner-content">
        <h1 className="page-heading">{t('documents-title')}</h1>
        <div className="collection-content">
          {!!documents.length && (
            <List>
              {documents.map((document) => (
                <DocumentLink key={document.Uid} title={document.Name ?? ''} link={document.Url ?? ''} />
              ))}
            </List>
          )}
          {!documents.length && <Alert type="info" text={t('no-documents')} />}
        </div>
      </div>
    </LoaderContainer>
  );
}
