import { CellProps, isStringControl, RankedTester, rankWith, isEnumControl, isDateControl, and, not } from '@jsonforms/core';
import { withJsonFormsCellProps } from '@jsonforms/react';
import type { VanillaRendererProps } from '@jsonforms/vanilla-renderers';
import { withVanillaCellProps } from '@jsonforms/vanilla-renderers';
import merge from 'lodash/merge';

export const TextCell = (props: CellProps & VanillaRendererProps): JSX.Element => {
  const { config, data, className, id, enabled, uischema, schema, path, handleChange } = props;
  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  return maxLength ?? 101 > 100 ? (
    <textarea
      rows={1}
      value={data || ''}
      onChange={(ev): void => handleChange(path, ev.target.value === '' ? undefined : ev.target.value)}
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={appliedUiSchemaOptions.focus}
      placeholder={appliedUiSchemaOptions.placeholder}
    />
  ) : (
    <input
      type={appliedUiSchemaOptions.format === 'password' ? 'password' : 'text'}
      value={data || ''}
      onChange={(ev): void => handleChange(path, ev.target.value === '' ? undefined : ev.target.value)}
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={appliedUiSchemaOptions.focus}
      placeholder={appliedUiSchemaOptions.placeholder}
      maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
      size={appliedUiSchemaOptions.trim ? maxLength : undefined}
    />
  );
};

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const textCellTester: RankedTester = rankWith(10, and(isStringControl, not(isEnumControl), not(isDateControl)));

export default withJsonFormsCellProps(withVanillaCellProps(TextCell));
