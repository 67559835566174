import { swedishNumberFormatter } from '../../utils/locale-utils';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  number: number | undefined;
  description: string;
}

export default function NumberInfo({ title, number, description }: Props): JSX.Element {
  const { t } = useTranslation('number-info');

  return (
    <div className="number-info" data-testid="number-info">
      <div className="title"> {title} </div>
      <div className="number" data-testid="number">
        {' '}
        {number !== undefined ? swedishNumberFormatter.format(number) : t('data-error')}{' '}
      </div>
      <div className="description"> {description} </div>
    </div>
  );
}
