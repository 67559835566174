import { NavLink } from 'react-router-dom';
import ApplicationOptions from './../../utils/options';
import { useEffect, useRef } from 'react';

interface Props {
  steps: ProcessStep[];
}

export interface ProcessStep {
  title: string;
  route: string;
  /** Denotes that this is the current step in the process, not just the currently selected one. */
  isCurrentStep: boolean;
}

export default function ProcessNavBar({ steps }: Props): JSX.Element {
  const ref = useRef(null);

  const scrollToElement = (): void => {
    //When running in test, dont do scrollIntoView (causes test to fail)
    if (process.env.JEST_WORKER_ID !== undefined) return;

    if (ref?.current) {
      (ref.current as HTMLElement)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, []);

  function getStepLink(step: ProcessStep): JSX.Element {
    return (
      <NavLink to={step.route} className="step">
        {step.isCurrentStep && <div ref={ref} className="current-step-marker"></div>}
        {step.title}
      </NavLink>
    );
  }

  return (
    <nav className="process-nav-bar" data-testid="process-nav-bar">
      <ul>
        {steps.map((step, i) => (
          <li key={i}>
            {getStepLink(step)}
            {ApplicationOptions.themeLayout === 'HomePageLayout' ? (
              <>{i === steps.length - 1 ? '' : <span className="headerDivider"></span>} </>
            ) : (
              ''
            )}{' '}
          </li>
        ))}
      </ul>
    </nav>
  );
}
