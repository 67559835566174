import { EffectCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderContainer, { ListLoader } from '../../components/Loader';
import { JsonForms } from '@jsonforms/react';
import { JsonFormsRendererRegistryEntry, JsonFormsCellRendererRegistryEntry, Layout, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { vanillaRenderers, vanillaCells } from '@jsonforms/vanilla-renderers';
import Ajv, { ErrorObject } from 'ajv';
import addFormats from 'ajv-formats';
import ApiClient from '../../services/api-client';
import { useParams, useSearchParams } from 'react-router-dom';
import FullscreenPage from '../FullscreenPage/FullscreenPage';
import { FormFormData } from '../../services/generated-api-client';
import ChecklistItem from '../../components/ChecklistItem/ChecklistItem';
//import IdCell, { idCellTester } from './FormCellRendererId';
import MoneyCell, { moneyCellTester } from './FormCellRendererMoney';
import FormGroupRenderer, { formGroupTester } from './FormGroupRenderer';
import FormInputControl, { formInputTester } from './FormInputControl';
import FormDefaultRenderer, { formDefaultTester } from './FormDefaultRenderer';
import FormMspxCapitalGainImprovmentsRenderer, { formMspxCapitalGainImprovmentsTester } from './FormMspxCapitalGainImprovmentsRenderer';
import FormMspxCapitalGainSellersRenderer, { formMspxCapitalGainSellersTester } from './FormMspxCapitalGainSellersRenderer';
import FormMspxSumFieldsRenderer, { formMspxSumFieldsTester } from './FormMspxSumFieldsRenderer';
import FormArrayControl, { formArrayControlTester } from './FormArrayControl';
import FormLabelRenderer, { formLabelRendererTester } from './FormLabelRenderer';
import FormMspxBooleanArrayRenderer, { formMspxBooleanArrayTester } from './FormMspxBooleanArrayRenderer';
//import FormMspxBankSellersRenderer, { formMspxBankSellersTester } from './FormMspxBankSellersRenderer';
import TextCell, { textCellTester } from './FormCellRendererText';

import { isEmpty } from 'lodash';
import Spinner from '../../components/Spinner/Spinner';
interface UISchemaElementLocal extends UISchemaElement {
  label: string;
  elements: UISchemaElementLocal[];
}

export default function Form(): JSX.Element {
  const { view, estateId, formId } = useParams();
  const [searchParams] = useSearchParams();
  const returnToStep = searchParams.get('returnToStep');
  const backUrl = returnToStep ? `/${view}/${estateId}/process/${returnToStep}` : `/${view}/${estateId}`;
  const { t } = useTranslation('form-page');
  const [loading, setLoading] = useState<boolean>(true);
  const [firstChange, setFirstChange] = useState<boolean>(true);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [readyForImport, setReadyForImport] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormFormData>({});
  const [errors, setErrors] = useState<ErrorObject[]>([]);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const addErrors = (errors2set: ErrorObject[] | undefined): void => {
    if (errors2set && errors2set.length > 0) {
      console.log(errors2set);
      // fix custom fields error
      const customFieldsError = errors2set.filter((o) => o.instancePath === '/customFields' && o.message === 'must be array')[0];
      if (customFieldsError) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const looseObject = formData.Data as { [k: string]: any };
        looseObject['customFields'] = [];
      }
      const questionsError = errors2set.filter((o) => o.instancePath === '/questions' && o.message === 'must be object')[0];
      if (questionsError) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const looseObject = formData.Data as { [k: string]: any };
        looseObject['questions'] = {};
      }

      setSubmitError(true);
      setErrors(errors2set);
    } else {
      setSubmitError(false);
      setErrors([]);
    }
  };

  const canModifyForm = (): boolean => {
    if (getFormStatus() === 'Submitted') {
      return false;
    }
    if (getFormStatus() === 'Locked') {
      return false;
    }
    return true;
  };

  const getFormStatus = (): 'Unknown' | 'Open' | 'Changed' | 'Submitted' | 'Locked' => {
    //console.log('getJsonSchema:' + formData.JsonSchema);
    return formData.FormStatus ?? 'Unknown';
  };

  const getJsonSchema = (): JsonSchema => {
    //console.log('getJsonSchema:', JSON.stringify(formData.JsonSchema));
    return (formData.JsonSchema ?? {}) as JsonSchema;
  };

  const getUISchema = (): Layout => {
    //console.log('getUISchema:', JSON.stringify(formData.UiSchema));
    return (formData.UiSchema ?? {}) as Layout;
  };

  const isUISchemaValid = (): boolean => {
    const uischema = getUISchema();
    if (!uischema) return false;
    if (!uischema.elements) return false;
    return uischema.elements.length > 0;
  };

  useEffect((): ReturnType<EffectCallback> => {
    ApiClient.authenticated
      .getFormDataForEstate(estateId!, formId!, {
        cancelToken: 'getFormDataForEstate',
      })
      .then((response) => {
        setFormData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [estateId, formId]);

  const handleSubmit = (): void => {
    setSubmitting(true);
    ApiClient.authenticated
      .updateFormDataForEstate(estateId!, formId!, readyForImport, formData.Data!)
      .then((response) => {
        setFirstChange(true);
        setFormChanged(false);
        setFormData(response.data);
        addErrors(undefined);
      })
      .catch((e) => {
        setSubmitError(true);
        console.log('Failed to send form', e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const renderers: JsonFormsRendererRegistryEntry[] = [
    ...vanillaRenderers,
    { tester: formDefaultTester, renderer: FormDefaultRenderer },
    { tester: formMspxCapitalGainImprovmentsTester, renderer: FormMspxCapitalGainImprovmentsRenderer },
    { tester: formMspxCapitalGainSellersTester, renderer: FormMspxCapitalGainSellersRenderer },
    { tester: formMspxSumFieldsTester, renderer: FormMspxSumFieldsRenderer },
    { tester: formGroupTester, renderer: FormGroupRenderer },
    { tester: formInputTester, renderer: FormInputControl },
    { tester: formArrayControlTester, renderer: FormArrayControl },
    { tester: formMspxBooleanArrayTester, renderer: FormMspxBooleanArrayRenderer },
    { tester: formLabelRendererTester, renderer: FormLabelRenderer },
    //{ tester: formMspxBankSellersTester, renderer: FormMspxBankSellersRenderer },
  ];

  const cells: JsonFormsCellRendererRegistryEntry[] = [
    ...vanillaCells,
    { tester: textCellTester, cell: TextCell },
    //{ tester: idCellTester, cell: IdCell },
    { tester: moneyCellTester, cell: MoneyCell },
  ];

  const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strict: false,
  });
  addFormats(ajv);
  ajv.addFormat('id', '.*');
  ajv.addFormat('money', '.*');
  ajv.addFormat('enum', '.*');

  function readyForImportCallback(itemId: string, status: boolean): void {
    setFormChanged(true);
    setReadyForImport(status);
  }

  function hasLabel(uiSchema: UISchemaElementLocal, label: string): boolean {
    if (uiSchema.label === label) {
      return true;
    }
    const elements = uiSchema.elements ?? [];
    const x = elements.find((element) => hasLabel(element, label));
    return x !== undefined;
  }

  const translation = (key: string, defaultMessage: string | undefined): string => {
    //console.log(`Key: ${key}, Default Message: ${defaultMessage}`);

    // get dynamic label
    const sellerIdxMatch = key.match(/^sellerBankAccount\.([0-9]+)\.sellerBankAccounts\.label$/);
    if (sellerIdxMatch) {
      const sellerIdx = sellerIdxMatch[1];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = formData.Data as any;
      //console.log(data, key, sellerIdx);
      const contactDetails = data?.sellers[sellerIdx]?.contactDetails;
      if (contactDetails) {
        return contactDetails.firstName + ' ' + contactDetails.lastName;
      } else {
        return '';
      }
    }

    // handle .0., .1. paths from arrays...
    key = key
      .split('.')
      .filter((o) => isNaN(Number(o)))
      .join('.');

    let translated = t(key);
    if (translated === key) {
      if (defaultMessage) {
        translated = t(defaultMessage);
        //if(translated === defaultMessage) {
        //  console.log('Missing key:' + key2 + ':' + defaultMessage);
        //}
      }
    }

    if (key === 'CUSTOMER_PAGE_OPERATING_COST_DESCRIPTION_ADDITION') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!hasLabel(formData.UiSchema as UISchemaElementLocal, 'ESTATE_ELECTRICITY_COST')) {
        translated = defaultMessage = '';
      }
    }

    return translated ?? defaultMessage;
  };

  const getSaveStatus = (): string => {
    const key = 'saved-text.' + getFormStatus();
    const translated = t(key);
    if (key === translated) {
      return t('saved-text.default');
    }
    return translated;
  };

  const formatDate = (dt: string | undefined): string => {
    if (isEmpty(dt)) {
      return dt ?? '';
    }
    const now = new Date();
    const date = new Date(dt!);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (now.getFullYear() === year && now.getMonth() + 1 === month && now.getDate() === day) {
      const hour = date.getHours();
      const strHour = hour < 10 ? '0' + hour : '' + hour;
      const minutes = date.getMinutes();
      const strMinutes = minutes < 10 ? '0' + minutes : '' + minutes;
      return strHour + ':' + strMinutes;
    } else {
      const strMonth = month < 10 ? '0' + month : '' + month;
      const strDay = day < 10 ? '0' + day : '' + day;
      return year + '-' + strMonth + '-' + strDay;
    }
  };

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ListLoader />
        </>
      }
    >
      <FullscreenPage title={formData.Name ?? ''} backUrl={backUrl}>
        {!isUISchemaValid() && <div className="forms-error">{t('form-error')}</div>}
        {isUISchemaValid() && (
          <div className="forms custom-form">
            <JsonForms
              readonly={!canModifyForm()}
              ajv={ajv}
              i18n={{ translate: translation }}
              schema={getJsonSchema()}
              uischema={getUISchema()}
              data={formData.Data}
              renderers={renderers}
              cells={cells}
              onChange={({ errors, data }): void => {
                formData.Data = data;
                setFormData(formData);
                addErrors(errors ?? []);
                if (!firstChange) {
                  setFormChanged(true);
                }
                setFirstChange(false);
              }}
            />
            <div className="save-form">
              {canModifyForm() && (
                <>
                  <div className="save-form-description">{t('save-form-description')}</div>
                  <ChecklistItem
                    id="ready-for-import"
                    title={t('ready-for-import')}
                    description=""
                    completed={readyForImport}
                    updateItemStatusCallback={readyForImportCallback}
                  />
                </>
              )}
              <div className="last-saved">
                {getSaveStatus()}: {formatDate(formData.Updated)}
              </div>
            </div>
            {getFormStatus() !== 'Submitted' && (
              <button
                className={'button-submit' + (submitError ? ' button-submit-error' : '')}
                disabled={!formChanged || errors.length > 0}
                type="submit"
                data-testid="button"
                onClick={handleSubmit}
              >
                <Spinner enabled={submitting} />
                {errors.length > 0 && t('button-text-send')}
                {errors.length === 0 && (readyForImport ? t('button-text-send') : t('button-text-save'))}
              </button>
            )}
          </div>
        )}
      </FullscreenPage>
    </LoaderContainer>
  );
}
