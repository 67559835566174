import React, { FunctionComponent } from 'react';
import { LayoutProps, RankedTester, rankWith, UISchemaElement, JsonSchema, TesterContext } from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

export const formDefaultTester: RankedTester = rankWith(
  0,
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    switch (uischema.type) {
      case 'Label':
      case 'Group':
      case 'Control':
      case 'VerticalLayout':
      case 'HorizontalLayout':
      case 'Mspx_CapitalGainImprovments':
      case 'Mspx_CapitalGainSellers':
      case 'Mspx_SumFields':
      case 'Mspx_BooleanArray':
        return true;
      default:
        console.log('Unknown type:' + uischema.type);
        return true;
    }
  },
);

export const FormDefaultRenderer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
): JSX.Element => {
  const { data: _data, ...otherProps } = props;
  // We don't hand over data to the layout renderer to avoid rerendering it with every data change
  return <DefaultRendererComponent {...otherProps} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultRendererComponent: FunctionComponent<any> = React.memo(function DefaultRendererComponent({
  schema,
  uischema,
}: LayoutProps & VanillaRendererProps) {
  return <div>Unknown</div>;
});

export default withVanillaControlProps(withJsonFormsLayoutProps(FormDefaultRenderer));
