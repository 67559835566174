import uuid from './uuid';

export type MessageCallback = (...subject: unknown[]) => void;

type Topics = {
  [index: string]: Topic;
};

type Topic = {
  [index: string]: MessageCallback | null;
};

const topics: Topics = {};

export function subscribeToTopic(topic: string, callback: MessageCallback): () => void {
  if (!topics[topic]) {
    topics[topic] = {};
  }

  const id = uuid();
  topics[topic][id] = callback;

  return (): void => {
    topics[topic][id] = null;
    delete topics[topic][id];
  };
}

export function publishMessage(topic: string, ...args: unknown[]): void {
  if (!topics[topic]) {
    return;
  }

  Object.values(topics[topic]).forEach((callback) => {
    if (!!callback) {
      callback(...args);
    }
  });
}
