/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type BiddingBiddingBidLooserView = object & BiddingBiddingView;

/**
 * Represents the bidding view
 */

export interface BiddingBiddingView {
  /** Common properties for the estate in the buy or sell views */
  Estate?: BuySellBuySellEstate;

  /** Bidding status, can be InProgress, Paused, or Ended */
  Status?: string;

  /**
   * Whether user has the leading bid. This flag also signals if
   * a new bid can be placed. Null -> no new bids, true ->
   */
  HasLeadingBid?: boolean;

  /** Represents a link */
  MoreEstatesLink?: Link;

  /** Loan commitment for a user */
  LoanCommitmentInfo?: BiddingLoanCommitmentInfo;

  /**
   * The minimum amount necessary to raise the bid
   * @format decimal
   */
  MinBidRaise?: number;

  /** Bids */
  Bids?: BuySellBid[];

  /** Tips to display */
  Tips?: BuySellTip[];

  /** Represents a broker */
  Broker?: BuySellBroker;
}

/**
 * Common properties for the estate in the buy or sell views
 */

export interface BuySellBuySellEstate {
  /**
   * The id of the estate
   * @format uuid
   */
  Uid?: string;

  /** The type of the estate */
  Type?: string;

  /** The adress of an estate */
  Address?: BuySellBuySellAddress;

  /** Represents a homepage estate price */
  PriceInfo?: EstatePrice;

  /** The current estate step */
  CurrentStep?: string;

  /** Link to estate webpage */
  EstateLink?: string;
}

/**
 * The adress of an estate
 */

export interface BuySellBuySellAddress {
  /** The street address */
  StreetAddress?: string;

  /** The area */
  Area?: string;

  /** The zip code */
  Zip?: string;

  /** The city */
  City?: string;
}

/**
 * Represents a homepage estate price
 */

export interface EstatePrice {
  /** The price status */
  PriceStatus?: string;

  /**
   * The current price
   * @format decimal
   */
  Price?: number;

  /**
   * The starting price
   * @format decimal
   */
  StartingPrice?: number;

  /**
   * The amount that the price has altered from the starting price
   * @format int32
   */
  DiffPercent?: number;

  /**
   * The final price per square meter
   * @format decimal
   */
  PricePerSquareMeter?: number;
}

/**
 * Represents a link
 */

export interface Link {
  /** The link url */
  Url?: string;

  /** A friendly link name */
  Name?: string;
}

/**
 * Loan commitment for a user
 */

export interface BiddingLoanCommitmentInfo {
  /** The bank the user has a loan commitment from */
  LoanCommitmentBank?: string;

  /** If the user has a loan commitment */
  HasLoanCommitment?: boolean;

  /** Specifies if the user can be contacted. ie if the user can accept to be so. */
  CanBeContacted?: boolean;

  /** If the user wishes to be contacted about loan commitment */
  ToBeContacted?: boolean;
}

/**
 * Represents a bid
 */

export interface BuySellBid {
  /**
   * The unique id of the bid
   * @format uuid
   */
  Uid?: string;

  /**
   * Date the bid was placed
   * @format date-time
   */
  Date?: string;

  /**
   * The amount this bid was for
   * @format int32
   */
  Amount?: number;

  /** The anonymous name of the bidder */
  BidderName?: string;

  /** If the user is the bidder */
  IsUser?: boolean;

  /** Specifies if this is a hidden bid */
  IsHidden?: boolean;
}

/**
 * A tip structure
 */

export interface BuySellTip {
  /**
   * The unique id of the tip
   * @format uuid
   */
  Uid?: string;

  /** The title of the tip */
  Title?: string;

  /** The description text of the tip */
  Description?: string;

  /** The text on the button that links to the content */
  LinkText?: string;

  /** The link to the content */
  LinkUrl?: string;

  /** The url to the image to show */
  ImageUrl?: string;

  /** The type of the tip */
  Type?: 'Tip' | 'Offer';
}

/**
 * Represents a broker
 */

export interface BuySellBroker {
  /**
   * The unique id of the broker
   * @format uuid
   */
  Uid?: string;

  /** The broker name */
  Name?: string;

  /** The brokers title */
  Title?: string;

  /** The broker phone */
  Phone?: string;

  /** The broker mail */
  Mail?: string;

  /**
   * The broker image
   * @format uri
   */
  ImageUrl?: string;
}

export type BiddingBiddingBidWinnerView = object & BiddingBiddingView;

export type BiddingBiddingHaltedView = object & BiddingBiddingView;

export type BiddingBiddingHighestBidView = object & BiddingBiddingView;

export type BiddingBiddingNotBiddingParticipantView = object & BiddingBiddingView;

export type BiddingBiddingPausedView = object & BiddingBiddingView;

/**
 * Represents a new bid
 */

export interface BiddingNewBid {
  /**
   * The amount to bid
   * @format decimal
   */
  Amount?: number;
}

export type BiddingBiddingPlaceBidView = object & BiddingBiddingView;

/**
 * Contains data to show in the view
 */

export type BuySellBuyBuyAfterEntryView = { Price?: EstatePrice } & BuySellBuyBuyView;

/**
 * Represents a sell view
 */

export type BuySellBuyBuyView = { Estate?: BuySellBuyBuyEstate } & BuySellBuySellView;

/**
 * Represents a sell view
 */

export type BuySellBuyBuyEstate = object & BuySellBuySellEstate;

/**
 * Common properties for all the buy and sell views
 */

export interface BuySellBuySellView {
  /** Represents a step description */
  Description?: BuySellStepDescription;

  /** Information about the checklists in this view */
  Checklists?: ChecklistChecklistSummary[];

  /** The documents */
  Documents?: DocumentDocument[];

  /** The forms */
  Forms?: FormForm[];

  /** Tips to display */
  Tips?: BuySellTip[];

  /** Represents a broker */
  Broker?: BuySellBroker;
}

/**
 * Represents a step description
 */

export interface BuySellStepDescription {
  /** The step title */
  Title?: string;

  /** The step description */
  Description?: string;
}

/**
 * Represents the data in a checklist
 */

export interface ChecklistChecklistSummary {
  /**
   * The id of the checklist
   * @format uuid
   */
  Uid?: string;

  /** The name of the checklist. */
  Name?: string;

  /**
   * The number of items in this list
   * @format int32
   */
  NumberOfItems?: number;

  /**
   * The number of completed items in this list
   * @format int32
   */
  CompletedItems?: number;
}

/**
 * Represents a document
 */

export interface DocumentDocument {
  /**
   * The id of the document
   * @format uuid
   */
  Uid?: string;

  /**
   * The type of document
   * @format uuid
   */
  DocumentType?: string;

  /** The name of the document. */
  Name?: string;

  /** The url to the document */
  Url?: string;
}

/**
 * Represents a form
 */

export interface FormForm {
  /**
   * The id of the form
   * @format uuid
   */
  Uid?: string;

  /**
   * The type of form
   * @format uuid
   */
  FormType?: string;

  /** The name of the form. */
  Name?: string;

  /** The type of form */
  FormStatus?: 'Unknown' | 'Open' | 'Changed' | 'Submitted' | 'Locked';

  /**
   * The updated time
   * @format date-time
   */
  Updated?: string;
}

/**
 * Contains data to show in the view
 */

export type BuySellBuyBuyContractView = { SigningEvent?: BuySellEvent } & BuySellBuyBuyView;

/**
 * Represents an event
 */

export interface BuySellEvent {
  /** The title of the event */
  Title?: string;

  /** The push text of the event */
  PushText?: string;

  /** The sub text of the event */
  SubText?: string;

  /** The link of the sub-text */
  SubTextLink?: string;
}

/**
 * Contains data to show in the view
 */

export type BuySellBuyBuyDownPaymentView = {
  DownPaymentAmount?: number;
  DownPaymentAdjustment?: BuySellDownPaymentAdjustment[];
  AmountToPay?: number;
  AccountNumber?: string;
  ClearingNumber?: string;
  Bank?: string;
  Bankgiro?: string;
  BankgiroType?: 'Bankgiro' | 'Plusgiro';
  LastDepositDate?: string;
  LastPayoutDate?: string;
  IsDownPaymentDeposited?: boolean;
  IsMembershipApproved?: boolean;
  IsDownPaymentPaidOut?: boolean;
} & BuySellBuyBuyView;

/**
 * Represents a commission adjustment
 */

export interface BuySellDownPaymentAdjustment {
  /** The description */
  Description?: string;

  /**
   * The amount
   * @format decimal
   */
  Amount?: number;
}

/**
 * Contains data to show in the view
 */

export type BuySellBuyBuyEntryView = { Event?: BuySellEvent } & BuySellBuyBuyView;

/**
 * Contains data to show in the view
 */

export type BuySellBuyBuyEstateDataView = { EstateDescription?: BuySellEstateDescription; Images?: BuySellImage[] } & BuySellBuyBuyView;

/**
 * Estate description
 */

export interface BuySellEstateDescription {
  /** Short selling text */
  ShortText?: string;

  /** Long selling text */
  LongText?: string;

  /**
   * The number of rooms
   * @format decimal
   */
  NumberOfRooms?: number;

  /**
   * The living space in square meteres
   * @format decimal
   */
  LivingSpace?: number;
}

/**
 * Represents an image
 */

export interface BuySellImage {
  /**
   * The id of the image
   * @format uuid
   */
  ImageUid?: string;

  /**
   * The image uri
   * @format uri
   */
  ImageUri?: string;

  /** The image extension */
  ImageExtension?: string;
}

/**
 * Contains data to show in the view
 */

export type BuySellSellSellAfterEntryView = { Price?: EstatePrice; TaxInformation?: BuySellSellSellTaxInformation } & BuySellSellSellView;

/**
 * Contains all the info the seller needs for his/her taxes
 */

export interface BuySellSellSellTaxInformation {
  /**
   * The income year
   * @format int32
   */
  IncomeYear?: number;

  /**
   * The taxation year
   * @format int32
   */
  TaxationYear?: number;

  /**
   * The date the sale was finalized
   * @format date-time
   */
  SellDate?: string;

  /**
   * The price that the estate was sold for
   * @format decimal
   */
  SellPrice?: number;

  /**
   * The brokers comission
   * @format decimal
   */
  BrokerComission?: number;

  /**
   * The date when the keys were handed over to the buyer
   * @format date-time
   */
  EntryDate?: string;

  /** The name of the housing cooperative, or null of the estate isn't a housing cooperative */
  HousingCooperativeName?: string;

  /** The apartment designation within the housing cooperative, or null of the estate isn't a housing cooperative */
  HouseCooperativeDesignation?: string;
}

/**
 * Represents a sell view
 */

export type BuySellSellSellView = { Estate?: BuySellSellSellEstate } & BuySellBuySellView;

/**
 * Represents a sell view
 */

export type BuySellSellSellEstate = object & BuySellBuySellEstate;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellContractView = { SigningEvent?: BuySellEvent } & BuySellSellSellView;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellDownPaymentView = {
  LastDepositDate?: string;
  LastPayoutDate?: string;
  IsDownPaymentDeposited?: boolean;
  IsMembershipApproved?: boolean;
  IsDownPaymentPaidOut?: boolean;
  DownPaymentAmount?: number;
  DownPaymentBrokerComissionAmount?: number;
  DownPaymentAdjustment?: BuySellDownPaymentAdjustment[];
  DownPaymentPaidOutAmount?: number;
} & BuySellSellSellView;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellEntryView = { Event?: BuySellEvent } & BuySellSellSellView;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellEstateDataView = { EstateDescription?: BuySellEstateDescription; Images?: BuySellImage[] } & BuySellSellSellView;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellMarketingView = {
  NumberOfInterestedPeople?: number;
  TotalNumberOfBookedViewings?: number;
  ExternalListings?: BuySellExternalListing[];
} & BuySellSellSellView;

/**
 * An external listing for a property
 */

export interface BuySellExternalListing {
  /** The name of the listing */
  Name?: string;

  /** The url to the listing */
  Url?: string;

  /** The url to the icon */
  IconUrl?: 'Website' | 'Prospect' | 'Facebook' | 'Instagram' | 'Twitter';
}

/**
 * Contains data to show in the view
 */

export interface BuySellSellSellMarketingViewGraph {
  /** Datasets for a graph showing number of impressions per day grouped per channel (and total) */
  Impressions?: BuySellGraphDataset[];

  /** The total number of impressions grouped per channel */
  ImpressionsPerChannel?: BuySellMarketingChannelImpressions[];

  /** The number of website page views grouped per source */
  WebsiteViewsPerChannel?: BuySellMarketingChannelImpressions[];
}

/**
 * Represents a dataset for a graph component
 */

export interface BuySellGraphDataset {
  /** The name of the dataset */
  Name?: string;

  /** The data */
  DataPoints?: BuySellGraphDataPoint[];
}

/**
 * A single data point in a graph
 */

export interface BuySellGraphDataPoint {
  /**
   * The data point date
   * @format date-time
   */
  Date?: string;

  /**
   * The data point value
   * @format decimal
   */
  Value?: number;
}

/**
 * Tracks the number of impressions for a marketing channel
 */

export interface BuySellMarketingChannelImpressions {
  /** The channel name */
  Name?: string;

  /**
   * The data point value
   * @format int32
   */
  Value?: number;
}

/**
 * Contains data to show in the view
 */

export type BuySellSellSellPreparationsView = { Event?: BuySellEvent } & BuySellSellSellView;

/**
 * Contains data to show in the view
 */

export type BuySellSellSellViewingBiddingView = {
  PriceInfo?: EstatePrice;
  ViewingBiddingInfo?: BuySellViewingBiddingInfo;
  Viewings?: BuySellViewing[];
  Bids?: BuySellBid[];
} & BuySellSellSellView;

/**
 * Represents information about viewings and biddings
 */

export interface BuySellViewingBiddingInfo {
  /**
   * The total amount of booked viewings
   * @format int32
   */
  TotalNumberOfBookedViewings?: number;

  /**
   * The total amount of viewing attendants
   * @format int32
   */
  TotalNumberOfViewingAttendants?: number;

  /**
   * The total amount of bidders
   * @format int32
   */
  TotalNumberOfBidders?: number;
}

/**
 * Represents a viewing
 */

export interface BuySellViewing {
  /**
   * The unique id of the viewing
   * @format uuid
   */
  Uid?: string;

  /** The comment entered by the broker. */
  Comment?: string;

  /** The viewing type */
  ViewingType?: 'OnlyComment' | 'ContactBroker' | 'Date' | 'DateAndTime' | 'TimeSlots';

  /**
   * Start date for viewing
   * @format date-time
   */
  StartDate?: string;

  /**
   * End date for viewing
   * @format date-time
   */
  EndDate?: string;

  /**
   * Number of bookings for viewing
   * @format int32
   */
  NumberOfBookings?: number;

  /**
   * Number of visitors for viewing
   * @format int32
   */
  NumberOfVisitors?: number;
}

/**
 * Represents an item in a checklist
 */

export interface ChecklistChecklistItem {
  /**
   * The id of the checklist item
   * @format uuid
   */
  Uid?: string;

  /** The the title of this item */
  Title?: string;

  /** The the description of this item */
  Description?: string;

  /** The the link to an external resource */
  Link?: string;

  /** Is this item completed? */
  Completed?: boolean;
}

/**
 * Represents the a checklist
 */

export interface ChecklistChecklist {
  /**
   * The unique id of the checklist
   * @format uuid
   */
  Uid?: string;

  /** The name of the checklist. */
  Name?: string;

  /** The title of the checklist */
  Title?: string;

  /** The title of the description */
  Description?: string;

  /** The checklist items */
  Items?: ChecklistChecklistItem[];
}

/**
 * Represents the checklists that belongs to a certain process step
 */

export interface ChecklistChecklistStep {
  /** The name of the checklist. */
  StepName?: string;

  /** The checklist items */
  Checklists?: ChecklistChecklistSummary[];
}

/**
 * Represents a form
 */

export type FormFormData = { Data?: JsonNode; JsonSchema?: JsonNode; UiSchema?: JsonNode } & FormForm;

/**
 * Represents some json data
 */

export type JsonNode = object;

/**
 * The hompage data
 */

export interface HomepageHomepageData {
  /** The estates to sell */
  EstatesToSell?: HomepageHomepageEstate[];

  /** The estates to sell */
  EstatesToBuy?: HomepageHomepageEstate[];

  /** The estates to bid on */
  EstatesToBidOn?: HomepageHomepageEstate[];

  /** List of offers */
  Offers?: BuySellTip[];
}

/**
 * Represents a homepage estate
 */

export interface HomepageHomepageEstate {
  /**
   * The estate id
   * @format uuid
   */
  Uid?: string;

  /** The address */
  Address?: string;

  /** Represents a homepage estate price */
  PriceInfo?: EstatePrice;

  /**
   * The date when the estat was sold(if sold)
   * @format date-time
   */
  Sold?: string;

  /**
   * The image of the estate
   * @format uri
   */
  Image?: string;

  /** The name of the step that this estate is in(EstateStep.Name). */
  EstateStep?: string;
}

/**
 * Represents the onboarding view
 */

export interface OnboardingOnboardingView {
  /** Contains status of the onboarding process */
  Contact?: OnboardingContact;

  /**
   * The estate uid
   * @format uuid
   */
  EstateUid?: string;

  /** Represents a broker */
  Broker?: BuySellBroker;

  /** List of tips */
  Tips?: BuySellTip[];
}

/**
 * Contains status of the onboarding process
 */

export interface OnboardingContact {
  /**
   * The uid of the contact
   * @format uuid
   */
  ContactUid?: string;

  /** The onboarding status of the contact */
  Status?: 'Unknown' | 'Match' | 'NotFound' | 'NeedSsn' | 'SsnDiffers';

  /** The first name of the contact */
  FirstName?: string;

  /** The last name of the contact */
  LastName?: string;

  /** The social security number */
  Ssn?: string;
}

/**
 * A recommendation sent by a user
 */

export interface BuySellRecommendation {
  /**
   * The estate uid that the user sent the recommendation from
   * @format uuid
   */
  EstateUid?: string;

  /** The name of the person recieving the recommendation */
  Name?: string;

  /** The email of the person recieving the recommendation */
  Email?: string;

  /** The phone of the person recieving the recommendation */
  Phone?: string;
}

/**
 * Represents data for a user
 */

export interface UserUserInfo {
  /** The subject id */
  SubjectId?: string;

  /** The name of the user */
  Name?: string;

  /** The social security number of the user */
  Ssn?: string;
}

/**
 * Represents a notification
 */

export interface UserNotification {
  /**
   * The notification uid
   * @format uuid
   */
  Uid?: string;

  /** The notification title */
  Title?: string;

  /** The notification text */
  Text?: string;

  /**
   * The notification link. This is the url the user
   * navigates to if the notification is selected.
   * @format uri
   */
  Link?: string;

  /**
   * The notification date
   * @format date-time
   */
  Date?: string;

  /** The notification sender */
  Sender?: string;

  /** The notification state */
  Read?: boolean;
}

/**
 * Represents a geo location.
 */

export interface GeoLocation {
  /**
   * The latitude
   * @format decimal
   */
  Latitude?: number;

  /**
   * The longitude
   * @format decimal
   */
  Logitude?: number;
}

/**
 * Represents a tiny url
 */

export interface TinyUrlTinyUrl {
  /** The short id for this tiny url. */
  ShortId?: string;

  /**
   * The url that this tiny url points to.
   * @format uri
   */
  Url?: string;

  /** the sha256 checksum of the url */
  SHA256?: string;
}

/**
* This sturcture contains information about the appplication. This structure is
available by any user on the internet.
*/

export interface ApplicationData {
  /** The application version. */
  Version?: string;

  /**
   * The front address that can be used to access the content.
   * @format uri
   */
  FrontAddress?: string;
}

/**
 * Contains information about an error
 */

export interface ErrorInfo {
  /**
   * The error code
   * @format int32
   */
  ErrorCode?: number;

  /** The title to show */
  Title?: string;

  /** The description of the error */
  Description?: string;
}

/**
 * An estate step
 */

export interface EstateStep {
  /** The view that the step belongs to - may be "sell" or "buy" */
  View?: string;

  /**
   * The id of this step
   * @format uuid
   */
  Uid?: string;

  /** The friendly name of the step */
  Name?: string;

  /**
   * The previous step id
   * @format uuid
   */
  PreviousStepId?: string;

  /**
   * The next step id
   * @format uuid
   */
  NextStepId?: string;

  /**
   * The ordinal of this step
   * @format int32
   */
  Ordinal?: number;
}

/**
 * The authorization progress
 */

export interface BankIdAuthStatus {
  /**
   * Specifies if this authorization has been successful or not.
   * If not set then the authorization is in progress.
   */
  Success?: boolean;

  /** The current status of the authorization. */
  Description?: string;

  /** The Qr string */
  QrString?: string;
}

export namespace Authenticated {
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingBidLooser
   * @name GetBiddingBidLooserView
   * @request GET:/Services/Authenticated/Bidding/IBiddingBidLooser/GetBiddingBidLooserViewAsync/{estateUid}
   * @response `200` `BiddingBiddingBidLooserView` The response type
   */
  export namespace GetBiddingBidLooserView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingBidLooserView;
  }
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingBidWinner
   * @name GetBiddingBidWinnerView
   * @request GET:/Services/Authenticated/Bidding/IBiddingBidWinner/GetBiddingBidWinnerViewAsync/{estateUid}
   * @response `200` `BiddingBiddingBidWinnerView` The response type
   */
  export namespace GetBiddingBidWinnerView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingBidWinnerView;
  }
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingHalted
   * @name GetBiddingHaltedView
   * @request GET:/Services/Authenticated/Bidding/IBiddingHalted/GetBiddingHaltedViewAsync/{estateUid}
   * @response `200` `BiddingBiddingHaltedView` The response type
   */
  export namespace GetBiddingHaltedView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingHaltedView;
  }
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingHighestBid
   * @name GetBiddingHighestBidView
   * @request GET:/Services/Authenticated/Bidding/IBiddingHighestBid/GetBiddingHighestBidViewAsync/{estateUid}
   * @response `200` `BiddingBiddingHighestBidView` The response type
   */
  export namespace GetBiddingHighestBidView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingHighestBidView;
  }
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingNotBiddingParticipant
   * @name GetBiddingNotBiddingParticipantView
   * @request GET:/Services/Authenticated/Bidding/IBiddingNotBiddingParticipant/GetBiddingNotBiddingParticipantViewAsync/{estateUid}
   * @response `200` `BiddingBiddingNotBiddingParticipantView` The response type
   */
  export namespace GetBiddingNotBiddingParticipantView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingNotBiddingParticipantView;
  }
  /**
   * No description
   * @tags Authenticated.Bidding.IBiddingPaused
   * @name GetBiddingPausedView
   * @request GET:/Services/Authenticated/Bidding/IBiddingPaused/GetBiddingPausedViewAsync/{estateUid}
   * @response `200` `BiddingBiddingPausedView` The response type
   */
  export namespace GetBiddingPausedView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingPausedView;
  }
  /**
   * @description Adds a new bid
   * @tags Authenticated.Bidding.IBiddingPlaceBid
   * @name AddBid
   * @request POST:/Services/Authenticated/Bidding/IBiddingPlaceBid/AddBid/{estateUid}
   * @response `200` `boolean` The response type
   */
  export namespace AddBid {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = BiddingNewBid;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * @description Returns the data for placing a bid
   * @tags Authenticated.Bidding.IBiddingPlaceBid
   * @name GetBiddingPlaceBidView
   * @request GET:/Services/Authenticated/Bidding/IBiddingPlaceBid/GetBiddingPlaceBidViewAsync/{estateUid}
   * @response `200` `BiddingBiddingPlaceBidView` The response type
   */
  export namespace GetBiddingPlaceBidView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingPlaceBidView;
  }
  /**
   * @description Returns the viewwith data after the entry.
   * @tags Authenticated.BuySell.Buy.IBuyAfterEntry
   * @name GetBuyAfterEntryView
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyAfterEntry/GetBuyAfterEntryViewAsync/{estateUid}
   * @response `200` `BuySellBuyBuyAfterEntryView` The response type
   */
  export namespace GetBuyAfterEntryView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuyBuyAfterEntryView;
  }
  /**
   * @description Returns the buy contract view
   * @tags Authenticated.BuySell.Buy.IBuyContract
   * @name GetBuyContractView
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyContract/GetBuyContractViewAsync/{estateUid}
   * @response `200` `BuySellBuyBuyContractView` The response type
   */
  export namespace GetBuyContractView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuyBuyContractView;
  }
  /**
   * @description Returns the buy downpayment view
   * @tags Authenticated.BuySell.Buy.IBuyDownPayment
   * @name GetBuyDownPaymentView
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyDownPayment/GetBuyDownPaymentViewAsync/{estateUid}
   * @response `200` `BuySellBuyBuyDownPaymentView` The response type
   */
  export namespace GetBuyDownPaymentView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuyBuyDownPaymentView;
  }
  /**
   * @description Returns the view with data for the entry.
   * @tags Authenticated.BuySell.Buy.IBuyEntry
   * @name GetBuyEntryView
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEntry/GetBuyEntryViewAsync/{estateUid}
   * @response `200` `BuySellBuyBuyEntryView` The response type
   */
  export namespace GetBuyEntryView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuyBuyEntryView;
  }
  /**
   * @description Returns the view with data for the estate.
   * @tags Authenticated.BuySell.Buy.IBuyEstateData
   * @name GetBuyEstateDataView
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetBuyEstateDataViewAsync/{estateUid}/{imageSize}
   * @response `200` `BuySellBuyBuyEstateDataView` The response type
   */
  export namespace GetBuyEstateDataView {
    export type RequestParams = { estateUid: string; imageSize: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuyBuyEstateDataView;
  }
  /**
   * @description Returns the image content
   * @tags Authenticated.BuySell.Buy.IBuyEstateData
   * @name GetImageContent
   * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetImageContentAsync/{estateUid}/{imageUid}
   * @response `200` `File` The response type
   */
  export namespace GetImageContent {
    export type RequestParams = { estateUid: string; imageUid: string };
    export type RequestQuery = { width?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns information about a sell after entry view
   * @tags Authenticated.BuySell.Sell.ISellAfterEntry
   * @name GetSellAfterEntryView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellAfterEntry/GetSellAfterEntryViewAsync/{estateUid}
   * @response `200` `BuySellSellSellAfterEntryView` The response type
   */
  export namespace GetSellAfterEntryView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellAfterEntryView;
  }
  /**
   * @description Retunrs the sell contract view
   * @tags Authenticated.BuySell.Sell.ISellContract
   * @name GetSellContractView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellContract/GetSellContractViewAsync/{estateUid}
   * @response `200` `BuySellSellSellContractView` The response type
   */
  export namespace GetSellContractView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellContractView;
  }
  /**
   * @description Returns the sell down payment view
   * @tags Authenticated.BuySell.Sell.ISellDownPayment
   * @name GetSellDownPaymentView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellDownPayment/GetSellDownPaymentViewAsync/{estateUid}
   * @response `200` `BuySellSellSellDownPaymentView` The response type
   */
  export namespace GetSellDownPaymentView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellDownPaymentView;
  }
  /**
   * @description Returns the sell entry view
   * @tags Authenticated.BuySell.Sell.ISellEntry
   * @name GetSellEntryView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellEntry/GetSellEntryViewAsync/{estateUid}
   * @response `200` `BuySellSellSellEntryView` The response type
   */
  export namespace GetSellEntryView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellEntryView;
  }
  /**
   * @description Returns the view with data for the estate.
   * @tags Authenticated.BuySell.Sell.ISellEstateData
   * @name GetSellEstateDataView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellEstateData/GetSellEstateDataViewAsync/{estateUid}/{imageSize}
   * @response `200` `BuySellSellSellEstateDataView` The response type
   */
  export namespace GetSellEstateDataView {
    export type RequestParams = { estateUid: string; imageSize: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellEstateDataView;
  }
  /**
   * @description Returns the data for the sell marketing view.
   * @tags Authenticated.BuySell.Sell.ISellMarketing
   * @name GetSellMarketingView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewAsync/{estateUid}
   * @response `200` `BuySellSellSellMarketingView` The response type
   */
  export namespace GetSellMarketingView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellMarketingView;
  }
  /**
   * @description Returns the data for the sell marketing view.
   * @tags Authenticated.BuySell.Sell.ISellMarketing
   * @name GetSellMarketingViewGraph
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewGraphAsync/{estateUid}
   * @response `200` `BuySellSellSellMarketingViewGraph` Contains data to show in the view
   */
  export namespace GetSellMarketingViewGraph {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = { startDate?: string; endDate?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellMarketingViewGraph;
  }
  /**
   * @description Returns the sell preparations view
   * @tags Authenticated.BuySell.Sell.ISellPreparations
   * @name GetSellPreparationsView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellPreparations/GetSellPreparationsViewAsync/{estateUid}
   * @response `200` `BuySellSellSellPreparationsView` The response type
   */
  export namespace GetSellPreparationsView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellPreparationsView;
  }
  /**
   * @description Returns the viewing and bidding view
   * @tags Authenticated.BuySell.Sell.ISellViewingBidding
   * @name GetSellViewingBiddingView
   * @request GET:/Services/Authenticated/BuySell/Sell/ISellViewingBidding/GetSellViewingBiddingViewAsync/{estateUid}
   * @response `200` `BuySellSellSellViewingBiddingView` The response type
   */
  export namespace GetSellViewingBiddingView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellSellSellViewingBiddingView;
  }
  /**
   * @description Adds a bid.
   * @tags Authenticated.IBiddingView
   * @name AddBid2
   * @request POST:/Services/Authenticated/IBiddingView/AddBid/{estateUid}
   * @originalName addBid
   * @duplicate
   * @response `200` `boolean` The response type
   */
  export namespace AddBid2 {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = BiddingNewBid;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * @description The bidding view
   * @tags Authenticated.IBiddingView
   * @name GetBiddingView
   * @request GET:/Services/Authenticated/IBiddingView/GetBiddingView/{estateUid}
   * @response `200` `BiddingBiddingView` Represents the bidding view
   */
  export namespace GetBiddingView {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingBiddingView;
  }
  /**
   * @description Add checklist item to checklist with specified checklist id
   * @tags Authenticated.IChecklistView
   * @name AddChecklistItem
   * @request POST:/Services/Authenticated/IChecklistView/AddChecklistItem/{estateUid}/{checklistId}
   * @response `200` `void` Success
   */
  export namespace AddChecklistItem {
    export type RequestParams = { estateUid: string; checklistId: string };
    export type RequestQuery = {};
    export type RequestBody = ChecklistChecklistItem;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IChecklistView
   * @name GetChecklist
   * @request GET:/Services/Authenticated/IChecklistView/GetChecklist/{estateUid}/{checklistId}
   * @response `200` `ChecklistChecklist` Represents the a checklist
   */
  export namespace GetChecklist {
    export type RequestParams = { estateUid: string; checklistId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ChecklistChecklist;
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IChecklistView
   * @name GetChecklistsForEstate
   * @request GET:/Services/Authenticated/IChecklistView/GetChecklistsForEstate/{estateUid}
   * @response `200` `(ChecklistChecklistStep)[]` The response type
   */
  export namespace GetChecklistsForEstate {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ChecklistChecklistStep[];
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IChecklistView
   * @name SetChecklistItemState
   * @request GET:/Services/Authenticated/IChecklistView/SetChecklistItemState/{estateUid}/{checklistId}/{checklistItemId}/{checkedState}
   * @response `200` `void` Success
   */
  export namespace SetChecklistItemState {
    export type RequestParams = { estateUid: string; checklistId: string; checklistItemId: string; checkedState: boolean };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IDocumentView
   * @name GetDocumentsForEstate
   * @request GET:/Services/Authenticated/IDocumentView/GetDocumentsForEstate/{estateUid}
   * @response `200` `(DocumentDocument)[]` The response type
   */
  export namespace GetDocumentsForEstate {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentDocument[];
  }
  /**
   * @description Returns the requested estate
   * @tags Authenticated.IEstateView
   * @name GetByEstateId
   * @request GET:/Services/Authenticated/IEstateView/GetByEstateId/{view}/{uid}
   * @response `200` `BuySellBuySellEstate` Common properties for the estate in the buy or sell views
   */
  export namespace GetByEstateId {
    export type RequestParams = { view: string; uid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellBuySellEstate;
  }
  /**
   * @description Returns the form data
   * @tags Authenticated.IFormView
   * @name GetFormDataForEstate
   * @request GET:/Services/Authenticated/IFormView/GetFormDataForEstate/{estateUid}/{formUid}
   * @response `200` `FormFormData` The response type
   */
  export namespace GetFormDataForEstate {
    export type RequestParams = { estateUid: string; formUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormFormData;
  }
  /**
   * @description Returns the forms for supplied estate
   * @tags Authenticated.IFormView
   * @name GetFormsForEstate
   * @request GET:/Services/Authenticated/IFormView/GetFormsForEstate/{estateUid}
   * @response `200` `(FormForm)[]` The response type
   */
  export namespace GetFormsForEstate {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormForm[];
  }
  /**
   * @description Updates the form
   * @tags Authenticated.IFormView
   * @name UpdateFormDataForEstate
   * @request POST:/Services/Authenticated/IFormView/UpdateFormDataForEstate/{estateUid}/{formUid}/{submitToBroker}
   * @response `200` `FormFormData` The response type
   */
  export namespace UpdateFormDataForEstate {
    export type RequestParams = { estateUid: string; formUid: string; submitToBroker: boolean };
    export type RequestQuery = {};
    export type RequestBody = JsonNode;
    export type RequestHeaders = {};
    export type ResponseBody = FormFormData;
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IHomepageView
   * @name GetHomepageData
   * @request GET:/Services/Authenticated/IHomepageView/GetHomepageDataAsync
   * @response `200` `HomepageHomepageData` The hompage data
   */
  export namespace GetHomepageData {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HomepageHomepageData;
  }
  /**
   * @description Updates or saves a users loan commitment info
   * @tags Authenticated.ILoanCommitmentInfo
   * @name UpsertLoanCommitmentInfo
   * @request POST:/Services/Authenticated/ILoanCommitmentInfo/UpsertLoanCommitmentInfo/{estateUid}
   * @response `200` `BiddingLoanCommitmentInfo` Loan commitment for a user
   */
  export namespace UpsertLoanCommitmentInfo {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = BiddingLoanCommitmentInfo;
    export type RequestHeaders = {};
    export type ResponseBody = BiddingLoanCommitmentInfo;
  }
  /**
   * @description Returns the all tips with Type = Offer for the given estate
   * @tags Authenticated.IOffersView
   * @name GetOffersForEstate
   * @request GET:/Services/Authenticated/IOffersView/GetOffersForEstate/{estateUid}
   * @response `200` `(BuySellTip)[]` The response type
   */
  export namespace GetOffersForEstate {
    export type RequestParams = { estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuySellTip[];
  }
  /**
   * @description Invoked when the correct user was logged in.
   * @tags Authenticated.IOnboarding
   * @name CorrectUserLoggedIn
   * @request GET:/Services/Authenticated/IOnboarding/CorrectUserLoggedInAsync/{source}/{contactId}/{estateId}
   * @response `200` `void` Success
   */
  export namespace CorrectUserLoggedIn {
    export type RequestParams = { source: string; contactId: string; estateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Status of the logged in user when it should be the supplied contact.
   * @tags Authenticated.IOnboarding
   * @name GetOnboardingView
   * @request GET:/Services/Authenticated/IOnboarding/GetOnboardingViewAsync/{source}/{contactId}/{estateId}
   * @response `200` `OnboardingOnboardingView` Represents the onboarding view
   */
  export namespace GetOnboardingView {
    export type RequestParams = { source: string; contactId: string; estateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OnboardingOnboardingView;
  }
  /**
   * @description Sets the contact ssn to the ssn of the logged in person
   * @tags Authenticated.IOnboarding
   * @name SetContactSsn
   * @request GET:/Services/Authenticated/IOnboarding/SetContactSsnAsync/{source}/{contactId}
   * @response `200` `OnboardingContact` Contains status of the onboarding process
   */
  export namespace SetContactSsn {
    export type RequestParams = { source: string; contactId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OnboardingContact;
  }
  /**
   * @description Processes the recommendation. Composes a new email and tries to send it.
   * @tags Authenticated.IRecommendation
   * @name ProcessRecommendation
   * @request GET:/Services/Authenticated/IRecommendation/ProcessRecommendation/{guid}
   * @response `200` `void` Success
   */
  export namespace ProcessRecommendation {
    export type RequestParams = { guid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Returns the structure to display on the homepage.
   * @tags Authenticated.IRecommendation
   * @name SendRecommendation
   * @request POST:/Services/Authenticated/IRecommendation/SendRecommendation
   * @response `200` `void` Success
   */
  export namespace SendRecommendation {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BuySellRecommendation;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Returns the current user
   * @tags Authenticated.IUserData
   * @name GetCurrentUser
   * @request GET:/Services/Authenticated/IUserData/GetCurrentUserAsync
   * @response `200` `UserUserInfo` Represents data for a user
   */
  export namespace GetCurrentUser {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserUserInfo;
  }
  /**
   * @description Returns all the user notifications.
   * @tags Authenticated.IUserData
   * @name GetNotifications
   * @request GET:/Services/Authenticated/IUserData/GetNotificationsAsync
   * @response `200` `(UserNotification)[]` The response type
   */
  export namespace GetNotifications {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserNotification[];
  }
  /**
   * @description Returns true or false depending on wether the user has any unread notifications.
   * @tags Authenticated.IUserData
   * @name GetNotificationsChangeToken
   * @request GET:/Services/Authenticated/IUserData/GetNotificationsChangeTokenAsync
   * @response `200` `string` The response type
   */
  export namespace GetNotificationsChangeToken {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns true or false depending on wether the user has any unread notifications.
   * @tags Authenticated.IUserData
   * @name HasUnreadNotifications
   * @request GET:/Services/Authenticated/IUserData/HasUnreadNotificationsAsync
   * @response `200` `boolean` The response type
   */
  export namespace HasUnreadNotifications {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * @description Mark notifications as read.
   * @tags Authenticated.IUserData
   * @name MarkNotificationsAsRead
   * @request GET:/Services/Authenticated/IUserData/MarkNotificationsAsRead/{notificationIds}
   * @response `200` `void` Success
   */
  export namespace MarkNotificationsAsRead {
    export type RequestParams = { notificationIds: string[] };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Public {
  /**
   * @description Returns the application data.
   * @tags Public.IApplication
   * @name GetApplicationData
   * @request GET:/Services/Public/IApplication/GetApplicationDataAsync
   * @response `200` `ApplicationData` This sturcture contains information about the appplication. This structure is available by any user on the internet.
   */
  export namespace GetApplicationData {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationData;
  }
  /**
   * @description Returns a link to the broker image
   * @tags Public.IApplication
   * @name GetBrokerImage
   * @request GET:/Services/Public/IApplication/GetBrokerImageAsync/{brokerUid}/{cacheToken}
   * @response `200` `File` The response type
   */
  export namespace GetBrokerImage {
    export type RequestParams = { brokerUid: string; cacheToken: string };
    export type RequestQuery = { transformOps?: string[] };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns the link to specified contact and estate using supplied source. Both the contact and estate needs to reside in the same source.
   * @tags Public.IApplication
   * @name GetContactEstateLink
   * @request GET:/Services/Public/IApplication/GetContactEstateLinkAsync/{source}/{contactUid}/{view}/{estateUid}
   * @response `200` `string` The response type
   */
  export namespace GetContactEstateLink {
    export type RequestParams = { source: string; contactUid: string; view: string; estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns the view for supplied estate and contact in the source backend.
   * @tags Public.IApplication
   * @name GetContactEstateView
   * @request GET:/Services/Public/IApplication/GetContactEstateViewAsync/{source}/{contactId}/{view}/{estateId}
   * @response `200` `File` The response type
   */
  export namespace GetContactEstateView {
    export type RequestParams = { source: string; contactId: string; view: string; estateId: string };
    export type RequestQuery = { access_token?: string; frontAddress?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns a description of supplied error..
   * @tags Public.IApplication
   * @name GetErrorInfo
   * @request GET:/Services/Public/IApplication/GetErrorInfoAsync/{estateUid}/{errorCode}
   * @response `200` `ErrorInfo` Contains information about an error
   */
  export namespace GetErrorInfo {
    export type RequestParams = { estateUid: string; errorCode: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ErrorInfo;
  }
  /**
   * @description Returns the estate image
   * @tags Public.IApplication
   * @name GetEstateImage
   * @request GET:/Services/Public/IApplication/GetEstateImageAsync/{estateUid}/{imageUid}/{cacheToken}
   * @response `200` `File` The response type
   */
  export namespace GetEstateImage {
    export type RequestParams = { estateUid: string; imageUid: string; cacheToken: string };
    export type RequestQuery = { transformOps?: string[]; downloadUid?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns the link to specified estate using supplied source. The estate needs to have an external id matching supplied source.
   * @tags Public.IApplication
   * @name GetEstateLink
   * @request GET:/Services/Public/IApplication/GetEstateLinkAsync/{source}/{view}/{estateUid}
   * @response `200` `string` The response type
   */
  export namespace GetEstateLink {
    export type RequestParams = { source: string; view: string; estateUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns the index html page
   * @tags Public.IApplication
   * @name GetIndexHtml
   * @request GET:/Services/Public/IApplication/GetIndexHtmlAsync
   * @response `200` `File` The response type
   */
  export namespace GetIndexHtml {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns a link to the broker image
   * @tags Public.IApplication
   * @name GetOriginalBrokerImage
   * @request GET:/Services/Public/IApplication/GetOriginalBrokerImageAsync/{brokerUid}/{cacheToken}
   * @response `200` `File` The response type
   */
  export namespace GetOriginalBrokerImage {
    export type RequestParams = { brokerUid: string; cacheToken: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns the estate image
   * @tags Public.IApplication
   * @name GetOriginalEstateImage
   * @request GET:/Services/Public/IApplication/GetOriginalEstateImageAsync/{estateUid}/{imageUid}/{cacheToken}
   * @response `200` `File` The response type
   */
  export namespace GetOriginalEstateImage {
    export type RequestParams = { estateUid: string; imageUid: string; cacheToken: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns all the steps
   * @tags Public.IApplication
   * @name GetSteps
   * @request GET:/Services/Public/IApplication/GetSteps
   * @response `200` `(EstateStep)[]` The response type
   */
  export namespace GetSteps {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EstateStep[];
  }
  /**
   * @description Starts a new download. Returns a guid if download can be done.
   * @tags Public.IApplication
   * @name StartNewDownload
   * @request GET:/Services/Public/IApplication/StartNewDownloadAsync/{dowloadsUid}
   * @response `200` `string` The response type
   */
  export namespace StartNewDownload {
    export type RequestParams = { dowloadsUid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Starts a new download session
   * @tags Public.IApplication
   * @name StartNewDownloads
   * @request GET:/Services/Public/IApplication/StartNewDownloadsAsync
   * @response `200` `string` The response type
   */
  export namespace StartNewDownloads {
    export type RequestParams = {};
    export type RequestQuery = { maxNumberOfDownloads?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Creates a new Oidc scope.
   * @tags Public.IBankId
   * @name CreateOidcScope
   * @request GET:/Services/Public/IBankId/CreateOidcScopeAsync
   * @response `200` `string` The response type
   */
  export namespace CreateOidcScope {
    export type RequestParams = {};
    export type RequestQuery = { redirectUri?: string; userAgent?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns the QR code for supplied auto start token.
   * @tags Public.IBankId
   * @name CreateQrCode
   * @request GET:/Services/Public/IBankId/CreateQrCodeAsync/{autoStartToken}
   * @response `200` `File` The response type
   */
  export namespace CreateQrCode {
    export type RequestParams = { autoStartToken: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
  /**
   * @description Returns the QR code for supplied auto start token.
   * @tags Public.IBankId
   * @name GetAuthStatus
   * @request GET:/Services/Public/IBankId/GetAuthStatusAsync/{oidcScopeId}
   * @response `200` `BankIdAuthStatus` The authorization progress
   */
  export namespace GetAuthStatus {
    export type RequestParams = { oidcScopeId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankIdAuthStatus;
  }
  /**
   * @description Returns the client ip.
   * @tags Public.IBankId
   * @name GetClientIp
   * @request GET:/Services/Public/IBankId/GetClientIpAsync
   * @response `200` `string` The response type
   */
  export namespace GetClientIp {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns the jwt token for supplied oidc scope
   * @tags Public.IBankId
   * @name GetJwtToken
   * @request GET:/Services/Public/IBankId/GetJwtTokenAsync/{oidcScopeId}
   * @response `200` `string` The response type
   */
  export namespace GetJwtToken {
    export type RequestParams = { oidcScopeId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Returns the redirect to perform when the user has been logged in.
   * @tags Public.IBankId
   * @name GetRedirect
   * @request GET:/Services/Public/IBankId/GetRedirectAsync/{oidcScopeId}
   * @response `200` `string` The response type
   */
  export namespace GetRedirect {
    export type RequestParams = { oidcScopeId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Refreshes the supplied jwt token
   * @tags Public.IBankId
   * @name RefreshToken
   * @request GET:/Services/Public/IBankId/RefreshTokenAsync/{jwt}
   * @response `200` `string` The response type
   */
  export namespace RefreshToken {
    export type RequestParams = { jwt: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Starts a new login using the supplied oidc scope.
   * @tags Public.IBankId
   * @name StartLocalLogin
   * @request GET:/Services/Public/IBankId/StartLocalLoginAsync/{oidcScopeId}
   * @response `200` `string` The response type
   */
  export namespace StartLocalLogin {
    export type RequestParams = { oidcScopeId: string };
    export type RequestQuery = { clientIp?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Starts a new login using the supplied oidc scope.
   * @tags Public.IBankId
   * @name StartLogin
   * @request GET:/Services/Public/IBankId/StartLoginAsync/{oidcScopeId}
   * @response `200` `BankIdAuthStatus` The authorization progress
   */
  export namespace StartLogin {
    export type RequestParams = { oidcScopeId: string };
    export type RequestQuery = { clientIp?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankIdAuthStatus;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://localhost/RA/Customer';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title RA.Customer
 * @version 1.0.0
 * @baseUrl https://localhost/RA/Customer
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  authenticated = {
    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingBidLooser
     * @name GetBiddingBidLooserView
     * @request GET:/Services/Authenticated/Bidding/IBiddingBidLooser/GetBiddingBidLooserViewAsync/{estateUid}
     * @response `200` `BiddingBiddingBidLooserView` The response type
     */
    getBiddingBidLooserView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingBidLooserView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingBidLooser/GetBiddingBidLooserViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingBidWinner
     * @name GetBiddingBidWinnerView
     * @request GET:/Services/Authenticated/Bidding/IBiddingBidWinner/GetBiddingBidWinnerViewAsync/{estateUid}
     * @response `200` `BiddingBiddingBidWinnerView` The response type
     */
    getBiddingBidWinnerView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingBidWinnerView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingBidWinner/GetBiddingBidWinnerViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingHalted
     * @name GetBiddingHaltedView
     * @request GET:/Services/Authenticated/Bidding/IBiddingHalted/GetBiddingHaltedViewAsync/{estateUid}
     * @response `200` `BiddingBiddingHaltedView` The response type
     */
    getBiddingHaltedView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingHaltedView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingHalted/GetBiddingHaltedViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingHighestBid
     * @name GetBiddingHighestBidView
     * @request GET:/Services/Authenticated/Bidding/IBiddingHighestBid/GetBiddingHighestBidViewAsync/{estateUid}
     * @response `200` `BiddingBiddingHighestBidView` The response type
     */
    getBiddingHighestBidView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingHighestBidView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingHighestBid/GetBiddingHighestBidViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingNotBiddingParticipant
     * @name GetBiddingNotBiddingParticipantView
     * @request GET:/Services/Authenticated/Bidding/IBiddingNotBiddingParticipant/GetBiddingNotBiddingParticipantViewAsync/{estateUid}
     * @response `200` `BiddingBiddingNotBiddingParticipantView` The response type
     */
    getBiddingNotBiddingParticipantView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingNotBiddingParticipantView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingNotBiddingParticipant/GetBiddingNotBiddingParticipantViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authenticated.Bidding.IBiddingPaused
     * @name GetBiddingPausedView
     * @request GET:/Services/Authenticated/Bidding/IBiddingPaused/GetBiddingPausedViewAsync/{estateUid}
     * @response `200` `BiddingBiddingPausedView` The response type
     */
    getBiddingPausedView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingPausedView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingPaused/GetBiddingPausedViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a new bid
     *
     * @tags Authenticated.Bidding.IBiddingPlaceBid
     * @name AddBid
     * @request POST:/Services/Authenticated/Bidding/IBiddingPlaceBid/AddBid/{estateUid}
     * @response `200` `boolean` The response type
     */
    addBid: (estateUid: string, bid: BiddingNewBid, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingPlaceBid/AddBid/${estateUid}`,
        method: 'POST',
        body: bid,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the data for placing a bid
     *
     * @tags Authenticated.Bidding.IBiddingPlaceBid
     * @name GetBiddingPlaceBidView
     * @request GET:/Services/Authenticated/Bidding/IBiddingPlaceBid/GetBiddingPlaceBidViewAsync/{estateUid}
     * @response `200` `BiddingBiddingPlaceBidView` The response type
     */
    getBiddingPlaceBidView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingPlaceBidView, any>({
        path: `/RA/Customer/Services/Authenticated/Bidding/IBiddingPlaceBid/GetBiddingPlaceBidViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the viewwith data after the entry.
     *
     * @tags Authenticated.BuySell.Buy.IBuyAfterEntry
     * @name GetBuyAfterEntryView
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyAfterEntry/GetBuyAfterEntryViewAsync/{estateUid}
     * @response `200` `BuySellBuyBuyAfterEntryView` The response type
     */
    getBuyAfterEntryView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellBuyBuyAfterEntryView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyAfterEntry/GetBuyAfterEntryViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the buy contract view
     *
     * @tags Authenticated.BuySell.Buy.IBuyContract
     * @name GetBuyContractView
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyContract/GetBuyContractViewAsync/{estateUid}
     * @response `200` `BuySellBuyBuyContractView` The response type
     */
    getBuyContractView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellBuyBuyContractView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyContract/GetBuyContractViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the buy downpayment view
     *
     * @tags Authenticated.BuySell.Buy.IBuyDownPayment
     * @name GetBuyDownPaymentView
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyDownPayment/GetBuyDownPaymentViewAsync/{estateUid}
     * @response `200` `BuySellBuyBuyDownPaymentView` The response type
     */
    getBuyDownPaymentView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellBuyBuyDownPaymentView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyDownPayment/GetBuyDownPaymentViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the view with data for the entry.
     *
     * @tags Authenticated.BuySell.Buy.IBuyEntry
     * @name GetBuyEntryView
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEntry/GetBuyEntryViewAsync/{estateUid}
     * @response `200` `BuySellBuyBuyEntryView` The response type
     */
    getBuyEntryView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellBuyBuyEntryView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyEntry/GetBuyEntryViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the view with data for the estate.
     *
     * @tags Authenticated.BuySell.Buy.IBuyEstateData
     * @name GetBuyEstateDataView
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetBuyEstateDataViewAsync/{estateUid}/{imageSize}
     * @response `200` `BuySellBuyBuyEstateDataView` The response type
     */
    getBuyEstateDataView: (estateUid: string, imageSize: number, params: RequestParams = {}) =>
      this.request<BuySellBuyBuyEstateDataView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetBuyEstateDataViewAsync/${estateUid}/${imageSize}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the image content
     *
     * @tags Authenticated.BuySell.Buy.IBuyEstateData
     * @name GetImageContent
     * @request GET:/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetImageContentAsync/{estateUid}/{imageUid}
     * @response `200` `File` The response type
     */
    getImageContent: (estateUid: string, imageUid: string, query?: { width?: number }, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Buy/IBuyEstateData/GetImageContentAsync/${estateUid}/${imageUid}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Returns information about a sell after entry view
     *
     * @tags Authenticated.BuySell.Sell.ISellAfterEntry
     * @name GetSellAfterEntryView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellAfterEntry/GetSellAfterEntryViewAsync/{estateUid}
     * @response `200` `BuySellSellSellAfterEntryView` The response type
     */
    getSellAfterEntryView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellAfterEntryView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellAfterEntry/GetSellAfterEntryViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retunrs the sell contract view
     *
     * @tags Authenticated.BuySell.Sell.ISellContract
     * @name GetSellContractView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellContract/GetSellContractViewAsync/{estateUid}
     * @response `200` `BuySellSellSellContractView` The response type
     */
    getSellContractView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellContractView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellContract/GetSellContractViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the sell down payment view
     *
     * @tags Authenticated.BuySell.Sell.ISellDownPayment
     * @name GetSellDownPaymentView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellDownPayment/GetSellDownPaymentViewAsync/{estateUid}
     * @response `200` `BuySellSellSellDownPaymentView` The response type
     */
    getSellDownPaymentView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellDownPaymentView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellDownPayment/GetSellDownPaymentViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the sell entry view
     *
     * @tags Authenticated.BuySell.Sell.ISellEntry
     * @name GetSellEntryView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellEntry/GetSellEntryViewAsync/{estateUid}
     * @response `200` `BuySellSellSellEntryView` The response type
     */
    getSellEntryView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellEntryView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellEntry/GetSellEntryViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the view with data for the estate.
     *
     * @tags Authenticated.BuySell.Sell.ISellEstateData
     * @name GetSellEstateDataView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellEstateData/GetSellEstateDataViewAsync/{estateUid}/{imageSize}
     * @response `200` `BuySellSellSellEstateDataView` The response type
     */
    getSellEstateDataView: (estateUid: string, imageSize: number, params: RequestParams = {}) =>
      this.request<BuySellSellSellEstateDataView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellEstateData/GetSellEstateDataViewAsync/${estateUid}/${imageSize}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the data for the sell marketing view.
     *
     * @tags Authenticated.BuySell.Sell.ISellMarketing
     * @name GetSellMarketingView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewAsync/{estateUid}
     * @response `200` `BuySellSellSellMarketingView` The response type
     */
    getSellMarketingView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellMarketingView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the data for the sell marketing view.
     *
     * @tags Authenticated.BuySell.Sell.ISellMarketing
     * @name GetSellMarketingViewGraph
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewGraphAsync/{estateUid}
     * @response `200` `BuySellSellSellMarketingViewGraph` Contains data to show in the view
     */
    getSellMarketingViewGraph: (estateUid: string, query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<BuySellSellSellMarketingViewGraph, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellMarketing/GetSellMarketingViewGraphAsync/${estateUid}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the sell preparations view
     *
     * @tags Authenticated.BuySell.Sell.ISellPreparations
     * @name GetSellPreparationsView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellPreparations/GetSellPreparationsViewAsync/{estateUid}
     * @response `200` `BuySellSellSellPreparationsView` The response type
     */
    getSellPreparationsView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellPreparationsView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellPreparations/GetSellPreparationsViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the viewing and bidding view
     *
     * @tags Authenticated.BuySell.Sell.ISellViewingBidding
     * @name GetSellViewingBiddingView
     * @request GET:/Services/Authenticated/BuySell/Sell/ISellViewingBidding/GetSellViewingBiddingViewAsync/{estateUid}
     * @response `200` `BuySellSellSellViewingBiddingView` The response type
     */
    getSellViewingBiddingView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellSellSellViewingBiddingView, any>({
        path: `/RA/Customer/Services/Authenticated/BuySell/Sell/ISellViewingBidding/GetSellViewingBiddingViewAsync/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a bid.
     *
     * @tags Authenticated.IBiddingView
     * @name AddBid2
     * @request POST:/Services/Authenticated/IBiddingView/AddBid/{estateUid}
     * @originalName addBid
     * @duplicate
     * @response `200` `boolean` The response type
     */
    addBid2: (estateUid: string, bid: BiddingNewBid, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/RA/Customer/Services/Authenticated/IBiddingView/AddBid/${estateUid}`,
        method: 'POST',
        body: bid,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description The bidding view
     *
     * @tags Authenticated.IBiddingView
     * @name GetBiddingView
     * @request GET:/Services/Authenticated/IBiddingView/GetBiddingView/{estateUid}
     * @response `200` `BiddingBiddingView` Represents the bidding view
     */
    getBiddingView: (estateUid: string, params: RequestParams = {}) =>
      this.request<BiddingBiddingView, any>({
        path: `/RA/Customer/Services/Authenticated/IBiddingView/GetBiddingView/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Add checklist item to checklist with specified checklist id
     *
     * @tags Authenticated.IChecklistView
     * @name AddChecklistItem
     * @request POST:/Services/Authenticated/IChecklistView/AddChecklistItem/{estateUid}/{checklistId}
     * @response `200` `void` Success
     */
    addChecklistItem: (estateUid: string, checklistId: string, item: ChecklistChecklistItem, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IChecklistView/AddChecklistItem/${estateUid}/${checklistId}`,
        method: 'POST',
        body: item,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IChecklistView
     * @name GetChecklist
     * @request GET:/Services/Authenticated/IChecklistView/GetChecklist/{estateUid}/{checklistId}
     * @response `200` `ChecklistChecklist` Represents the a checklist
     */
    getChecklist: (estateUid: string, checklistId: string, params: RequestParams = {}) =>
      this.request<ChecklistChecklist, any>({
        path: `/RA/Customer/Services/Authenticated/IChecklistView/GetChecklist/${estateUid}/${checklistId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IChecklistView
     * @name GetChecklistsForEstate
     * @request GET:/Services/Authenticated/IChecklistView/GetChecklistsForEstate/{estateUid}
     * @response `200` `(ChecklistChecklistStep)[]` The response type
     */
    getChecklistsForEstate: (estateUid: string, params: RequestParams = {}) =>
      this.request<ChecklistChecklistStep[], any>({
        path: `/RA/Customer/Services/Authenticated/IChecklistView/GetChecklistsForEstate/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IChecklistView
     * @name SetChecklistItemState
     * @request GET:/Services/Authenticated/IChecklistView/SetChecklistItemState/{estateUid}/{checklistId}/{checklistItemId}/{checkedState}
     * @response `200` `void` Success
     */
    setChecklistItemState: (
      estateUid: string,
      checklistId: string,
      checklistItemId: string,
      checkedState: boolean,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IChecklistView/SetChecklistItemState/${estateUid}/${checklistId}/${checklistItemId}/${checkedState}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IDocumentView
     * @name GetDocumentsForEstate
     * @request GET:/Services/Authenticated/IDocumentView/GetDocumentsForEstate/{estateUid}
     * @response `200` `(DocumentDocument)[]` The response type
     */
    getDocumentsForEstate: (estateUid: string, params: RequestParams = {}) =>
      this.request<DocumentDocument[], any>({
        path: `/RA/Customer/Services/Authenticated/IDocumentView/GetDocumentsForEstate/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the requested estate
     *
     * @tags Authenticated.IEstateView
     * @name GetByEstateId
     * @request GET:/Services/Authenticated/IEstateView/GetByEstateId/{view}/{uid}
     * @response `200` `BuySellBuySellEstate` Common properties for the estate in the buy or sell views
     */
    getByEstateId: (view: string, uid: string, params: RequestParams = {}) =>
      this.request<BuySellBuySellEstate, any>({
        path: `/RA/Customer/Services/Authenticated/IEstateView/GetByEstateId/${view}/${uid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the form data
     *
     * @tags Authenticated.IFormView
     * @name GetFormDataForEstate
     * @request GET:/Services/Authenticated/IFormView/GetFormDataForEstate/{estateUid}/{formUid}
     * @response `200` `FormFormData` The response type
     */
    getFormDataForEstate: (estateUid: string, formUid: string, params: RequestParams = {}) =>
      this.request<FormFormData, any>({
        path: `/RA/Customer/Services/Authenticated/IFormView/GetFormDataForEstate/${estateUid}/${formUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the forms for supplied estate
     *
     * @tags Authenticated.IFormView
     * @name GetFormsForEstate
     * @request GET:/Services/Authenticated/IFormView/GetFormsForEstate/{estateUid}
     * @response `200` `(FormForm)[]` The response type
     */
    getFormsForEstate: (estateUid: string, params: RequestParams = {}) =>
      this.request<FormForm[], any>({
        path: `/RA/Customer/Services/Authenticated/IFormView/GetFormsForEstate/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the form
     *
     * @tags Authenticated.IFormView
     * @name UpdateFormDataForEstate
     * @request POST:/Services/Authenticated/IFormView/UpdateFormDataForEstate/{estateUid}/{formUid}/{submitToBroker}
     * @response `200` `FormFormData` The response type
     */
    updateFormDataForEstate: (estateUid: string, formUid: string, submitToBroker: boolean, data: JsonNode, params: RequestParams = {}) =>
      this.request<FormFormData, any>({
        path: `/RA/Customer/Services/Authenticated/IFormView/UpdateFormDataForEstate/${estateUid}/${formUid}/${submitToBroker}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IHomepageView
     * @name GetHomepageData
     * @request GET:/Services/Authenticated/IHomepageView/GetHomepageDataAsync
     * @response `200` `HomepageHomepageData` The hompage data
     */
    getHomepageData: (params: RequestParams = {}) =>
      this.request<HomepageHomepageData, any>({
        path: `/RA/Customer/Services/Authenticated/IHomepageView/GetHomepageDataAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates or saves a users loan commitment info
     *
     * @tags Authenticated.ILoanCommitmentInfo
     * @name UpsertLoanCommitmentInfo
     * @request POST:/Services/Authenticated/ILoanCommitmentInfo/UpsertLoanCommitmentInfo/{estateUid}
     * @response `200` `BiddingLoanCommitmentInfo` Loan commitment for a user
     */
    upsertLoanCommitmentInfo: (estateUid: string, loanCommitmentInfo: BiddingLoanCommitmentInfo, params: RequestParams = {}) =>
      this.request<BiddingLoanCommitmentInfo, any>({
        path: `/RA/Customer/Services/Authenticated/ILoanCommitmentInfo/UpsertLoanCommitmentInfo/${estateUid}`,
        method: 'POST',
        body: loanCommitmentInfo,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the all tips with Type = Offer for the given estate
     *
     * @tags Authenticated.IOffersView
     * @name GetOffersForEstate
     * @request GET:/Services/Authenticated/IOffersView/GetOffersForEstate/{estateUid}
     * @response `200` `(BuySellTip)[]` The response type
     */
    getOffersForEstate: (estateUid: string, params: RequestParams = {}) =>
      this.request<BuySellTip[], any>({
        path: `/RA/Customer/Services/Authenticated/IOffersView/GetOffersForEstate/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Invoked when the correct user was logged in.
     *
     * @tags Authenticated.IOnboarding
     * @name CorrectUserLoggedIn
     * @request GET:/Services/Authenticated/IOnboarding/CorrectUserLoggedInAsync/{source}/{contactId}/{estateId}
     * @response `200` `void` Success
     */
    correctUserLoggedIn: (source: string, contactId: string, estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IOnboarding/CorrectUserLoggedInAsync/${source}/${contactId}/${estateId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Status of the logged in user when it should be the supplied contact.
     *
     * @tags Authenticated.IOnboarding
     * @name GetOnboardingView
     * @request GET:/Services/Authenticated/IOnboarding/GetOnboardingViewAsync/{source}/{contactId}/{estateId}
     * @response `200` `OnboardingOnboardingView` Represents the onboarding view
     */
    getOnboardingView: (source: string, contactId: string, estateId: string, params: RequestParams = {}) =>
      this.request<OnboardingOnboardingView, any>({
        path: `/RA/Customer/Services/Authenticated/IOnboarding/GetOnboardingViewAsync/${source}/${contactId}/${estateId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets the contact ssn to the ssn of the logged in person
     *
     * @tags Authenticated.IOnboarding
     * @name SetContactSsn
     * @request GET:/Services/Authenticated/IOnboarding/SetContactSsnAsync/{source}/{contactId}
     * @response `200` `OnboardingContact` Contains status of the onboarding process
     */
    setContactSsn: (source: string, contactId: string, params: RequestParams = {}) =>
      this.request<OnboardingContact, any>({
        path: `/RA/Customer/Services/Authenticated/IOnboarding/SetContactSsnAsync/${source}/${contactId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Processes the recommendation. Composes a new email and tries to send it.
     *
     * @tags Authenticated.IRecommendation
     * @name ProcessRecommendation
     * @request GET:/Services/Authenticated/IRecommendation/ProcessRecommendation/{guid}
     * @response `200` `void` Success
     */
    processRecommendation: (guid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IRecommendation/ProcessRecommendation/${guid}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns the structure to display on the homepage.
     *
     * @tags Authenticated.IRecommendation
     * @name SendRecommendation
     * @request POST:/Services/Authenticated/IRecommendation/SendRecommendation
     * @response `200` `void` Success
     */
    sendRecommendation: (recommendation: BuySellRecommendation, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IRecommendation/SendRecommendation`,
        method: 'POST',
        body: recommendation,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns the current user
     *
     * @tags Authenticated.IUserData
     * @name GetCurrentUser
     * @request GET:/Services/Authenticated/IUserData/GetCurrentUserAsync
     * @response `200` `UserUserInfo` Represents data for a user
     */
    getCurrentUser: (params: RequestParams = {}) =>
      this.request<UserUserInfo, any>({
        path: `/RA/Customer/Services/Authenticated/IUserData/GetCurrentUserAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all the user notifications.
     *
     * @tags Authenticated.IUserData
     * @name GetNotifications
     * @request GET:/Services/Authenticated/IUserData/GetNotificationsAsync
     * @response `200` `(UserNotification)[]` The response type
     */
    getNotifications: (params: RequestParams = {}) =>
      this.request<UserNotification[], any>({
        path: `/RA/Customer/Services/Authenticated/IUserData/GetNotificationsAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns true or false depending on wether the user has any unread notifications.
     *
     * @tags Authenticated.IUserData
     * @name GetNotificationsChangeToken
     * @request GET:/Services/Authenticated/IUserData/GetNotificationsChangeTokenAsync
     * @response `200` `string` The response type
     */
    getNotificationsChangeToken: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Authenticated/IUserData/GetNotificationsChangeTokenAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns true or false depending on wether the user has any unread notifications.
     *
     * @tags Authenticated.IUserData
     * @name HasUnreadNotifications
     * @request GET:/Services/Authenticated/IUserData/HasUnreadNotificationsAsync
     * @response `200` `boolean` The response type
     */
    hasUnreadNotifications: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/RA/Customer/Services/Authenticated/IUserData/HasUnreadNotificationsAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Mark notifications as read.
     *
     * @tags Authenticated.IUserData
     * @name MarkNotificationsAsRead
     * @request GET:/Services/Authenticated/IUserData/MarkNotificationsAsRead/{notificationIds}
     * @response `200` `void` Success
     */
    markNotificationsAsRead: (notificationIds: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/RA/Customer/Services/Authenticated/IUserData/MarkNotificationsAsRead/${notificationIds}`,
        method: 'GET',
        ...params,
      }),
  };
  backend = {};
  iBoostadAdmin = {};
  iImpersonate = {
    /**
     * @description Impersonates the specified contact
     *
     * @tags IImpersonate
     * @name ImpersonateContactByUid
     * @request GET:/Services/IImpersonate/ImpersonateContactByUidAsync/{contactUid}
     * @response `200` `string` The response type
     */
    impersonateContactByUid: (contactUid: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/IImpersonate/ImpersonateContactByUidAsync/${contactUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Impersonates the specified contact
     *
     * @tags IImpersonate
     * @name ImpersonateContactByUidWithRedirect
     * @request GET:/Services/IImpersonate/ImpersonateContactByUidWithRedirectAsync/{contactUid}
     * @response `200` `File` The response type
     */
    impersonateContactByUidWithRedirect: (
      contactUid: string,
      query?: { view?: string; estateUid?: string; frontAddress?: string },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/IImpersonate/ImpersonateContactByUidWithRedirectAsync/${contactUid}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description This method creates an access token for a customer that matches supplied ssn
     *
     * @tags IImpersonate
     * @name ImpersonateUser
     * @request GET:/Services/IImpersonate/ImpersonateUserAsync/{ssn}
     * @response `200` `string` The response type
     */
    impersonateUser: (ssn: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/IImpersonate/ImpersonateUserAsync/${ssn}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Impersonates the specified user redirecting to the homepage view with the new user. This method is primarily used by developers to setup the initial page.
     *
     * @tags IImpersonate
     * @name ImpersonateUserWithRedirect
     * @request GET:/Services/IImpersonate/ImpersonateUserWithRedirectAsync/{ssn}
     * @response `200` `File` The response type
     */
    impersonateUserWithRedirect: (ssn: string, query?: { redirectUrl?: string }, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/IImpersonate/ImpersonateUserWithRedirectAsync/${ssn}`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  public = {
    /**
     * @description Returns the application data.
     *
     * @tags Public.IApplication
     * @name GetApplicationData
     * @request GET:/Services/Public/IApplication/GetApplicationDataAsync
     * @response `200` `ApplicationData` This sturcture contains information about the appplication. This structure is available by any user on the internet.
     */
    getApplicationData: (params: RequestParams = {}) =>
      this.request<ApplicationData, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetApplicationDataAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a link to the broker image
     *
     * @tags Public.IApplication
     * @name GetBrokerImage
     * @request GET:/Services/Public/IApplication/GetBrokerImageAsync/{brokerUid}/{cacheToken}
     * @response `200` `File` The response type
     */
    getBrokerImage: (brokerUid: string, cacheToken: string, query?: { transformOps?: string[] }, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetBrokerImageAsync/${brokerUid}/${cacheToken}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Returns the link to specified contact and estate using supplied source. Both the contact and estate needs to reside in the same source.
     *
     * @tags Public.IApplication
     * @name GetContactEstateLink
     * @request GET:/Services/Public/IApplication/GetContactEstateLinkAsync/{source}/{contactUid}/{view}/{estateUid}
     * @response `200` `string` The response type
     */
    getContactEstateLink: (source: string, contactUid: string, view: string, estateUid: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetContactEstateLinkAsync/${source}/${contactUid}/${view}/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the view for supplied estate and contact in the source backend.
     *
     * @tags Public.IApplication
     * @name GetContactEstateView
     * @request GET:/Services/Public/IApplication/GetContactEstateViewAsync/{source}/{contactId}/{view}/{estateId}
     * @response `200` `File` The response type
     */
    getContactEstateView: (
      source: string,
      contactId: string,
      view: string,
      estateId: string,
      query?: { access_token?: string; frontAddress?: string },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetContactEstateViewAsync/${source}/${contactId}/${view}/${estateId}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Returns a description of supplied error..
     *
     * @tags Public.IApplication
     * @name GetErrorInfo
     * @request GET:/Services/Public/IApplication/GetErrorInfoAsync/{estateUid}/{errorCode}
     * @response `200` `ErrorInfo` Contains information about an error
     */
    getErrorInfo: (estateUid: string, errorCode: number, params: RequestParams = {}) =>
      this.request<ErrorInfo, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetErrorInfoAsync/${estateUid}/${errorCode}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the estate image
     *
     * @tags Public.IApplication
     * @name GetEstateImage
     * @request GET:/Services/Public/IApplication/GetEstateImageAsync/{estateUid}/{imageUid}/{cacheToken}
     * @response `200` `File` The response type
     */
    getEstateImage: (
      estateUid: string,
      imageUid: string,
      cacheToken: string,
      query?: { transformOps?: string[]; downloadUid?: string },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetEstateImageAsync/${estateUid}/${imageUid}/${cacheToken}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Returns the link to specified estate using supplied source. The estate needs to have an external id matching supplied source.
     *
     * @tags Public.IApplication
     * @name GetEstateLink
     * @request GET:/Services/Public/IApplication/GetEstateLinkAsync/{source}/{view}/{estateUid}
     * @response `200` `string` The response type
     */
    getEstateLink: (source: string, view: string, estateUid: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetEstateLinkAsync/${source}/${view}/${estateUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the index html page
     *
     * @tags Public.IApplication
     * @name GetIndexHtml
     * @request GET:/Services/Public/IApplication/GetIndexHtmlAsync
     * @response `200` `File` The response type
     */
    getIndexHtml: (params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetIndexHtmlAsync`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns a link to the broker image
     *
     * @tags Public.IApplication
     * @name GetOriginalBrokerImage
     * @request GET:/Services/Public/IApplication/GetOriginalBrokerImageAsync/{brokerUid}/{cacheToken}
     * @response `200` `File` The response type
     */
    getOriginalBrokerImage: (brokerUid: string, cacheToken: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetOriginalBrokerImageAsync/${brokerUid}/${cacheToken}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns the estate image
     *
     * @tags Public.IApplication
     * @name GetOriginalEstateImage
     * @request GET:/Services/Public/IApplication/GetOriginalEstateImageAsync/{estateUid}/{imageUid}/{cacheToken}
     * @response `200` `File` The response type
     */
    getOriginalEstateImage: (estateUid: string, imageUid: string, cacheToken: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IApplication/GetOriginalEstateImageAsync/${estateUid}/${imageUid}/${cacheToken}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns all the steps
     *
     * @tags Public.IApplication
     * @name GetSteps
     * @request GET:/Services/Public/IApplication/GetSteps
     * @response `200` `(EstateStep)[]` The response type
     */
    getSteps: (params: RequestParams = {}) =>
      this.request<EstateStep[], any>({
        path: `/RA/Customer/Services/Public/IApplication/GetSteps`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Starts a new download. Returns a guid if download can be done.
     *
     * @tags Public.IApplication
     * @name StartNewDownload
     * @request GET:/Services/Public/IApplication/StartNewDownloadAsync/{dowloadsUid}
     * @response `200` `string` The response type
     */
    startNewDownload: (dowloadsUid: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IApplication/StartNewDownloadAsync/${dowloadsUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Starts a new download session
     *
     * @tags Public.IApplication
     * @name StartNewDownloads
     * @request GET:/Services/Public/IApplication/StartNewDownloadsAsync
     * @response `200` `string` The response type
     */
    startNewDownloads: (query?: { maxNumberOfDownloads?: number }, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IApplication/StartNewDownloadsAsync`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new Oidc scope.
     *
     * @tags Public.IBankId
     * @name CreateOidcScope
     * @request GET:/Services/Public/IBankId/CreateOidcScopeAsync
     * @response `200` `string` The response type
     */
    createOidcScope: (query?: { redirectUri?: string; userAgent?: string }, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/CreateOidcScopeAsync`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the QR code for supplied auto start token.
     *
     * @tags Public.IBankId
     * @name CreateQrCode
     * @request GET:/Services/Public/IBankId/CreateQrCodeAsync/{autoStartToken}
     * @response `200` `File` The response type
     */
    createQrCode: (autoStartToken: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/RA/Customer/Services/Public/IBankId/CreateQrCodeAsync/${autoStartToken}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns the QR code for supplied auto start token.
     *
     * @tags Public.IBankId
     * @name GetAuthStatus
     * @request GET:/Services/Public/IBankId/GetAuthStatusAsync/{oidcScopeId}
     * @response `200` `BankIdAuthStatus` The authorization progress
     */
    getAuthStatus: (oidcScopeId: string, params: RequestParams = {}) =>
      this.request<BankIdAuthStatus, any>({
        path: `/RA/Customer/Services/Public/IBankId/GetAuthStatusAsync/${oidcScopeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the client ip.
     *
     * @tags Public.IBankId
     * @name GetClientIp
     * @request GET:/Services/Public/IBankId/GetClientIpAsync
     * @response `200` `string` The response type
     */
    getClientIp: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/GetClientIpAsync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the jwt token for supplied oidc scope
     *
     * @tags Public.IBankId
     * @name GetJwtToken
     * @request GET:/Services/Public/IBankId/GetJwtTokenAsync/{oidcScopeId}
     * @response `200` `string` The response type
     */
    getJwtToken: (oidcScopeId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/GetJwtTokenAsync/${oidcScopeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the redirect to perform when the user has been logged in.
     *
     * @tags Public.IBankId
     * @name GetRedirect
     * @request GET:/Services/Public/IBankId/GetRedirectAsync/{oidcScopeId}
     * @response `200` `string` The response type
     */
    getRedirect: (oidcScopeId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/GetRedirectAsync/${oidcScopeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Refreshes the supplied jwt token
     *
     * @tags Public.IBankId
     * @name RefreshToken
     * @request GET:/Services/Public/IBankId/RefreshTokenAsync/{jwt}
     * @response `200` `string` The response type
     */
    refreshToken: (jwt: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/RefreshTokenAsync/${jwt}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Starts a new login using the supplied oidc scope.
     *
     * @tags Public.IBankId
     * @name StartLocalLogin
     * @request GET:/Services/Public/IBankId/StartLocalLoginAsync/{oidcScopeId}
     * @response `200` `string` The response type
     */
    startLocalLogin: (oidcScopeId: string, query?: { clientIp?: string }, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/RA/Customer/Services/Public/IBankId/StartLocalLoginAsync/${oidcScopeId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Starts a new login using the supplied oidc scope.
     *
     * @tags Public.IBankId
     * @name StartLogin
     * @request GET:/Services/Public/IBankId/StartLoginAsync/{oidcScopeId}
     * @response `200` `BankIdAuthStatus` The authorization progress
     */
    startLogin: (oidcScopeId: string, query?: { clientIp?: string }, params: RequestParams = {}) =>
      this.request<BankIdAuthStatus, any>({
        path: `/RA/Customer/Services/Public/IBankId/StartLoginAsync/${oidcScopeId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
}
