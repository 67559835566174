export default (function supportsLocalStorage(): boolean {
  try {
    const supportsLocalStorage = 'localStorage' in window && window['localStorage'] !== null && JSON && typeof JSON.parse === 'function';

    if (supportsLocalStorage === false) {
      return false;
    }

    // The below lines will throw when run in private mode in Safari...
    localStorage.setItem('safariPrivateMode', '');
    localStorage.removeItem('safariPrivateMode');

    return true;
  } catch (e) {
    return false;
  }
})();
