import List from '../List/List';
import ChecklistLink from '../ChecklistLink/ChecklistLink';
import { ChecklistChecklistSummary } from '../../services/generated-api-client';

interface Props {
  checklists: ChecklistChecklistSummary[];
  title: string;
}

export default function ChecklistList({ checklists, title }: Props): JSX.Element {
  return (
    <List title={title}>
      {checklists &&
        checklists.map(
          (checklist) =>
            checklist.Name && (
              <ChecklistLink
                key={checklist.Uid}
                name={checklist.Name}
                items={checklist.NumberOfItems!}
                completedItems={checklist.CompletedItems ?? 0}
                checklistId={checklist.Uid!}
              />
            ),
        )}
    </List>
  );
}
