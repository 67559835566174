import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from './checkmark.svg';
import { formatSwedishDate } from '../../utils/locale-utils';

interface Props {
  type: 'deposit' | 'payout' | 'membership';
  date?: string;
  completed: boolean;
}

export default function DownPaymentStatusItem({ type, date, completed }: Props): JSX.Element {
  const { t } = useTranslation('down-payment');

  return (
    <div className="down-payment-status-item">
      <div>
        <span className="title">{t(`status-types.${type}`)}</span>
        {date && type === 'deposit' && !completed && (
          <span className="date">
            {t('date-text')} {formatSwedishDate(date)}
          </span>
        )}
      </div>
      <span
        className={`checkmark ${completed ? 'checked' : ''}`}
        aria-label={completed ? t('status-item-completed') : t('status-item-not-completed')}
      >
        <CheckmarkIcon className="checkmark-icon" />
      </span>
    </div>
  );
}
