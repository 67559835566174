import { EffectCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BuySellBuySellEstate } from '../../services/generated-api-client';
import ProcessPage from './ProcessPage';
import ChecklistsPage from '../ChecklistsPage/ChecklistsPage';
import BottomNavBar from '../../components/BottomNavBar/BottomNavBar';
import EstateInfo from '../../components/EstateInfo/EstateInfo';
import Checklist from '../Checklist/Checklist';
import DocumentsPage from '../DocumentsPage/DocumentsPage';
import OffersPage from '../OffersPage/OffersPage';
import ErrorPage from '../Error/ErrorPage';
import ApiClient from '../../services/api-client';
import LoaderContainer, { LineLoader } from '../../components/Loader';
import AddChecklistItemPage from '../Checklist/AddChecklistItemPage';
import Views from '../../constants/views';
import { subscribeToTopic } from '../../utils/message-hub';
import Form from '../Form/Form';

export default function EstatePage(): JSX.Element {
  const { t } = useTranslation('estate-page');
  const { view, estateId } = useParams();
  const [estate, setEstate] = useState<BuySellBuySellEstate>();
  const [loading, setLoading] = useState<boolean>(true);
  const [fullscreenPageOpen, setFullscreenPageOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect((): ReturnType<EffectCallback> => {
    if (view?.toUpperCase() !== Views.Sell.toUpperCase() && view?.toUpperCase() !== Views.Buy.toUpperCase()) {
      console.error('Wrong view supplied:' + view);
      navigate('/page-not-found');
      return;
    }

    if (!estateId) {
      console.error('No estateId supplied');
      navigate('/page-not-found');
      return;
    }

    ApiClient.authenticated
      .getByEstateId(view, estateId as string, { cancelToken: 'getByEstateId' })
      .then((response) => {
        setEstate(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        navigate('/error');
      });

    return (): void => {
      ApiClient.abortRequest('getByEstateId');
    };
  }, [view, estateId, navigate]);
  useEffect((): (() => void) => {
    const unsubscribeFullscreenPageOpened = subscribeToTopic('fullscreen-page-opened', () => {
      setFullscreenPageOpen(true);
    });
    const unsubscribeFullscreenPageClosed = subscribeToTopic('fullscreen-page-closed', () => {
      setFullscreenPageOpen(false);
    });

    return (): void => {
      unsubscribeFullscreenPageOpened();
      unsubscribeFullscreenPageClosed();
    };
  }, []);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <div className="loading-line">
            <LineLoader />
          </div>
        </>
      }
    >
      <div className="estate-page" data-testid="estate-page">
        {estate && estate.Uid ? (
          <>
            {!fullscreenPageOpen && (
              <EstateInfo
                estateAddress={estate.Address?.StreetAddress}
                estateArea={estate.Address?.Area}
                estateCity={estate.Address?.City}
                estatePrice={estate.PriceInfo?.Price}
                priceText={estate.PriceInfo?.PriceStatus}
              />
            )}
            <Routes>
              <Route index element={<Navigate to={`process/${estate.CurrentStep?.toLowerCase() ?? ''}`} replace={true} />} />
              <Route path="process" element={<Navigate to={estate.CurrentStep?.toLowerCase() ?? ''} replace={true} />} />
              <Route path="process/*" element={<ProcessPage currentStep={estate.CurrentStep} />} />
              <Route path="form/:formId" element={<Form />} />
              <Route path="checklists/:checklistId" element={<Checklist />} />
              <Route path="checklists/:checklistId/add-item" element={<AddChecklistItemPage />} />
              <Route path="checklists" element={<ChecklistsPage />} />
              <Route path="documents" element={<DocumentsPage />} />
              <Route path="offers" element={<OffersPage />} />
            </Routes>
          </>
        ) : (
          <ErrorPage text={t('missing-estate')} />
        )}
        {!fullscreenPageOpen && <BottomNavBar />}
      </div>
    </LoaderContainer>
  );
}
