import { ReactNode } from 'react';

interface Props {
  loading: boolean;
  children: ReactNode;
  loaders: ReactNode;
}

export default function LoaderContainer({ loading, children, loaders }: Props): JSX.Element {
  if (loading) {
    return <>{loaders}</>;
  }

  return <>{children}</>;
}

export const HeaderLoader = function HeaderLoader(): JSX.Element {
  return (
    <div className="ph-item header-loader">
      <div className="ph-col-12">
        <div className="ph-row">
          <div className="ph-col-4 big"></div>
          <div className="ph-col-6 big empty"></div>
          <div className="ph-col-2 big"></div>
        </div>
      </div>
    </div>
  );
};

export const LineLoader = function LineLoader(): JSX.Element {
  return (
    <div className="ph-item">
      <div className="ph-col-12">
        <div className="ph-row">
          <div className="ph-col-12 big"></div>
        </div>
      </div>
    </div>
  );
};

export const ListLoader = function ListLoader(): JSX.Element {
  return (
    <div className="ph-item">
      <div className="ph-col-12">
        <div className="ph-row">
          <div className="ph-col-12 big"></div>
          <div className="ph-col-12 big"></div>
          <div className="ph-col-12 big"></div>
        </div>
      </div>
    </div>
  );
};

export const ImageBoxLoader = function ImageBoxLoader(): JSX.Element {
  return (
    <div className="ph-item">
      <div className="ph-col-12">
        <div className="ph-picture"></div>
      </div>
    </div>
  );
};

export const BrokerLoader = function BrokerLoader(): JSX.Element {
  return (
    <div className="ph-item">
      <div className="ph-col-2">
        <div className="ph-avatar"></div>
      </div>
      <div>
        <div className="ph-row">
          <div className="ph-col-6"></div>
          <div className="ph-col-6 empty"></div>
          <div className="ph-col-6"></div>
          <div className="ph-col-6 empty"></div>
          <div className="ph-col-6"></div>
          <div className="ph-col-6 empty"></div>
        </div>
      </div>
    </div>
  );
};

export const HomeLoader = function HomeLoader(): JSX.Element {
  return (
    <div className="ph-item home-loader">
      <div className="ph-col-2">
        <div className="ph-picture"></div>
      </div>
      <div className="ph-col-8">
        <div className="ph-row">
          <div className="ph-col-12"></div>
          <div className="ph-col-12"></div>
          <div className="ph-col-12"></div>
        </div>
      </div>
      <div className="ph-col-2">
        <div className="ph-picture"></div>
      </div>
      <div className="ph-col-8">
        <div className="ph-row">
          <div className="ph-col-12"></div>
          <div className="ph-col-12"></div>
          <div className="ph-col-12"></div>
        </div>
      </div>
    </div>
  );
};
