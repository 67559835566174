//import ApplicationOptions from './../../utils/options';
interface Props {
  title: string | undefined;
  description: string | undefined;
  step: string | undefined;
}

export default function ProcessDescription({ title, description, step }: Props): JSX.Element {
  return (
    <>
      <div className="step-description-container">
        <img className={'step-description-img-' + step} alt="StepDescriptionImg" />
        <div className="step-description" data-testid="step-description">
          <span className="title"> {title} </span>
          <p className="description"> {description} </p>
        </div>
        <br />
      </div>
    </>
  );
}
