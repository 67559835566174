import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  LineControllerChartOptions,
} from 'chart.js';
import { BuySellBid } from '../../services/generated-api-client';
import { formatSwedishDate, swedishCurrencyFormatter } from '../../utils/locale-utils';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { bidsWithUser, getChartColors, userFirst } from '../../utils/chart-utils';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Bidder {
  name: string;
  color: string;
}

interface BidHistoryChart {
  getChartData: (bids: BuySellBid[], startingPrice: number) => ChartData<'line', number[], unknown>;
  getBidders: (bids: BuySellBid[]) => Bidder[];
  getOptions: (
    bids: BuySellBid[],
  ) => _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  >;
}

export default function useBidHistoryChart(): BidHistoryChart {
  const { t } = useTranslation('bid-history');
  const userColor = getComputedStyle(document.body).getPropertyValue(`--chart-data-user-color`);

  const getFormattedDate = (date: string): string | null => {
    const formattedDate = formatSwedishDate(date, 'compact');
    const formattedTime = formatSwedishDate(date, 'time');
    if (!formattedDate || !formattedTime) return null;

    return `${formattedDate} ${formattedTime}`;
  };

  const getBidders = (bids: BuySellBid[]): Bidder[] => {
    const colors = getChartColors();

    const uniqueBidderNames = userFirst(bids)
      .map((bid) => bid.BidderName)
      .filter((value, index, bidders) => bidders.indexOf(value) === index);

    const bidders = uniqueBidderNames.map((bidderName, i) => {
      if (bidderName === t('user')) {
        return {
          name: bidderName ?? '',
          color: userColor,
        };
      }
      return {
        name: bidderName ?? '',
        color: colors[i % colors.length],
      };
    });

    return bidders;
  };

  const getChartData = (bids: BuySellBid[], startingPrice: number): ChartData<'line', number[], unknown> => {
    const bidsByDate = [...bids].filter((o) => o.Date).sort((a, b) => Date.parse(a.Date!) - Date.parse(b.Date!));

    const startingPriceData = {
      label: 'Utgångspris',
      color: getComputedStyle(document.body).getPropertyValue('--bid-history-starting-price-color'),
    };

    const bidAmounts = [startingPrice, ...bidsByDate.map((bid) => bid.Amount!)];

    const labels = [startingPriceData.label, ...bidsByDate.map((bid) => bid.Date && getFormattedDate(bid.Date))];

    const pointBackgroundColors = [
      startingPriceData.color,
      ...bidsWithUser(bidsByDate, t).map((bid) => getBidders(bidsByDate).filter((bidder) => bidder.name === bid.BidderName)[0].color),
    ];
    const chartData = {
      labels: labels,
      datasets: [
        {
          data: bidAmounts,
          pointBackgroundColor: pointBackgroundColors,
        },
      ],
    };

    return chartData;
  };

  const getOptions = (
    bids: BuySellBid[],
  ): _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  > => {
    const style = getComputedStyle(document.body);
    const font = {
      family: style.getPropertyValue('--body-font'),
      size: 12,
      weight: '300',
    };
    return {
      locale: 'sv-SE',
      elements: {
        line: {
          borderColor: style.getPropertyValue('--bid-history-line-color'),
          borderWidth: 1,
        },
        point: {
          radius: Number(style.getPropertyValue('--point-radius')),
          borderWidth: 0,
          borderColor: style.getPropertyValue('--point-border-color'),
          hitRadius: 10,
          hoverRadius: Number(style.getPropertyValue('--point-radius')) + 0.5,
          hoverBorderWidth: 1,
        },
      },
      scales: {
        xAxes: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: style.getPropertyValue('--chart-label-color'),
          },
        },
        yAxes: {
          grid: {
            borderDash: [5, 5],
            drawBorder: false,
            color: style.getPropertyValue('--chart-grid-line-color'),
          },
          ticks: {
            color: style.getPropertyValue('--chart-label-color'),
            callback: function (value, index, ticks): string {
              return swedishCurrencyFormatter.format(Number(value));
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function (context): string {
              const bidDate = bids.filter((bid) => bid.Amount === context[0].parsed.y)[0].Date ?? '';
              const formattedDate = formatSwedishDate(bidDate);
              const formattedTime = formatSwedishDate(bidDate, 'time');
              return `${formattedDate} ${formattedTime}`;
            },
            label: function (context): string {
              const bidAmount = swedishCurrencyFormatter.format(context.parsed.y);
              return `${bidAmount}`;
            },
            footer: function (context): string {
              return bids.filter((bid) => bid.Amount === context[0].parsed.y)[0].BidderName ?? '';
            },
          },
          boxPadding: 4,
          boxWidth: Number(style.getPropertyValue('--chart-legend-color-box-size')),
          boxHeight: Number(style.getPropertyValue('--chart-legend-color-box-size')),
          backgroundColor: style.getPropertyValue('--chart-tooltip-bg-color'),
          borderWidth: 1,
          borderColor: style.getPropertyValue('--chart-tooltip-border-color'),
          titleColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
          bodyColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
          footerColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
          titleFont: font,
          bodyFont: { ...font, size: 14 },
          footerFont: font,
          padding: 8,
        },
      },
    };
  };

  return { getChartData, getBidders, getOptions };
}
