import { useTranslation } from 'react-i18next';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import { BuySellDownPaymentAdjustment } from '../../services/generated-api-client';

interface Props {
  downPayment: number;
  brokerCommission: number;
  brokerAdjustments: BuySellDownPaymentAdjustment[];
  amountBack: number;
  isSeller: boolean;
}

export default function DownPaymentAmount({ downPayment, brokerCommission, brokerAdjustments, amountBack, isSeller }: Props): JSX.Element {
  const { t } = useTranslation('down-payment-amount');

  return (
    <div className="down-payment-amount" data-testid="down-payment-amount">
      <div className="to-pay">
        <div>
          {' '}
          {t('to-pay')}
          <div className="payment-to"> {t('payment-to')} </div>
        </div>
        <span data-testid="down-payment"> {swedishCurrencyFormatter.format(downPayment)} </span>
      </div>
      {isSeller && (
        <>
          <div className="broker-commission" data-testid="broker-commission">
            <span> {t('broker-commission')} </span>
            <span data-testid="broker-amount"> {swedishCurrencyFormatter.format(brokerCommission)} </span>
          </div>
          {brokerAdjustments.map((item, idx) => (
            <div key={idx} className="broker-commission" data-testid="broker-commission-adjustment">
              <span> {item.Description} </span>
              <span data-testid="broker-amount"> {swedishCurrencyFormatter.format(item.Amount!)} </span>
            </div>
          ))}
        </>
      )}
      <hr className="divider" />
      <div className="amount-back">
        <div>
          {t('amount-back')}
          <div className="paid-back-to"> {t('paid-back-to')} </div>
        </div>
        <span className="amount-returned" data-testid="amount-back">
          {' '}
          {swedishCurrencyFormatter.format(amountBack)}{' '}
        </span>
      </div>
    </div>
  );
}
