import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChecklistList from '../../components/Lists/ChecklistList';
import StepDescription from '../../components/StepDescription/StepDescription';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import ApiClient from '../../services/api-client';
import { BuySellSellSellViewingBiddingView } from '../../services/generated-api-client';
import BidHistory from '../../components/BidHistory/BidHistory';
import ErrorPage from '../Error/ErrorPage';
import NumberInfoContainer from '../../components/NumberInfo/NumberInfoContainer';
import NumberInfo from '../../components/NumberInfo/NumberInfo';
import ViewingList from '../../components/Lists/ViewingList';
import DocumentList from '../../components/Lists/DocumentList';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import FormList from '../../components/Lists/FormList';

export default function SellViewingBiddingPage(): JSX.Element {
  const { estateId } = useParams();
  const { t } = useTranslation('viewing-bidding-page');
  const { t: tProcess } = useTranslation('process');
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<BuySellSellSellViewingBiddingView>();
  const step = 'ViewingBidding';

  useEffect(() => {
    ApiClient.authenticated
      .getSellViewingBiddingView(estateId!, {
        cancelToken: 'getSellViewingBiddingView',
      })
      .then((response) => {
        setContent(response.data);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getSellViewingBiddingView');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.ViewingBidding')}</h1>

          <div className="process-content">
            {content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            <div className="grouped">
              {content.PriceInfo && <BidHistory bids={content.Bids ?? []} price={content.PriceInfo} />}

              {content.ViewingBiddingInfo && (
                <NumberInfoContainer>
                  {content.ViewingBiddingInfo.TotalNumberOfBookedViewings !== null && (
                    <NumberInfo
                      title={t('bookings-title')}
                      description={t('bookings-description')}
                      number={content.ViewingBiddingInfo.TotalNumberOfBookedViewings!}
                    />
                  )}
                  {content.ViewingBiddingInfo.TotalNumberOfViewingAttendants !== null && (
                    <NumberInfo
                      title={t('attendants-title')}
                      description={t('attendants-description')}
                      number={content.ViewingBiddingInfo.TotalNumberOfViewingAttendants!}
                    />
                  )}
                  <NumberInfo
                    title={t('bidders-title')}
                    description={t('bidders-description')}
                    number={content.ViewingBiddingInfo.TotalNumberOfBidders!}
                  />
                </NumberInfoContainer>
              )}
            </div>

            {!!content.Viewings?.length && <ViewingList title={tProcess('viewing-list-title')} viewings={content.Viewings} />}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}

            {!!content.Forms?.length && <FormList forms={content.Forms} title={tProcess('forms-title')} />}
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <ErrorPage text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
