import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as OpenIcon } from './open.svg';
import { ReactComponent as ProspectIcon } from './prospect.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as WebIcon } from './web.svg';

interface Props {
  name: string;
  linkUrl: string;
  type: string;
}

export default function ExternalListing({ name, linkUrl, type }: Props): JSX.Element {
  function getExternalListingIcon(): JSX.Element | void {
    const upperType = type?.toString().toUpperCase();

    switch (upperType) {
      case 'WEBSITE':
        return <WebIcon role="presentation" data-testid="web-icon" />;
      case 'PROSPECT':
        return <ProspectIcon role="presentation" data-testid="prospect-icon" />;
      case 'FACEBOOK':
        return <FacebookIcon role="presentation" data-testid="facebook-icon" />;
      case 'INSTAGRAM':
        return <InstagramIcon role="presentation" data-testid="instagram-icon" />;
      case 'TWITTER':
        return <TwitterIcon role="presentation" data-testid="twitter-icon" />;
      default:
        return <WebIcon role="presentation" data-testid="default-icon" />;
    }
  }

  return (
    <a href={linkUrl} rel="noreferrer" className="external-listing" data-testid="external-listing" target="_blank">
      <div className="link-info">
        {getExternalListingIcon()}
        <span className="name"> {name} </span>
      </div>
      <OpenIcon className="open-icon" />
    </a>
  );
}
