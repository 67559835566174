import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BuySellBid, EstatePrice } from '../../services/generated-api-client';
import Expandable from '../Expandable/Expandable';
import PriceInfo from '../Price/PriceInfo';
import BidHistoryList from './BidHistoryList';

const BidHistoryChart = lazy(() => import('./BidHistoryChart'));

interface Props {
  bids: BuySellBid[];
  price: EstatePrice;
}

export default function BidHistory({ bids, price }: Props): JSX.Element {
  const { t } = useTranslation('bid-history');

  return (
    <div className="bid-history" data-testid="bid-history">
      <PriceInfo price={price} />
      <Suspense fallback={<></>}>
        <BidHistoryChart bids={bids} startingPrice={price.StartingPrice!} />
      </Suspense>
      <Expandable id="expandable-bid-history-list" title={t('list-title')} expandedTitle={t('list-title-expanded')}>
        <BidHistoryList bids={bids} startingPrice={price.StartingPrice!} />
      </Expandable>
    </div>
  );
}
