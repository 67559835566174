import i18n, { BackendModule, ReadCallback } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

i18n
  .use({
    type: 'backend',
    read(language: string, namespace: string, callback: ReadCallback) {
      import(/* webpackMode: "eager" */ `../public/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  } as BackendModule)
  .use(ICU)
  .use(initReactI18next)
  .init({
    fallbackLng: 'sv',
    debug: false, // Enable this if there is a need to debug i18n stuff for main development

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
