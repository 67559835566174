import { useParams } from 'react-router-dom';
import SellViewingBiddingPage from '../../pages/SellSteps/SellViewingBiddingPage';
import SellContractPage from '../../pages/SellSteps/SellContractPage';
import SellEntryPage from '../../pages/SellSteps/SellEntryPage';
import SellAfterEntryPage from '../../pages/SellSteps/SellAfterEntryPage';
import BuyContractPage from '../../pages/BuySteps/BuyContractPage';
import BuyEntryPage from '../../pages/BuySteps/BuyEntryPage';
import BuyAfterEntryPage from '../../pages/BuySteps/BuyAfterEntryPage';
import BuyEstateDataPage from '../BuySteps/BuyEstateDataPage';
import SellEstateDataPage from '../SellSteps/SellEstateDataPage';
import SellPreparationsPage from '../SellSteps/SellPreparationsPage';
import SellMarketingPage from '../SellSteps/SellMarketingPage';
import SellDownPaymentPage from '../SellSteps/SellDownPaymentPage';
import BuyDownPaymentPage from '../BuySteps/BuyDownPaymentPage';
import Steps from '../../constants/steps';
import Views from '../../constants/views';

function ProcessStep(): JSX.Element {
  const { view, step } = useParams();

  const getSellPage = (): JSX.Element | null => {
    switch (step?.toUpperCase()) {
      case Steps.Preparations.toUpperCase():
        return <SellPreparationsPage />;

      case Steps.Marketing.toUpperCase():
        return <SellMarketingPage />;

      case Steps.ViewingBidding.toUpperCase():
        return <SellViewingBiddingPage />;

      case Steps.Contract.toUpperCase():
        return <SellContractPage />;

      case Steps.DownPayment.toUpperCase():
        return <SellDownPaymentPage />;

      case Steps.Entry.toUpperCase():
        return <SellEntryPage />;

      case Steps.AfterEntry.toUpperCase():
        return <SellAfterEntryPage />;

      case Steps.EstateData.toUpperCase():
        return <SellEstateDataPage />;

      default:
        return null;
    }
  };

  const getBuyPage = (): JSX.Element | null => {
    switch (step?.toUpperCase()) {
      case Steps.Contract.toUpperCase():
        return <BuyContractPage />;

      case Steps.DownPayment.toUpperCase():
        return <BuyDownPaymentPage />;

      case Steps.Entry.toUpperCase():
        return <BuyEntryPage />;

      case Steps.AfterEntry.toUpperCase():
        return <BuyAfterEntryPage />;

      case Steps.EstateData.toUpperCase():
        return <BuyEstateDataPage />;

      default:
        return null;
    }
  };

  return (
    <>
      {view?.toUpperCase() === Views.Sell.toUpperCase() && getSellPage()}
      {view?.toUpperCase() === Views.Buy.toUpperCase() && getBuyPage()}
    </>
  );
}

export default ProcessStep;
