import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { BuySellGraphDataset } from '../../../services/generated-api-client';
import ImpressionsContainer from '../ImpressionsContainer';
import useImpressionsChart from './useImpressionsChart';

interface Props {
  datasets: BuySellGraphDataset[];
}

export default function ImpressionsChart({ datasets }: Props): JSX.Element {
  const { chartData, options } = useImpressionsChart(datasets);
  const { t } = useTranslation('marketing');

  const getLabels = (): JSX.Element[] => {
    const sortedDatasets = chartData.datasets.sort((a, b) => b.order! - a.order!);
    return sortedDatasets.map((dataset) => (
      <span key={dataset.label} className="label">
        <span className="color" style={{ backgroundColor: dataset.backgroundColor as string }}></span>
        <span className="title">{dataset.label}</span>
      </span>
    ));
  };

  return (
    <ImpressionsContainer title={t('impressions')} className="impressions-chart">
      <div className="labels">{getLabels()}</div>
      <div className="chart">
        <Line data={chartData} options={options} />
      </div>
    </ImpressionsContainer>
  );
}
