import { useTranslation } from 'react-i18next';
import windowId from '../../utils/window-id';

interface Props {
  image: string | undefined;
  title: string | undefined;
  description: string | undefined;
  linkText: string | undefined;
  linkUrl: string | undefined;
  textOnTopOfImage?: boolean;
}

export default function Tip({ image, title, description, linkText, linkUrl, textOnTopOfImage }: Props): JSX.Element {
  const { t } = useTranslation('tip');

  return (
    <div className={`tip ${!!textOnTopOfImage ? 'text-on-top' : ''}`} data-testid="tip">
      {image && <img src={image} role="presentation" alt="" />}
      <div className="tip-info">
        {title && <span className="title text"> {title} </span>}
        {description && <p className="description text"> {description} </p>}
        {linkText && linkUrl && (
          <a href={linkUrl} target={windowId + '_ads'} data-testid="link" rel="noopener" aria-label={t('link-label', { title: title })}>
            {' '}
            {linkText}{' '}
          </a>
        )}
      </div>
    </div>
  );
}
