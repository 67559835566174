import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from './arrow.svg';
import { publishMessage } from './../../utils/message-hub';
import { useEffect } from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
  backUrl?: string;
}

export default function FullscreenPage({ title, children, className, backUrl }: Props): JSX.Element {
  const { t } = useTranslation('fullscreen-page');
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => publishMessage('fullscreen-page-opened'), 1); // Petter - Needs to be on timeout for subscription to pick up message
    return () => publishMessage('fullscreen-page-closed');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = (): void => {
    if (backUrl) navigate({ pathname: backUrl });
    else navigate(-1);
  };

  return (
    <div className={`${className} fullscreen-page`} data-testid="fullscreen-page">
      <header>
        <button aria-label={t('go-back')} onClick={goBack}>
          <Arrow />
        </button>
        <h1 className="component-heading">{title}</h1>
      </header>
      <main>{children}</main>
    </div>
  );
}
