import { useState } from 'react';
import { ReactComponent as Checkmark } from './checkmark.svg';

interface Props {
  id: string;
  title: string | undefined;
  description: string | undefined;
  link?: string | undefined;
  completed: boolean | undefined;
  updateItemStatusCallback: (itemId: string, status: boolean) => void;
}

export default function ChecklistItem({ id, title, description, link, completed, updateItemStatusCallback }: Props): JSX.Element {
  const [checked, setChecked] = useState<boolean>(completed ?? false);
  const [focused, setFocused] = useState<boolean>(false);

  const updateItemStatus = (): void => {
    const status = !checked;
    updateItemStatusCallback(id, status);
    setChecked(status);
  };

  return (
    <label className="checklist-item">
      <div>
        <input
          type="checkbox"
          name="checklist-item"
          checked={checked}
          onChange={(): void => updateItemStatus()}
          onFocus={(): void => setFocused(true)}
          onBlur={(): void => setFocused(false)}
        />
        <span className={`checkbox ${checked ? 'checked' : ''} ${focused ? 'focused' : ''}`} aria-hidden="true">
          <Checkmark className="checkmark" />
        </span>
      </div>
      <div>
        {title && <span className="title">{title}</span>}
        {description && (
          <>
            {link && (
              <a href={link} target="new">
                {description && <span className="description">{description}</span>}
              </a>
            )}
            {!link && <span className="description">{description}</span>}
          </>
        )}
      </div>
    </label>
  );
}
