import { useEffect, useState } from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import ApiClient from '../../services/api-client';
import { BuySellBroker, OnboardingOnboardingView } from '../../services/generated-api-client';

import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import Broker from '../../components/Broker/Broker';
import TipList from '../../components/Lists/TipList';
import securityService from '../../services/security-service';
import Spinner from '../../components/Spinner/Spinner';

function matchingSSNs(
  navigate: NavigateFunction,
  source: string,
  contactId: string,
  estateId: string,
  view: string,
  estateUid: string,
): void {
  ApiClient.authenticated
    .correctUserLoggedIn(source, contactId, estateId)
    .then((r) => {
      navigate('/' + view + '/' + estateUid);
    })
    .catch((e) => {
      navigate('/error');
    });
}

export default function OnboardingPage(): JSX.Element {
  const { source, contactId, view, estateId } = useParams();
  const { t: tProcess } = useTranslation('process');
  const { t: tOnboarding } = useTranslation('onboarding');
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [needSsn, setNeedSsn] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [content, setContent] = useState<OnboardingOnboardingView>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!source || !contactId || !view || !estateId) {
      navigate('/error');
      return;
    }

    ApiClient.authenticated
      .getOnboardingView(source, contactId, estateId, {
        cancelToken: 'getContactStatus',
      })
      .then((response) => {
        setContent(response.data);

        switch (response.data.Contact!.Status) {
          case 'Match':
            matchingSSNs(navigate, source, contactId, estateId, view, response.data.EstateUid!);
            return;
          case 'NeedSsn':
            setNeedSsn(true);
            break;
          default:
            setError(true);
            break;
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        navigate('/error');
      });

    return (): void => {
      ApiClient.abortRequest('getContactStatus');
    };
  }, [view, navigate, source, contactId, estateId]);

  const updateSsn = (): void => {
    setSubmitting(true);
    ApiClient.authenticated
      .setContactSsn(source!, contactId!)
      .then((response) => {
        if (response.data.Status === 'Match') {
          matchingSSNs(navigate, source!, contactId!, estateId!, view!, content.EstateUid!);
        } else {
          setNeedSsn(false);
          setError(true);
        }
      })
      .catch((e) => {
        navigate('/error');
      });
  };

  const getBroker = (broker: BuySellBroker): JSX.Element => {
    return <Broker name={broker.Name} title={broker.Title} phoneNumber={broker.Phone} email={broker.Mail} image={broker.ImageUrl} />;
  };

  const logoutClick = (): void => {
    securityService.clearStoredAccessToken();
    navigate('/login');
  };

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      <div className="onboarding-page">
        <div className="onboarding-content-container">
          <div className="onboarding-content">
            {needSsn && (
              <div className="grouped">
                <h1>{tOnboarding('need-ssn.header')}</h1>
                <span>
                  <Trans i18nKey="need-ssn.text" t={tOnboarding} values={{ broker: content.Broker }} />
                </span>
                <span>
                  {content.Contact!.FirstName} {content.Contact!.LastName}
                  <br />
                  {content.Contact!.Ssn}
                </span>
                <div className="buttons">
                  <button className="yes" onClick={updateSsn} disabled={submitting}>
                    <Spinner enabled={submitting} />
                    {tOnboarding('need-ssn.yes')}
                  </button>
                  <button className="yes" onClick={logoutClick} disabled={submitting}>
                    {tOnboarding('need-ssn.no')}
                  </button>
                </div>
              </div>
            )}
            {error && (
              <div className="grouped">
                <span>
                  <Trans i18nKey="error" t={tOnboarding} values={{ broker: content.Broker }} />
                </span>
              </div>
            )}
          </div>
          <aside>
            {!!content.Broker && getBroker(content.Broker)}

            {!!content.Tips?.filter((tip) => tip.Type === 'Offer').length && (
              <TipList tips={content.Tips.filter((tip) => tip.Type === 'Offer')} textOnTopOfImage />
            )}

            {!!content.Tips?.filter((tip) => tip.Type === 'Tip').length && (
              <TipList tips={content.Tips.filter((tip) => tip.Type === 'Tip')} title={tProcess('tip-title')} />
            )}
          </aside>
        </div>
      </div>
    </LoaderContainer>
  );
}
