import { NavLink } from 'react-router-dom';
import { ReactComponent as ProcessIcon } from './process.svg';
import { ReactComponent as ChecklistIcon } from './checklists.svg';
import { ReactComponent as OffersIcon } from './offers.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { useTranslation } from 'react-i18next';

export default function BottomNavBar(): JSX.Element {
  const { t } = useTranslation('bottom-nav-bar');

  return (
    <nav className="bottom-nav-bar" data-testid="bottom-nav-bar">
      <div>
        <NavLink to="process" data-testid="process-link" title={t('process-link-text')}>
          <ProcessIcon aria-label={t('process-link-text')} />
        </NavLink>
        <NavLink to="checklists" data-testid="checklists-link" title={t('checklist-link-text')}>
          <ChecklistIcon aria-label={t('checklist-link-text')} />
        </NavLink>
        <NavLink to="documents" data-testid="documents-link" title={t('documents-link-text')}>
          <DocumentsIcon aria-label={t('documents-link-text')} />
        </NavLink>
        <NavLink to="offers" data-testid="offers-link" title={t('offers-link-text')}>
          <OffersIcon aria-label={t('offers-link-text')} />
        </NavLink>
      </div>
    </nav>
  );
}
