import React, { FunctionComponent } from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { withJsonFormsLayoutProps, useJsonForms } from '@jsonforms/react';
import { isEmpty } from 'lodash';

export const formMspxSumFieldsTester: RankedTester = rankWith(10, uiTypeIs('Mspx_SumFields'));

export const FormMspxSumFieldsRenderer = (props: LayoutProps & VanillaRendererProps): JSX.Element => {
  const { data: _data, ...otherProps } = props;
  // We don't hand over data to the layout renderer to avoid rerendering it with every data change
  return <MspxMspxSumFields {...otherProps} />;
};

interface UISchemaElementLocal {
  scope: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getElementSum(scope: string, data: any): number {
  if (Array.isArray(data)) {
    let arrSum = 0;
    data.forEach((o) => (arrSum += getElementSum(scope, o)));
    return arrSum;
  }
  if (scope.startsWith('#')) {
    return getElementSum(scope.substring(1), data);
  }
  if (scope.startsWith('/properties/')) {
    let endIdx = scope.indexOf('/', 13);
    if (endIdx === -1) {
      endIdx = scope.length;
    }
    const propertyName = scope.substring(12, endIdx);
    if (propertyName in data) {
      return getElementSum(scope.substring(endIdx), data[propertyName]);
    }
    return 0;
  }
  if (data === null || data === undefined) {
    return 0;
  }
  if (typeof data === 'number') {
    return data;
  }
  return 0;
}

const MspxMspxSumFields: FunctionComponent<LayoutProps & VanillaRendererProps> = React.memo(function GroupLayoutRendererComponent({
  classNames,
  schema,
  uischema,
  path,
  enabled,
  visible,
  label,
  getStyle,
  getStyleAsClassName,
}: LayoutProps & VanillaRendererProps) {
  const { i18n, core } = useJsonForms();
  const translatedSuffix = i18n!.translate!(uischema?.options?.suffix);

  let sum = 0;
  if ('elements' in uischema) {
    // @ts-expect-error this is safe!
    const uiSchemaElements = uischema['elements'] as UISchemaElementLocal[];
    uiSchemaElements.forEach((o) => {
      sum += getElementSum(o.scope, core?.data);
    });
  }
  return (
    <div className="control">
      <label htmlFor={'total-sum'} className={classNames!.label}>
        {label}
      </label>
      <input
        type="number"
        value={sum}
        className={classNames!.wrapper}
        id="total-sum"
        disabled={true}
        autoFocus={uischema.options && uischema.options.focus}
      />
      {!isEmpty(translatedSuffix) && <div className="suffix">{translatedSuffix}</div>}
    </div>
  );
});

export default withVanillaControlProps(withJsonFormsLayoutProps(FormMspxSumFieldsRenderer));
