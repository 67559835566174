import NumberFormat from 'react-number-format';

interface Props {
  value: string | number;
  label: string;
  name: string;
  onChange: (inputValue: string) => void;
  errorMode?: boolean;
  required?: boolean;
}

export default function TextInput({ value, label, name, onChange, errorMode = false, required = false }: Props): JSX.Element {
  return (
    <div className="text-input-container">
      <label htmlFor={`input-${name}`}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <NumberFormat
        id={`input-${name}`}
        className={errorMode ? 'error' : ''}
        name={name}
        spellCheck={false}
        aria-required={required}
        aria-invalid={errorMode}
        value={value}
        onValueChange={({ value }): void => onChange(value)}
        displayType={'input'}
        thousandSeparator={' '}
        suffix={' kr'}
      />
    </div>
  );
}
