import DownPaymentStatusItem from './DownPaymentStatusItem';

interface Props {
  isHousingCooperative: boolean;
  lastDepositDate: string | undefined;
  lastPayoutDate: string | undefined;
  isDownPaymentDeposited: boolean | undefined;
  isMembershipApproved: boolean | undefined;
  isDownPaymentPaidOut: boolean | undefined;
}

export default function DownPaymentStatus({
  isHousingCooperative,
  lastDepositDate,
  lastPayoutDate,
  isDownPaymentDeposited,
  isMembershipApproved,
  isDownPaymentPaidOut,
}: Props): JSX.Element {
  return (
    <div className="down-payment-status">
      {lastDepositDate != null && isDownPaymentDeposited != null && (
        <DownPaymentStatusItem type="deposit" date={lastDepositDate} completed={isDownPaymentDeposited} />
      )}

      {isHousingCooperative && isMembershipApproved != null && <DownPaymentStatusItem type="membership" completed={isMembershipApproved} />}

      {lastPayoutDate != null && isDownPaymentPaidOut != null && (
        <DownPaymentStatusItem type="payout" date={lastPayoutDate} completed={isDownPaymentPaidOut} />
      )}
    </div>
  );
}
