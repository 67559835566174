//import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  className?: string;
  children: React.ReactNode;
}

export default function ImpressionsContainer({ title, children, className }: Props): JSX.Element {
  return (
    <div className={`impressions-container ${className}`}>
      <div className="title">{title}</div>
      <div className="chart-container">{children}</div>
    </div>
  );
}
