import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from './copy.svg';

interface Props {
  title: string;
  text: string;
}

export default function CopyToClipboard({ title, text }: Props): JSX.Element {
  const { t } = useTranslation('copy-to-clipboard');

  function copyTextToClipboard(): void {
    navigator.clipboard.writeText(text);
  }

  return (
    <div className="copy-to-clipboard" data-testid="copy-to-clipboard">
      <p className="title"> {title} </p>
      <button onClick={copyTextToClipboard} data-testid="button" aria-label={t('copy-text', { text: text })}>
        <span> {text} </span>
        <CopyIcon />
      </button>
    </div>
  );
}
