import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import ApiClient from '../../services/api-client';
import { BuySellBuySellAddress, EstatePrice } from '../../services/generated-api-client';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import Alert from '../Alert/Alert';
import CurrencyInput from '../Form/CurrencyInput';
import PriceInfo from '../Price/PriceInfo';
import Spinner from '../../components/Spinner/Spinner';

interface Props {
  estateAddress: BuySellBuySellAddress | undefined;
  priceInfo: EstatePrice;
  minBidRaiseAmount: number;
  paused: boolean;
}

export default function AddBid({ priceInfo, estateAddress, minBidRaiseAmount, paused }: Props): JSX.Element {
  const { estateId } = useParams();
  const { t } = useTranslation('bidding');
  const [bid, setBid] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [checkBid, setCheckBid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showDoubleCheck, setShowDoubleCheck] = useState(false);

  let lowestPossibleBid = priceInfo.Price! + minBidRaiseAmount;
  let lowestPossibleBidFormatted = swedishCurrencyFormatter.format(lowestPossibleBid);
  const basePrice = priceInfo.StartingPrice!;
  const basePriceFormatted = swedishCurrencyFormatter.format(basePrice);

  if (priceInfo.Price === priceInfo.StartingPrice) {
    lowestPossibleBid = priceInfo.StartingPrice!;
    lowestPossibleBidFormatted = swedishCurrencyFormatter.format(lowestPossibleBid);
  }

  const submitBid = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setSubmitError(false);
    setSubmitting(true);
    ApiClient.authenticated
      .addBid(estateId!, { Amount: bid })
      .then((res) => {
        if (res.data) {
          navigate(0);
        } else {
          setSubmitting(false);
          setSubmitError(true);
        }
      })
      .catch((error) => {
        e.preventDefault();
        console.error(error);
        setSubmitting(false);
        setSubmitError(true);
      });
  };

  const validateForm = (): boolean => {
    if (bid === 0) {
      setError(t('add-bid.errors.required'));
      return false;
    }

    if (bid < basePrice && basePrice === lowestPossibleBid) {
      setError(
        t('add-bid.errors.bid-under-price', {
          lowestPossibleBid: basePriceFormatted,
        }),
      );
      return false;
    } else if (bid < lowestPossibleBid) {
      setError(
        t('add-bid.errors.bid-too-low', {
          lowestPossibleBid: lowestPossibleBidFormatted,
        }),
      );
      return false;
    }
    setError('');
    return true;
  };

  const addBid = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    const formIsValid = validateForm();
    if (formIsValid) {
      setCheckBid(true);
      setShowDoubleCheck(bid >= (priceInfo.Price ?? 0) + 250_000); // If bid is higher than price + 250000, show the double check text
    }
  };

  const bidChanged = (inputValue: string): void => {
    setBid(Number(inputValue));
    setError('');
  };

  return (
    <div className="add-bid">
      {!checkBid && <PriceInfo price={priceInfo} />}

      {!paused && (
        <form onSubmit={submitBid}>
          {submitError && <Alert type="error" text={t('add-bid.errors.submit')} />}
          {!checkBid ? (
            <>
              {error !== '' && <div className="error-summary-text">{error}</div>}
              <CurrencyInput
                label={t('add-bid.label')}
                name="add-bid"
                onChange={(inputValue): void => bidChanged(inputValue)}
                value={bid > 0 ? bid : ''}
                errorMode={error !== ''}
                required
              />
              {lowestPossibleBid !== priceInfo.Price ? (
                <span className="lowest-possible-bid">
                  {t('add-bid.lowest-possible-bid', {
                    lowestPossibleBid: lowestPossibleBidFormatted,
                  })}
                </span>
              ) : (
                <br />
              )}
              <button onClick={addBid}>{t('add-bid.button-text')}</button>
            </>
          ) : (
            <div className="check">
              {showDoubleCheck && <span className="double-check">{t('add-bid.check.very-high-bid')}</span>}
              <span>{t('add-bid.check.text')}</span>
              <span className="bid-amount">{swedishCurrencyFormatter.format(bid)}</span>
              {estateAddress?.StreetAddress && (
                <span>
                  {estateAddress.StreetAddress}
                  {estateAddress.Area && estateAddress.City && `. ${estateAddress.Area}, ${estateAddress.City}.`}
                </span>
              )}
              <div className="buttons">
                <button type="submit" disabled={submitting}>
                  <Spinner enabled={submitting} />
                  {t('add-bid.check.yes')}
                </button>
                <button className="cancel" onClick={(): void => setCheckBid(false)} disabled={submitting}>
                  {t('add-bid.check.no')}
                </button>
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
}
