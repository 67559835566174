import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../services/api-client';
import securityService from '../../services/security-service';

interface Props {
  oidcScopeId: string;
}

export default function LoginOptions({ oidcScopeId }: Props): JSX.Element {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const handleLoginThisDevice = (): void => {
    ApiClient.public
      .startLocalLogin(oidcScopeId)
      .then((response) => {
        document.location = response.data;
      })
      .catch(() => {
        navigate('/error');
      });
  };

  const handleLoginOtherDevice = (): void => {
    navigate('login-remote/' + oidcScopeId);
  };

  const mspecsBidding = (): boolean => {
    //visa budfilm om url innehåller mspecs bud
    if (securityService.navigateToAfterLogin?.includes('Mspecs') && securityService.navigateToAfterLogin?.includes('Bidding')) return true;
    else return false;

    // if(navigateToAfterLogin.includes('Mspecs') && navigateToAfterLogin.includes('Bidding'))
    //   return true;
    // else
    //   return false;
  };

  return (
    <div className="login login-options">
      <h1>{t('login-welcome')}</h1>
      <button onClick={handleLoginThisDevice}>{t('login-this-device-button')}</button>
      <button className="login-other-device-button" onClick={handleLoginOtherDevice}>
        {t('login-other-device-button')}
      </button>
      <div>
        {mspecsBidding() ? (
          <div>
            <br />
            <br />

            <a href="https://boostad.net/budinfo" target="_blank" rel="noreferrer">
              Se film om hur du lägger bud
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
