import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import ApiClient from '../../services/api-client';
import { BiddingLoanCommitmentInfo } from '../../services/generated-api-client';

interface Props {
  loanCommitmentInfo: BiddingLoanCommitmentInfo;
  navigateUrl: string;
}

export default function SPContact({ loanCommitmentInfo, navigateUrl }: Props): JSX.Element {
  const { estateId } = useParams();
  const { t: tBidding } = useTranslation('bidding');
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const handleYesClick = (): void => {
    setSubmitting(true);
    ApiClient.authenticated
      .upsertLoanCommitmentInfo(estateId!, {
        ...loanCommitmentInfo,
        ToBeContacted: true,
      })
      .then(() => navigate(navigateUrl))
      .catch((error) => {
        setSubmitting(false);
        setSubmitError(true);
        console.error(error);
      });
  };

  return (
    <div className="sp-contact">
      <img src="/images/sp-bolan-logotype.png" alt="" />
      {submitError && <Alert type="error" text={tBidding('sp-contact.error')} />}
      <p>{tBidding('sp-contact.description')}</p>
      <span className="question">{tBidding('sp-contact.question')}</span>
      <div className="buttons">
        <button onClick={handleYesClick} disabled={submitting} data-testid="yes-button">
          {tBidding('sp-contact.yes')}
        </button>
        <button onClick={(): void => navigate(navigateUrl)} disabled={submitting}>
          {tBidding('sp-contact.no')}
        </button>
      </div>
    </div>
  );
}
