import { useTranslation } from 'react-i18next';
import BiddingConstants from '../../constants/bidding';
import { Link } from '../../services/generated-api-client';

interface Props {
  status: string;
  moreEstatesLink: Link | undefined;
}

export default function BiddingStatus({ status, moreEstatesLink }: Props): JSX.Element {
  const { t: tBidding } = useTranslation('bidding');

  return (
    <div className="bidding-status">
      <span className="title">{tBidding(`status.${status}`)}</span>
      {status !== BiddingConstants.InProgress && (
        <span className="description">
          {status === BiddingConstants.Halted && tBidding(`status.${BiddingConstants.Halted}-text`)}
          {status === BiddingConstants.NotBiddingParticipant && tBidding(`status.${BiddingConstants.NotBiddingParticipant}-text`)}
          {status === BiddingConstants.Paused && tBidding(`status.${BiddingConstants.Paused}-text`)}
          {status === BiddingConstants.Ended && tBidding(`status.${status}-text${moreEstatesLink ? '-with-link' : ''}`)}{' '}
          {moreEstatesLink != null && status === BiddingConstants.Ended && (
            <a href={moreEstatesLink.Url} className="text-link">
              {moreEstatesLink.Name}
            </a>
          )}
        </span>
      )}
    </div>
  );
}
