import { BuySellTip } from '../../services/generated-api-client';
import List from '../List/List';
import Tip from '../Tip/Tip';

interface Props {
  tips: BuySellTip[];
  title?: string;
  textOnTopOfImage?: boolean;
}

export default function TipList({ tips, title, textOnTopOfImage }: Props): JSX.Element {
  return (
    <List title={title}>
      {tips &&
        tips.map(
          (tip) =>
            tip.Title &&
            tip.LinkText &&
            tip.LinkUrl && (
              <Tip
                key={tip.Uid}
                image={tip.ImageUrl}
                title={tip.Title}
                description={tip.Description}
                linkText={tip.LinkText}
                linkUrl={tip.LinkUrl}
                textOnTopOfImage={textOnTopOfImage}
              />
            ),
        )}
    </List>
  );
}
