import { useEffect, useState } from 'react';
import StepDescription from '../../components/StepDescription/StepDescription';
import ChecklistList from '../../components/Lists/ChecklistList';
import Event from '../../components/Event/Event';
import Error from '../Error/ErrorPage';
import ApiClient from '../../services/api-client';
import { BuySellSellSellPreparationsView } from '../../services/generated-api-client';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DocumentList from '../../components/Lists/DocumentList';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import FormList from '../../components/Lists/FormList';

export default function SellPreparationsPage(): JSX.Element {
  const { t: tProcess } = useTranslation('process');
  const [content, setContent] = useState<BuySellSellSellPreparationsView>();
  const [loading, setLoading] = useState<boolean>(true);
  const { estateId } = useParams();
  const step = 'Preparations';

  useEffect(() => {
    ApiClient.authenticated
      .getSellPreparationsView(estateId!, {
        cancelToken: 'getSellPreparationsView',
      })
      .then((response) => {
        setContent(response.data);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getSellPreparationsView');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.Preparations')}</h1>

          <div className="process-content">
            {!!content.Event && (
              <Event
                title={content.Event.Title!}
                pushText={content.Event.PushText!}
                subText={content.Event.SubText!}
                subTextLink={content.Event.SubTextLink}
              />
            )}

            {!!content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}

            {!!content.Forms?.length && <FormList forms={content.Forms} title={tProcess('forms-title')} />}
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <Error text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
