import {
  ArrayControlProps,
  RankedTester,
  rankWith,
  or,
  isObjectArrayControl,
  isPrimitiveArrayControl,
  UISchemaElement,
  composePaths,
} from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { JsonFormsDispatch, useJsonForms, withJsonFormsArrayControlProps } from '@jsonforms/react';
import uuid from '../../utils/uuid';

interface UISchemaElementLocal {
  scope: string;
  label: string;
  description: string;
}

export const formArrayControlTester: RankedTester = rankWith(10, or(isObjectArrayControl, isPrimitiveArrayControl));

export const FormArrayControl = ({
  schema,
  uischema,
  data,
  path,
  enabled,
  renderers,
  addItem,
  removeItems,
  label,
}: ArrayControlProps & VanillaRendererProps): JSX.Element => {
  const { i18n } = useJsonForms();
  const t = (key: string): string => i18n!.translate!(path + '.' + key) ?? '';
  const arrSchema = getArrSchema(uischema);
  const readonly = isReadonly(uischema);

  const arr = (data as object[]) ?? [];

  schema?.required?.forEach((propName) => {
    if (propName === 'id') {
      arr.forEach((arrObject) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const looseObject = arrObject as { [k: string]: any };
        if (looseObject[propName] === undefined) {
          looseObject[propName] = uuid();
        }
      });
    }
  });

  const uiSchemaLocal = uischema as unknown as UISchemaElementLocal;
  const translatedLabel = translate(path + '.label', uiSchemaLocal.label);
  const translatedDescription = translate(path + '.description', uiSchemaLocal.description);

  function translate(key: string, defaultMessage?: string): string {
    if (i18n) {
      if (i18n.translate) {
        key = key ?? '';
        defaultMessage = defaultMessage ?? key;
        //console.log(key, defaultMessage);
        const translated = i18n.translate(key, defaultMessage);
        if (translated !== defaultMessage) {
          return translated;
        }
      }
    }
    return '';
  }

  function isReadonly(uischema: UISchemaElement): boolean {
    if (!uischema) return false;
    if (!uischema.options) return false;
    return uischema.options['readonly'] as boolean;
  }

  function getArrSchema(uischema: UISchemaElement): UISchemaElement | undefined {
    if (!uischema) return uischema;
    if (!uischema.options) return uischema;
    return uischema.options['detail'] as UISchemaElement;
  }

  //console.log('Rendering array:', label, path, uischema, schema);

  return (
    <div className="array" id={path}>
      <label className="array-label" htmlFor={path}>
        {translatedLabel}
      </label>
      <div className="array-description">{translatedDescription}</div>
      {arr.map((imp, idx) => {
        const arrPath = composePaths(path, `${idx}`);
        return (
          <div className="improvement">
            <div className="one-row">
              <JsonFormsDispatch schema={schema} uischema={arrSchema} path={arrPath} key={arrPath} renderers={renderers} />
            </div>
            {!readonly && (
              <div className="button-container">
                <button
                  className="button-delete"
                  data-testid="button"
                  onClick={removeItems!(path, [idx])}
                  title="Ta bort"
                  disabled={!enabled}
                ></button>
              </div>
            )}
          </div>
        );
      })}
      {!readonly && (
        <button className="button-add" data-testid="button" onClick={addItem(path, {})} disabled={!enabled}>
          {t('add-text')}
        </button>
      )}
    </div>
  );
};

export default withVanillaControlProps(withJsonFormsArrayControlProps(FormArrayControl));
