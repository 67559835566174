import jwtDecode, { JwtPayload } from 'jwt-decode';
import supportsLocalStorage from '../utils/supports-local-storage';
import windowId from '../utils/window-id';
import { removeSearchParamFromUrl } from '../utils/url-utils';

export const TOKEN_QUERY_BANKID_TOKEN = 'bankid_token';
export const TOKEN_QUERY_SESSION_STATE = 'session_state';
export const TOKEN_QUERY_STATE = 'state';
export const TOKEN_QUERY_ACCESS_TOKEN = 'access_token';
export const TOKEN_STORAGE_KEY = 'access_token';

let cachedAccessToken: string | null;
let navigateToAfterLogin: string | null;

export class SecurityService {
  setNavigateToAfterLogin(path: string): void {
    navigateToAfterLogin = path;
  }

  get navigateToAfterLogin(): string {
    return navigateToAfterLogin ?? '/';
  }

  get isAuthenticated(): boolean {
    const accessToken = this.getValidAccessToken();
    if (!accessToken) {
      return false;
    }

    return true;
  }

  checkForAuthenticationCallback(): void {
    const accessToken = this.getAccessTokenFromUrl();
    if (!accessToken) {
      return;
    }

    this.removeAccessTokenFromUrl();

    const token = this.parseAccessToken(accessToken);
    if (!token) {
      return;
    }

    const isValidToken = this.validateJwtToken(token);
    if (!isValidToken) {
      return;
    }

    this.storeAccessToken(accessToken);
  }

  getAccessTokenFromUrl(): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(TOKEN_QUERY_ACCESS_TOKEN);
  }

  getBankIdTokenFromUrl(): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(TOKEN_QUERY_BANKID_TOKEN);
  }

  getAccessToken(): string | null {
    const accessTokenFromUrl = this.getAccessTokenFromUrl();
    if (accessTokenFromUrl) {
      cachedAccessToken = accessTokenFromUrl;
    }
    if (!cachedAccessToken && windowId) {
      cachedAccessToken = this.getTokenFromStorage(TOKEN_STORAGE_KEY + '_' + windowId);
    }
    if (!cachedAccessToken) {
      cachedAccessToken = this.getTokenFromStorage(TOKEN_STORAGE_KEY);
    }
    return cachedAccessToken;
  }

  getTokenFromStorage(storageKey: string): string | null {
    if (!supportsLocalStorage) {
      return null;
    }
    //console.log('Getting accesstoken @ ' + storageKey);
    cachedAccessToken = localStorage.getItem(storageKey);
    if (cachedAccessToken) {
      if (this.validateJwtToken(this.parseAccessToken(cachedAccessToken))) {
        return cachedAccessToken;
      } else {
        localStorage.removeItem(storageKey);
        return null;
      }
    }
    return null;
  }

  getJwtToken(): JwtPayload | null {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }
    return this.parseAccessToken(accessToken);
  }

  getValidAccessToken(): string | null {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const token = this.parseAccessToken(accessToken);
    if (!token) {
      return null;
    }

    const isValidToken = this.validateJwtToken(token);
    if (!isValidToken) {
      return null;
    }

    return accessToken;
  }

  parseAccessToken(accessToken: string): JwtPayload | null {
    let payload;
    try {
      payload = jwtDecode<JwtPayload>(accessToken);
    } catch (e) {
      console.warn('Something went wrong while parsing access token.', e);
      return null;
    }

    return payload;
  }

  storeAccessToken(accessToken: string): void {
    const parsedAccessToken = this.parseAccessToken(accessToken);
    if (!parsedAccessToken) {
      return;
    }
    cachedAccessToken = accessToken;
    if (supportsLocalStorage) {
      let storageKey = TOKEN_STORAGE_KEY;
      if (this.isJwtImpersonated(parsedAccessToken)) {
        storageKey = TOKEN_STORAGE_KEY + '_' + windowId;
      }
      //console.log('Storing access key @ ' + storageKey);
      localStorage.setItem(storageKey, accessToken);
    }
  }

  validateJwtToken(token: JwtPayload | null): boolean {
    if (!token) {
      return false;
    }

    const exp = token.exp || 0;
    return exp > Date.now() / 1000;
  }

  removeAccessTokenFromUrl(): void {
    removeSearchParamFromUrl(TOKEN_QUERY_ACCESS_TOKEN);
  }

  removeBankIdTokenFromUrl(): void {
    removeSearchParamFromUrl(TOKEN_QUERY_BANKID_TOKEN);
    removeSearchParamFromUrl(TOKEN_QUERY_SESSION_STATE);
    removeSearchParamFromUrl(TOKEN_QUERY_STATE);
  }

  clearStoredAccessToken(): void {
    cachedAccessToken = null;
    if (supportsLocalStorage) {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
    }
  }

  getJwtName(jwt?: JwtPayload | null): string | null {
    if (jwt == null) {
      jwt = this.getJwtToken();
    }
    if (jwt == null) {
      return null;
    }
    type ObjectKey = keyof typeof jwt;
    let name = jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name' as ObjectKey] as string | null;
    if (name == null) {
      name = jwt['name' as ObjectKey] as string | null;
    }
    return name;
  }

  isJwtImpersonated(jwt?: JwtPayload | null): boolean {
    if (jwt == null) {
      jwt = this.getJwtToken();
    }
    if (jwt == null) {
      return false;
    }
    type ObjectKey = keyof typeof jwt;
    const actor_sub = jwt['actor_sub' as ObjectKey] as string | null;
    return actor_sub != null;
  }

  getJwtActorName(): string | null {
    const jwt = this.getJwtToken();
    if (jwt == null) {
      return null;
    }
    type ObjectKey = keyof typeof jwt;
    return jwt['actor_name' as ObjectKey] as string | null;
  }
}

const securityService = new SecurityService();
export default securityService;
