import React, { FunctionComponent } from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs, UISchemaElement, ControlElement, computeLabel, toDataPath } from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { withJsonFormsLayoutProps, DispatchCell, useJsonForms } from '@jsonforms/react';

export const formMspxBooleanArrayTester: RankedTester = rankWith(10, uiTypeIs('Mspx_BooleanArray'));

export const FormMspxBooleanArrayRenderer = (props: LayoutProps & VanillaRendererProps): JSX.Element => {
  const { data: _data, ...otherProps } = props;
  // We don't hand over data to the layout renderer to avoid rerendering it with every data change
  return <MspxBooleanArrayRenderer {...otherProps} />;
};

interface UISchemaElementLocal {
  elements: UISchemaElementLocal2[];
  label: string;
}
interface UISchemaElementLocal2 extends UISchemaElement {
  scope: string;
  label: string;
  path: string;
}
const MspxBooleanArrayRenderer: FunctionComponent<LayoutProps & VanillaRendererProps> = React.memo(function GroupLayoutRendererComponent({
  classNames,
  schema,
  uischema,
  path,
  enabled,
  visible,
  label,
  getStyle,
  getStyleAsClassName,
}: LayoutProps & VanillaRendererProps) {
  const uischemalocal = uischema as unknown as UISchemaElementLocal;
  const { i18n } = useJsonForms();

  function translate(key: string, defaultMessage: string): string {
    if (i18n?.translate == null) return key;
    return i18n?.translate(key, defaultMessage) ?? defaultMessage;
  }

  uischemalocal.elements.forEach((o, idx) => {
    o.path = toDataPath(o.scope);
    o.label = translate(o.path + '.label', o.label);
  });

  return (
    <>
      <div className="control array">
        <label htmlFor={'x-input'} className={classNames!.wrapper}>
          {computeLabel(label, false, false)}
        </label>{' '}
        {uischemalocal.elements
          .sort((one, two) => (one.label > two.label ? 1 : -1))
          .map((o, idx) => {
            const id = o.scope.replace('#', '') + '.' + idx;
            const checkboxuischema = {
              type: 'Control',
              scope: o.scope,
              label: o.label,
              options: {
                noWidth: true,
              },
            } as ControlElement;
            return (
              <div className="array-item">
                <DispatchCell uischema={checkboxuischema} schema={schema} path={o.path} id={id + '-input'} enabled={enabled} />
                <label htmlFor={id + '-input'} className={classNames!.wrapper}>
                  {computeLabel(o.label, false, false)}
                </label>{' '}
              </div>
            );
          })}
      </div>
    </>
  );
});

export default withVanillaControlProps(withJsonFormsLayoutProps(MspxBooleanArrayRenderer));
