import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ApiClient from '../../services/api-client';
import SecurityService from '../../services/security-service';
import { useNavigate } from 'react-router-dom';
import { setAccessToken } from '../../services/api-client';

export default function LoginCallback(): JSX.Element {
  const { oidcScopeId } = useParams();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!oidcScopeId) {
      console.error('No oidc scope supplied');
      return;
    }

    ApiClient.public
      .getRedirect(oidcScopeId!)
      .then((o) => {
        SecurityService.setNavigateToAfterLogin(o.data);

        const bankIdToken = SecurityService.getBankIdTokenFromUrl();
        if (bankIdToken == null) {
          ApiClient.public
            .getJwtToken(oidcScopeId)
            .then((response) => {
              SecurityService.storeAccessToken(response.data);
              setAccessToken(response.data);
              setAuthenticated(true);
            })
            .catch(() => {
              navigate('/error');
            });
        } else {
          SecurityService.removeBankIdTokenFromUrl();
          ApiClient.public
            .refreshToken(bankIdToken)
            .then((response) => {
              SecurityService.storeAccessToken(response.data);
              setAccessToken(response.data);
              setAuthenticated(true);
            })
            .catch(() => {
              navigate('/error');
            });
        }
      })
      .catch(() => {
        console.log('Failed to fetch the redirect page');
        navigate('/error');
      });
  }, [navigate, oidcScopeId]);

  return <>{authenticated ? <Navigate to={SecurityService.navigateToAfterLogin} /> : <></>}</>;
}
