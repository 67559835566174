import {
  ArrayControlProps,
  RankedTester,
  rankWith,
  uiTypeIs,
  UISchemaElement,
  JsonSchema,
  JsonFormsRendererRegistryEntry,
  composePaths,
} from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { JsonFormsDispatch, useJsonForms, withJsonFormsArrayControlProps } from '@jsonforms/react';

export const formMspxCapitalGainImprovmentsTester: RankedTester = rankWith(10, uiTypeIs('Mspx_CapitalGainImprovments'));

interface Improvement {
  newAndRemodelling: boolean;
  repairAndMaintenance: boolean;
  year: number;
  description: string;
  cost: number;
  costDeduction: number;
}

interface ImprovementControlProps {
  schema: JsonSchema;
  uischema: UISchemaElement;
  improvement: Improvement;
  path: string;
  renderers: JsonFormsRendererRegistryEntry[];
  t: (key: string) => string;
  removeItems: (path: string, toDelete: number[]) => () => void;
}

const ImprovementRenderer = ({ schema, improvement, uischema, path, renderers, t, removeItems }: ImprovementControlProps): JSX.Element => {
  const arrPath = path.substring(0, path.lastIndexOf('.'));
  const idx = Number.parseInt(path.substring(path.lastIndexOf('.') + 1, path.length));
  return (
    <div className="improvement">
      <JsonFormsDispatch schema={schema} uischema={uischema} path={path} key={path} renderers={renderers} />
      <button className="button-delete" data-testid="button" onClick={removeItems(arrPath, [idx])}>
        {t('delete-text')}
      </button>
    </div>
  );
};

export const FormMspxCapitalGainImprovmentsRenderer = ({
  schema,
  uischema,
  data,
  path,
  renderers,
  addItem,
  removeItems,
}: ArrayControlProps & VanillaRendererProps): JSX.Element => {
  const { i18n } = useJsonForms();
  const t = (key: string): string => i18n!.translate!(path + '.' + key) ?? '';
  const improvementSchema = uischema.options!['detail'] as UISchemaElement;

  const improvements = (data as Improvement[]) ?? [];
  const newAndRemodelling = improvements.filter((o) => o.newAndRemodelling);
  const repairAndMaintenance = improvements.filter((o) => o.repairAndMaintenance);

  return (
    <div className="improvements">
      <div className="newAndRemodelling">
        <legend>{t('newAndRemodelling')}</legend>
        {newAndRemodelling.map((imp) => {
          const childPath = composePaths(path, `${improvements.indexOf(imp)}`);
          return (
            <ImprovementRenderer
              uischema={improvementSchema}
              schema={schema}
              path={childPath}
              improvement={imp}
              renderers={renderers!}
              t={t}
              removeItems={removeItems!}
            />
          );
        })}
        <button className="button-add" data-testid="button" onClick={addItem(path, { newAndRemodelling: true })}>
          {t('add-text')}
        </button>
      </div>
      <div className="repairAndMaintenance">
        <legend>{t('repairAndMaintenance')}</legend>
        {repairAndMaintenance.map((imp) => {
          const childPath = composePaths(path, `${improvements.indexOf(imp)}`);
          return (
            <ImprovementRenderer
              uischema={improvementSchema}
              schema={schema}
              path={childPath}
              improvement={imp}
              renderers={renderers!}
              t={t}
              removeItems={removeItems!}
            />
          );
        })}
        <button className="button-add" data-testid="button" onClick={addItem(path, { repairAndMaintenance: true })}>
          {t('add-text')}
        </button>
      </div>
    </div>
  );
};

export default withVanillaControlProps(withJsonFormsArrayControlProps(FormMspxCapitalGainImprovmentsRenderer));
