import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  LineControllerChartOptions,
  Filler,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuySellGraphDataset } from '../../../services/generated-api-client';
import { formatSwedishDate, swedishNumberFormatter } from '../../../utils/locale-utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface ImpressionsChart {
  chartData: ChartData<'line', number[], unknown>;
  options: _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  >;
}

export default function useImpressionsChart(datasets: BuySellGraphDataset[]): ImpressionsChart {
  const [chartData, setChartData] = useState<ChartData<'line', number[], unknown>>({ labels: [], datasets: [] });
  const { t } = useTranslation('marketing');

  useEffect(() => {
    const style = getComputedStyle(document.body);

    const chartData: ChartData<'line', number[], unknown> = {
      labels: datasets[0]?.DataPoints?.map((datapoint) => formatDateLabel(datapoint.Date ?? '')),

      datasets: datasets.map((dataset, i) => {
        // Assume that first dataset is "total"-data
        const color =
          i === 0
            ? style.getPropertyValue('--impressions-total-data-color')
            : style.getPropertyValue('--impressions-broker-channel-data-color');
        const borderColor =
          i === 0
            ? style.getPropertyValue('--impressions-total-border-color')
            : style.getPropertyValue('--impressions-broker-channel-border-color');

        const numberOfImpressions = dataset.DataPoints?.map((datapoint) => datapoint.Value!).reduce((sum, value) => sum + value, 0);

        return {
          label: `${dataset.Name} (${numberOfImpressions})`,
          data: dataset?.DataPoints?.map((datapoint) => datapoint.Value ?? 0) ?? [],
          backgroundColor: color,
          borderColor: borderColor,
          borderWidth: 3, // Optionally adjust the width of the lines
          pointRadius: 0, // Remove the small circles
          tension: 0.1, // Optionally adjust the tension for smoother curves
          order: i === 0 ? 1 : 0,
        };
      }),
    };

    setChartData(chartData);
  }, [datasets]);

  const formatDateLabel = (date: string | undefined): string | null => {
    if (date == null) return null;

    return formatSwedishDate(date, 'compact');
  };

  const style = getComputedStyle(document.body);
  const font = {
    family: style.getPropertyValue('--body-font'),
    size: 12,
    weight: '300',
  };
  const options: _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  > = {
    locale: 'sv-SE',
    maintainAspectRatio: false,
    elements: {
      line: {
        borderWidth: 0,
        fill: true,
      },
      point: {
        borderWidth: 1,
        borderColor: style.getPropertyValue('--point-border-color'),
        radius: Number(style.getPropertyValue('--point-radius')),
        hitRadius: 10,
        hoverRadius: Number(style.getPropertyValue('--point-radius')) + 0.5,
        hoverBorderWidth: 1,
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: style.getPropertyValue('--chart-label-color'),
        },
      },
      yAxes: {
        grid: {
          borderDash: [5, 5],
          drawBorder: false,
          color: style.getPropertyValue('--chart-grid-line-color'),
        },
        ticks: {
          callback: function (value, index, ticks): string {
            return swedishNumberFormatter.format(Number(value));
          },
          color: style.getPropertyValue('--chart-label-color'),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context): string {
            const date = datasets[0]?.DataPoints?.filter((datapoint) => formatDateLabel(datapoint.Date) === context[0].label)[0]?.Date;
            return formatSwedishDate(date ?? '') ?? '';
          },
          label: function (context): string {
            return t('number-of-impressions', { quantity: context.parsed.y });
          },
        },
        boxPadding: 4,
        boxWidth: Number(style.getPropertyValue('--chart-legend-color-box-size')),
        boxHeight: Number(style.getPropertyValue('--chart-legend-color-box-size')),
        backgroundColor: style.getPropertyValue('--chart-tooltip-bg-color'),
        borderWidth: 1,
        borderColor: style.getPropertyValue('--chart-tooltip-border-color'),
        titleColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
        bodyColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
        footerColor: style.getPropertyValue('--chart-tooltip-foreground-color'),
        titleFont: font,
        bodyFont: { ...font, size: 14 },
        footerFont: font,
        padding: 8,
      },
    },
  };

  return { chartData, options };
}
