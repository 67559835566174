import List from '../List/List';
import { FormForm } from '../../services/generated-api-client';
import FormLink from '../Form/FormLink';

interface Props {
  forms: FormForm[];
  title: string;
}

export default function FormList({ forms, title }: Props): JSX.Element {
  return (
    <List title={title}>
      {forms.map((form) => form.Name && <FormLink key={form.Uid} formId={form.Uid!} name={form.Name!} status={form.FormStatus!} />)}
    </List>
  );
}
