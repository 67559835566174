import { formatSwedishDate } from '../../utils/locale-utils';
import { useTranslation } from 'react-i18next';

interface Props {
  viewingType: 'OnlyComment' | 'ContactBroker' | 'Date' | 'DateAndTime' | 'TimeSlots';
  comment?: string;
  startDate?: string;
  endDate?: string;
  bookings: number;
  visitors: number;
}

export default function Viewing({ viewingType, comment, startDate, endDate, bookings, visitors }: Props): JSX.Element {
  const { t } = useTranslation('viewing');

  return (
    <div className="viewing" data-testid="viewing">
      {viewingType === 'ContactBroker' && (
        <div className="date" data-testid="date">
          {t('contact')}
        </div>
      )}
      {viewingType === 'Date' && (
        <div className="date" data-testid="date">
          {formatSwedishDate(startDate)}
        </div>
      )}
      {viewingType === 'DateAndTime' && (
        <div className="date" data-testid="date">
          {formatSwedishDate(startDate)} {formatSwedishDate(startDate, 'time')} &ndash; {formatSwedishDate(endDate, 'time')}
        </div>
      )}
      {viewingType === 'TimeSlots' && (
        <div className="date" data-testid="date">
          {formatSwedishDate(startDate)} {formatSwedishDate(startDate, 'time')} &ndash; {formatSwedishDate(endDate, 'time')}
        </div>
      )}
      {comment && (
        <div className="comment" data-testid="comment">
          {comment}
        </div>
      )}
      <div className="bookings" data-testid="bookings">
        {' '}
        {bookings ? bookings + ' ' + t('bookings') : ''}{' '}
      </div>
      <div className="visitors" data-testid="visitors">
        {' '}
        {visitors ? visitors + ' ' + t('visitors') : ''}{' '}
      </div>
    </div>
  );
}
