import { Link, useParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  status: string;
  formId: string;
}

export default function FormLink({ name, status, formId }: Props): JSX.Element {
  const { t } = useTranslation('form-link');

  const { view, estateId, step } = useParams();
  const query = step ? `?returnToStep=${step}` : '';
  const route = `/${view}/${estateId}/form/${formId}${query}`;

  return (
    <Link to={route} className="form-link" data-testid="form-link">
      <span className="title"> {name} </span>
      <div className="content">
        <span className={'forms form-' + status.toLowerCase()}> {t('form-status.' + status)} </span>
        <ArrowIcon aria-label={t('link-label', { title: name })} />
      </div>
    </Link>
  );
}
