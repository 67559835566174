import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as SPIcon } from './logo_new_shield.svg';

interface Props {
  loanCommitment: string;
  route: string;
}

export default function LoanSummary({ loanCommitment, route }: Props): JSX.Element {
  const { t } = useTranslation('loan-summary');

  return (
    <div className="loan-summary">
      <div className="loan-commitment-text"> {t('loan-commitment-text')} </div>
      <div className="content">
        {loanCommitment?.toUpperCase() === 'SÖDERBERG & PARTNERS BOLÅN' && <SPIcon className="sp-icon" />}
        <span className="loan-commitment-info"> {loanCommitment} </span>
        <Link to={route} className="change-choice-link text-link">
          {' '}
          {t('change')}{' '}
        </Link>
      </div>
    </div>
  );
}
