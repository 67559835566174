import { useEffect, useState } from 'react';
import StepDescription from '../../components/StepDescription/StepDescription';
import ObjectDescription from '../../components/StepDescription/ObjDescEstateData';
import Error from '../Error/ErrorPage';
import ApiClient from '../../services/api-client';
import { BuySellSellSellEstateDataView } from '../../services/generated-api-client';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import Infobox from '../../components/Infobox/Infobox';
import List from '../../components/List/List';
import ChecklistList from '../../components/Lists/ChecklistList';
import DocumentList from '../../components/Lists/DocumentList';
import FormList from '../../components/Lists/FormList';
import DownloadImagesButton from '../../components/Download/DownloadImagesButton';
//Description hidden until step preparation has been passed
export default function BuyEstateData(): JSX.Element {
  const { t } = useTranslation('estate-data-page');
  const { t: tProcess } = useTranslation('process');
  const [content, setContent] = useState<BuySellSellSellEstateDataView>();
  const [loading, setLoading] = useState<boolean>(true);
  const { estateId } = useParams();
  const step = 'EstateData';
  const imageSize = (window.screen?.width ?? 784) > 592 ? 784 : 400;
  const currentStep = content?.Estate?.CurrentStep;
  const estateDescription = content?.EstateDescription;
  const descriptionText = currentStep !== 'Preparations' ? estateDescription?.ShortText || estateDescription?.LongText || '' : '';

  useEffect(() => {
    ApiClient.authenticated
      .getSellEstateDataView(estateId!, imageSize, {
        cancelToken: 'getSellEstateDataView',
      })
      .then((response) => setContent(response.data as BuySellSellSellEstateDataView))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getSellEstateDataView');
    };
  }, [estateId, imageSize]);
  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.EstateData')}</h1>

          <div className="process-content">
            <StepDescription step={step} title={content.Description?.Title} description={content.Description?.Description} />
            {descriptionText && <ObjectDescription description={descriptionText} />}
            {content?.Estate?.CurrentStep !== 'Preparations' && (
              <div className="bank-info">
                <Infobox title={t('estate-type')} text={t('estate-types.' + (content.Estate?.Type || 'Unknown')) || ''} />
                <Infobox title={t('number-of-rooms')} text={content.EstateDescription?.NumberOfRooms?.toString() ?? ''} />
                <Infobox title={t('living-space')} text={content.EstateDescription?.LivingSpace?.toString() ?? ''} />
              </div>
            )}
            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}

            {!!content.Forms?.length && <FormList forms={content.Forms} title={tProcess('forms-title')} />}

            <div>
              {content.Images && (
                <div className="estate-data">
                  <List title={t('images') + ' (' + content.Images.length + ' st)'}>
                    <div className="buttons">
                      <DownloadImagesButton
                        downloadUrls={content?.Images?.map((o) => o.ImageUri ?? '') ?? []}
                        names={content?.Images?.map((o, idx) => 'bild_' + (idx + 1) + o.ImageExtension ?? '') ?? []}
                      ></DownloadImagesButton>
                    </div>
                    {content.Images.map(
                      (image) =>
                        image.ImageUri && (
                          <div>
                            <img className="estateData-imagesize" src={image.ImageUri + '?transformOps=r' + imageSize + 'x0'} alt="bild" />
                          </div>
                        ),
                    )}
                  </List>
                </div>
              )}
            </div>
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <Error text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
