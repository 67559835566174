import { Bar } from 'react-chartjs-2';
import useImpressionsPerChannel from './useImpressionsPerChannel';
import { useTranslation } from 'react-i18next';
import { BuySellMarketingChannelImpressions } from '../../../services/generated-api-client';
import ImpressionsContainer from '../ImpressionsContainer';

interface Props {
  channelImpressions: BuySellMarketingChannelImpressions[];
}

export default function ImpressionsPerChannel({ channelImpressions }: Props): JSX.Element {
  const { getChartData, getChartOptions } = useImpressionsPerChannel();
  const { t } = useTranslation('marketing');

  return (
    <ImpressionsContainer title={t('impressions-per-channel')}>
      <Bar className="chart" data={getChartData(channelImpressions, true)} options={getChartOptions()} />
    </ImpressionsContainer>
  );
}
