interface Props {
  enabled: boolean;
}

export default function Spinner({ enabled }: Props): JSX.Element {
  return (
    <div className="spinner-container" data-testid="spinner">
      {enabled && <div className="loading-spinner"></div>}
    </div>
  );
}
