import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChecklistList from '../../components/Lists/ChecklistList';
import StepDescription from '../../components/StepDescription/StepDescription';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import FinalPrice from '../../components/FinalPrice/FinalPrice';
import ApiClient from '../../services/api-client';
import { BuySellBuyBuyAfterEntryView } from '../../services/generated-api-client';
import ErrorPage from '../Error/ErrorPage';
import DocumentList from '../../components/Lists/DocumentList';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import Recommendation from '../../components/Recommendation/Recommendation';

export default function BuyAfterEntryPage(): JSX.Element {
  const { estateId } = useParams();
  const { t: tProcess } = useTranslation('process');
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<BuySellBuyBuyAfterEntryView>();
  const step = 'AfterEntry';

  useEffect(() => {
    ApiClient.authenticated
      .getBuyAfterEntryView(estateId!, { cancelToken: 'getBuyAfterEntryView' })
      .then((response) => {
        setContent(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getBuyAfterEntryView');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.AfterEntry')}</h1>

          <div className="process-content">
            {!!content.Price && <FinalPrice price={content.Price} />}

            {!!content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('brochures-title')} />}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            <Recommendation />
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <ErrorPage text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
