const Steps = {
  Preparations: 'Preparations',
  Marketing: 'Marketing',
  ViewingBidding: 'ViewingBidding',
  Contract: 'Contract',
  DownPayment: 'DownPayment',
  Entry: 'Entry',
  AfterEntry: 'AfterEntry',
  EstateData: 'EstateData',
};

export default Steps;
