import { BuySellBid } from '../../services/generated-api-client';
import { bidsWithUser } from '../../utils/chart-utils';
import { formatSwedishDate, swedishCurrencyFormatter } from '../../utils/locale-utils';
import List from '../List/List';
import { useTranslation } from 'react-i18next';

interface Props {
  bids: BuySellBid[];
  title?: string;
}

export default function BidList({ bids, title }: Props): JSX.Element {
  const { t } = useTranslation('bid-history');
  const { t: tBidding } = useTranslation('bidding');

  return (
    <List title={title}>
      {bids &&
        bidsWithUser(bids, t)
          .sort((a, b) => b.Amount! - a.Amount!)
          .map(
            (bid) =>
              bid.Date &&
              bid.Amount &&
              bid.BidderName && (
                <div className="bid-list" key={bid.Uid}>
                  <div>
                    <div className="bid-amount"> {swedishCurrencyFormatter.format(bid.Amount)} </div>
                    <div className="bidder"> {t(bid.BidderName)} </div>
                    {bid.IsHidden === true && <div className="bidder"> {tBidding('status.HiddenBid')} </div>}
                  </div>
                  <div className="date">
                    {' '}
                    {formatSwedishDate(bid.Date)} {formatSwedishDate(bid.Date, 'time')}{' '}
                  </div>
                </div>
              ),
          )}
    </List>
  );
}
