const BiddingConstants = {
  BiddingPath: 'Bidding',
  NotBiddingParticipant: 'NotBiddingParticipant',
  Halted: 'Halted',
  InProgress: 'InProgress',
  Paused: 'Paused',
  Ended: 'Ended',
};

export default BiddingConstants;
