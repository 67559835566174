import { CellProps, RankedTester, UISchemaElement, JsonSchema, TesterContext } from '@jsonforms/core';
import { withJsonFormsCellProps } from '@jsonforms/react';
import type { VanillaRendererProps } from '@jsonforms/vanilla-renderers';
import { withVanillaCellProps } from '@jsonforms/vanilla-renderers';

export const MoneyCellRenderer = (props: CellProps & VanillaRendererProps): JSX.Element => {
  const { data, className, id, enabled, uischema, path, handleChange } = props;

  const toNumber = (value: string): number | undefined => {
    return value === '' ? undefined : parseInt(value, 10);
  };

  return (
    <>
      <input
        type="number"
        value={data || ''}
        onChange={(ev): void => handleChange(path, toNumber(ev.target.value))}
        className={className + ' money'}
        id={id}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
    </>
  );
};

export const moneyCellTester: RankedTester = (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): number => {
  if (schema.format === 'money') {
    return 3;
  }
  return 0;
};

export default withJsonFormsCellProps(withVanillaCellProps(MoneyCellRenderer));
