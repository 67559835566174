import { useState } from 'react';
import { ReactComponent as Arrow } from './downarrow.svg';

interface Props {
  id: string;
  title: string;
  children: React.ReactNode;
  expandedTitle?: string;
}

export default function Expandable({ id, title, children, expandedTitle = title }: Props): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div id={id} className="expandable" aria-expanded={expanded}>
      <button
        aria-controls={id}
        onClick={(): void => setExpanded(!expanded)}
        className={expanded ? 'expanded expand-button' : 'expand-button'}
      >
        <span>{expanded ? expandedTitle : title}</span>
        <Arrow />
      </button>
      {expanded && children}
    </div>
  );
}
