import { EstatePrice } from '../../services/generated-api-client';
import PriceInfo from '../Price/PriceInfo';
import { ReactComponent as KeyIcon } from './key.svg';

interface Props {
  price: EstatePrice;
}

export default function FinalPrice({ price }: Props): JSX.Element {
  return (
    <div className="final-price" data-testid="final-price">
      <KeyIcon className="icon" role="presentation" />
      <PriceInfo price={price} showPricePerSquareMeter={true} />
    </div>
  );
}
