import maxBy from 'lodash/maxBy';
//import isEmpty from 'lodash/isEmpty';
import {
  computeLabel,
  ControlProps,
  isControl,
  NOT_APPLICABLE,
  RankedTester,
  rankWith,
  and,
  not,
  or,
  isObjectArrayControl,
  isPrimitiveArrayControl,
} from '@jsonforms/core';
import { DispatchCell, useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import type { VanillaRendererProps } from '@jsonforms/vanilla-renderers';
import merge from 'lodash/merge';
import { isEmpty } from 'lodash';

export const InputControl = (props: ControlProps & VanillaRendererProps): JSX.Element | null => {
  const {
    classNames,
    //description,
    id,
    errors,
    label,
    uischema,
    schema,
    rootSchema,
    visible,
    enabled,
    required,
    path,
    cells,
    config,
  } = props;

  const { i18n, core } = useJsonForms();

  function onFocus(): void {
    if (false) console.log('onFocus');
  }

  function onBlur(): void {
    if (false) console.log('onFocus');
  }

  function translate(key: string): string {
    if (i18n?.translate == null) return key;
    return i18n?.translate(key) ?? key;
  }

  function getErrorMessage(): string {
    //console.log(core?.errors);
    const instancePath = '/' + path.replaceAll('.', '/');
    const error = core?.errors?.find((o) => o.instancePath === instancePath);
    const errorPath = path + '.error.custom';
    //console.log(instancePath + ':' + error?.message);
    return i18n?.translate!(errorPath, error?.message) ?? errorPath;
  }

  const suffix = uischema?.options?.suffix;
  const optionsClassName = uischema?.options?.className;
  const isValid = errors.length === 0;

  const divClassNames = [classNames!.wrapper]
    .concat(isValid ? '' : classNames!.validationError)
    .concat(optionsClassName ? optionsClassName : '')
    .filter((o) => (o?.length ?? 0) > 0)
    .join(' ');

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const testerContext = {
    rootSchema: rootSchema,
    config: config,
  };
  const cell = maxBy(cells, (r) => r.tester(uischema, schema, testerContext));
  if (cell === undefined || cell.tester(uischema, schema, testerContext) === NOT_APPLICABLE) {
    console.warn('No applicable cell found.', uischema, schema);
    return null;
  } else {
    return (
      <div className={divClassNames} hidden={!visible} onFocus={onFocus} onBlur={onBlur} id={id}>
        {!isEmpty(errors) && (
          <div className="error-anchor">
            <div className="error-text">{getErrorMessage()}</div>
          </div>
        )}
        <label htmlFor={id + '-input'} className={classNames!.label}>
          {computeLabel(label, required!, appliedUiSchemaOptions.hideRequiredAsterisk)}
        </label>
        {/*!isEmpty(description) && <div className='info'>(i)</div>*/}
        <DispatchCell uischema={uischema} schema={schema} path={path} id={id + '-input'} enabled={enabled} />
        {!isEmpty(suffix) && <div className="suffix">{translate(suffix)}</div>}
      </div>
    );
  }
};

export const formInputTester: RankedTester = rankWith(10, and(isControl, not(or(isObjectArrayControl, isPrimitiveArrayControl))));

export default withVanillaControlProps(withJsonFormsControlProps(InputControl));
