import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import QrLogin from '../../components/QRLogin/QrLogin';

export default function LoginRemote(): JSX.Element {
  const { oidcScopeId } = useParams();
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  if (oidcScopeId === undefined) {
    navigate('/login/');
    return <></>;
  }
  return (
    <div className="login login-remote">
      <h1>{t('login-remote-header')}</h1>
      <QrLogin oidcScopeId={oidcScopeId} />
      <Link to="/login" className="text-link">
        {t('cancel')}
      </Link>
    </div>
  );
}
