interface IApplicationOptions {
  baseUrl: string;
  theme: string;
  themeLayout: string;
}
declare global {
  interface Window {
    applicationOptions: IApplicationOptions;
  }
}

const overridden = {
  baseUrl: undefined as string | undefined,
  theme: undefined as string | undefined,
} as IApplicationOptions;

class ApplicationOptions implements IApplicationOptions {
  private defaults = {
    baseUrl: 'https://demomaklaren.boostad.net/',
    //baseUrl: 'https://ip-prd-customer-web.azurewebsites.net/',
    //baseUrl: 'https://localhost:5001/',
    //baseUrl: 'https://maklaren.boostad.net/',
    //theme: 'svenska-maklarhuset', //'demomaklaren-homepage-layout',
    theme: 'demomaklaren',
  };

  private cleanOptions(options: IApplicationOptions): IApplicationOptions {
    if (process.env.JEST_WORKER_ID === undefined) {
      for (const key of Object.getOwnPropertyNames(options)) {
        // eslint-disable-next-line
        // @ts-ignore
        const value = options[key];

        if (typeof value === 'undefined') {
          // eslint-disable-next-line
          // @ts-ignore
          delete options[key];
        }
      }
    }
    return options;
  }

  private get cleanedWindowOptions(): IApplicationOptions {
    return this.cleanOptions(window.applicationOptions);
  }

  private get cleanedOverriddenOptions(): IApplicationOptions {
    return this.cleanOptions(overridden);
  }

  private get applicationOptions(): IApplicationOptions {
    return {
      ...this.defaults,
      ...this.cleanedWindowOptions,
      ...this.cleanedOverriddenOptions,
    } as IApplicationOptions;
  }

  get baseUrl(): string {
    return this.applicationOptions.baseUrl;
  }

  get theme(): string {
    return this.applicationOptions.theme;
  }

  get onlyViewsPerChannel(): boolean {
    return this.applicationOptions.theme === 'svenska-maklarhuset';
  }

  get themeLayout(): string {
    switch (this.applicationOptions.theme) {
      case 'svenska-maklarhuset': {
        return 'HomePageLayout';
      }
      case 'demomaklaren-homepage-layout': {
        return 'HomePageLayout';
      }
      case 'demomaklaren-new': {
        return 'HomePageLayout';
      }
      case 'bjurfors': {
        return 'HomePageLayout';
      }
      case 'erik-olsson': {
        return 'HomePageLayout';
      }
      case 'fastighetsmaklarna': {
        return 'HomePageLayout';
      }
      default: {
        return 'Default';
      }
    }
  }
}

export function setTheme(theme: string): void {
  overridden.theme = theme;
  applicationOptions = new ApplicationOptions();
}

let applicationOptions = new ApplicationOptions();
export default applicationOptions;
