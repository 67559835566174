import { useTranslation } from 'react-i18next';
import TaxInformationItem from './TaxInformationItem';
import { swedishCurrencyFormatter, formatSwedishDate } from '../../utils/locale-utils';

interface Props {
  incomeYear: number | undefined;
  taxationYear: number | undefined;
  sellDate: string | undefined;
  sellPrice: number | undefined;
  brokerCommission: number | undefined;
  entryDate: string | undefined;
  housingCooperativeName?: string;
  housingCooperativeDesignation?: string;
}

export default function TaxInformation({
  incomeYear,
  taxationYear,
  sellDate,
  sellPrice,
  brokerCommission,
  entryDate,
  housingCooperativeName,
  housingCooperativeDesignation,
}: Props): JSX.Element {
  const { t } = useTranslation('tax-information');
  //temporary fix, if no data remove placeholder data potential error if comission really is 0
  return (
    <div className="tax-information" data-testid="tax-information">
      <h2 className="component-heading">{t('title')}</h2>
      <div className="row">
        <TaxInformationItem
          title={t('income-year')}
          info={incomeYear != null ? (incomeYear === 1900 ? '' : incomeYear.toString()) : t('data-error')}
        />
        <TaxInformationItem
          title={t('taxation-year')}
          info={taxationYear != null ? (taxationYear === 1900 ? '' : taxationYear.toString()) : t('data-error')}
        />
      </div>
      <div className="row">
        <TaxInformationItem
          title={t('sell-date')}
          info={
            sellDate != null
              ? (formatSwedishDate(sellDate, 'short') === '1-01-01' ? '' : formatSwedishDate(sellDate, 'short')) ?? t('data-error')
              : t('data-error')
          }
        />
        <TaxInformationItem
          title={t('sell-price')}
          info={sellPrice != null ? (sellPrice === 0 ? '' : swedishCurrencyFormatter.format(sellPrice)) : t('data-error')}
        />
      </div>
      <div className="row">
        <TaxInformationItem
          title={t('broker-commission')}
          info={
            brokerCommission != null ? (brokerCommission === 0 ? '' : swedishCurrencyFormatter.format(brokerCommission)) : t('data-error')
          }
        />
        <TaxInformationItem
          title={t('entry-date')}
          info={
            entryDate != null
              ? (formatSwedishDate(entryDate, 'short') === '1-01-01' ? '' : formatSwedishDate(entryDate, 'short')) ?? t('data-error')
              : t('data-error')
          }
        />
      </div>
      {housingCooperativeName && housingCooperativeDesignation && (
        <div className="row">
          <TaxInformationItem title={t('housing-cooperative')} info={housingCooperativeName} />
          <TaxInformationItem title={t('housing-cooperative-designation')} info={housingCooperativeDesignation} />
        </div>
      )}
    </div>
  );
}
