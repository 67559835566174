import { useTranslation } from 'react-i18next';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import { EstatePrice } from '../../services/generated-api-client';
import PriceDifference from './PriceDifference';

interface Props {
  price: EstatePrice;
  showPricePerSquareMeter?: boolean;
}

export default function PriceInfo({ price, showPricePerSquareMeter = false }: Props): JSX.Element {
  const { t } = useTranslation('price');

  const getExtraInfo = (): string | null => {
    if (showPricePerSquareMeter) {
      return `${swedishCurrencyFormatter.format(price.PricePerSquareMeter!)}${t('square-meter')}`;
    }
    return `${t('price-statuses.Startingprice')} ${swedishCurrencyFormatter.format(price.StartingPrice!)}`;
  };

  return (
    <div className="price-info">
      {!!price.PriceStatus && <div className="status">{t(`price-statuses.${price.PriceStatus}`)}</div>}

      <div className="price">{swedishCurrencyFormatter.format(price.Price!)}</div>

      <div className="extra-info">
        {getExtraInfo()} {!!price.DiffPercent && <PriceDifference difference={price.DiffPercent} />}
      </div>
    </div>
  );
}
