import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import TextInput from '../../components/Form/TextInput';
import List from '../../components/List/List';
import ApiClient from '../../services/api-client';
import { BiddingLoanCommitmentInfo } from '../../services/generated-api-client';
import FullscreenPage from '../FullscreenPage/FullscreenPage';
import BankRadioInput from './BankRadioInput';
import SPContact from './SPContact';

const SPName = 'Söderberg & Partners Bolån';
const banks = [
  { name: SPName, image: '/images/sp-logotype.svg' },
  { name: 'SEB' },
  { name: 'Handelsbanken' },
  { name: 'Swedbank' },
  { name: 'Nordea' },
  { name: 'Danske Bank' },
  { name: 'Länsförsäkringar' },
  { name: 'SBAB!' },
  { name: 'Skandiabanken' },
];

export default function ChooseLoanCommitmentBank(): JSX.Element {
  const { t, i18n } = useTranslation('loan-commitment');
  const [bankChoice, setBankChoice] = useState<string>('');
  const [otherBank, setOtherBank] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [contactElement, setContactElement] = useState<JSX.Element | null>(null);
  const { estateId } = useParams();
  const backUrl = `/bidding/${estateId}`;
  const navigate = useNavigate();

  // currentChoice search parameter is used to display page with "change choice" options
  const [searchParams] = useSearchParams();
  const currentChoice = searchParams.get('currentChoice');

  useEffect(() => {
    if (currentChoice) {
      const currentChoiceIsPredefinedBank = banks.filter((bank) => bank.name === currentChoice).length > 0;
      if (
        !currentChoiceIsPredefinedBank &&
        t('choose-bank.no-loan-commitment') !== 'choose-bank.no-loan-commitment' && // Check whether translation has loaded, needed for marking radio button "No loan commitment"
        currentChoice !== t('choose-bank.no-loan-commitment')
      ) {
        setOtherBank(true);
      }

      setBankChoice(currentChoice);
    }
  }, [currentChoice, t, i18n]);

  const submitBank = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const loanCommitmentInfo: BiddingLoanCommitmentInfo = {
      HasLoanCommitment: bankChoice !== t('choose-bank.no-loan-commitment'),
      LoanCommitmentBank: bankChoice,
    };

    setSubmitError(false);
    setSubmitting(true);
    ApiClient.authenticated
      .upsertLoanCommitmentInfo(estateId!, loanCommitmentInfo, {
        cancelToken: 'upsertLoanCommitmentInfo',
      })
      .then((lc) => {
        if (lc.data.CanBeContacted && lc.data.ToBeContacted === null && bankChoice !== SPName) {
          setContactElement(<SPContact loanCommitmentInfo={loanCommitmentInfo} navigateUrl={backUrl} />);
        } else {
          navigate(backUrl);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.name === 'AbortError') {
          return;
        }
        setSubmitError(true);
        console.error(error);
      });
  };

  const handleBankRadioChange = (bankName: string): void => {
    if (otherBank) setOtherBank(false);

    setBankChoice(bankName);
  };

  const handleOtherBankRadioChange = (): void => {
    setOtherBank(true);
    setBankChoice('');
  };

  const currentChoiceIsOtherBank = (): boolean => {
    return (
      currentChoice != null &&
      bankChoice !== t('choose-bank.no-loan-commitment') &&
      banks.filter((bank) => bank.name === bankChoice).length === 0
    );
  };

  return (
    <FullscreenPage
      title={currentChoice ? t('choose-bank.change-choice-page-title') : t('choose-bank.page-title')}
      className="choose-loan-commitment-bank"
      backUrl={backUrl}
    >
      {contactElement ?? (
        <>
          <h2>{t('choose-bank.heading')}</h2>
          <p>{t('choose-bank.description')}</p>

          {currentChoice && <span className="current-choice">{t('choose-bank.current-choice-text', { currentChoice })}</span>}

          <form onSubmit={submitBank}>
            <List>
              {currentChoice && (
                <BankRadioInput
                  key={'has-no-loan-promise'}
                  label={t('choose-bank.no-loan-commitment')}
                  onChange={(): void => handleBankRadioChange(t('choose-bank.no-loan-commitment'))}
                  checked={!otherBank && bankChoice === t('choose-bank.no-loan-commitment')}
                />
              )}
              {banks.map((bank) => (
                <BankRadioInput
                  key={bank.name}
                  label={bank.name}
                  onChange={(): void => handleBankRadioChange(bank.name)}
                  image={bank.image}
                  checked={!otherBank && bankChoice === bank.name}
                />
              ))}
              <div key={'other-bank'}>
                <BankRadioInput
                  label={t('choose-bank.other-bank')}
                  onChange={(): void => handleOtherBankRadioChange()}
                  checked={otherBank || currentChoiceIsOtherBank()}
                />
                {otherBank && (
                  <TextInput
                    label={t('choose-bank.other-bank-input-label')}
                    name={'other-bank'}
                    onChange={(inputValue): void => setBankChoice(inputValue)}
                    value={bankChoice}
                    required={true}
                  />
                )}
              </div>
            </List>

            {submitError && <Alert type="error" text={t('choose-bank.submit-error')} />}
            <div className="buttons">
              <Link to={backUrl} onClick={(): void => ApiClient.abortRequest('upsertLoanCommitmentInfo')} data-testid="cancel">
                {t('choose-bank.cancel')}
              </Link>
              <button type="submit" disabled={bankChoice === '' || submitting} data-testid="submit-button">
                {t('choose-bank.accept')}
              </button>
            </div>
          </form>
        </>
      )}
    </FullscreenPage>
  );
}
