import securityService from '../../services/security-service';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import { useTranslation } from 'react-i18next';
import ApplicationOptions from './../../utils/options';
import { useEffect, useState } from 'react';

interface Props {
  estateAddress: string | undefined;
  estateArea: string | undefined;
  estateCity: string | undefined;
  estatePrice: number | undefined;
  priceText: string | undefined;
  linkUrl?: string | undefined;
}

export default function HeaderHP({ estateAddress, estateArea, estateCity, estatePrice, priceText, linkUrl }: Props): JSX.Element {
  const { t } = useTranslation('estate-info');
  const { t: tPrice } = useTranslation('price');

  const getName = (): string | null => {
    return securityService.getJwtName();
  };
  //remove custom-menu in mobile view
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = (): void => {
      // Specify the return type void
      setMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="homePage-header" data-testid="homePage-header">
        <div className="custom-layout">
          <div>
            <span className="page-title">Mina sidor</span>
            <br />
            <span className="user-name"> {getName()}</span>
            <span className="estate-info">{estateAddress}</span> <span className="estateArea"> {estateArea}</span>
          </div>
          {ApplicationOptions.theme === 'fastighetsmaklarna' && !mobileView && <img className="extra-logo" alt="logotype" />}
          {estatePrice !== undefined ? (
            <div className="estate-pricing2">
              <span className="starting-price"> {priceText ? tPrice('price-statuses.' + priceText) : tPrice('default-price-text')} </span>{' '}
              <br />
              <span className="estate-price" data-testid="estatePrice">
                {estatePrice !== undefined ? swedishCurrencyFormatter.format(estatePrice) : t('no-price-set')}
              </span>
            </div>
          ) : (
            <div className="empty-column"></div>
          )}
        </div>
      </div>
    </>
  );
}
