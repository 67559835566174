import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ChecklistChecklist } from '../../services/generated-api-client';
import ChecklistItem from '../../components/ChecklistItem/ChecklistItem';
import FullscreenPage from '../FullscreenPage/FullscreenPage';
import List from '../../components/List/List';
import ApiClient from '../../services/api-client';
import { useTranslation } from 'react-i18next';
import ChecklistAddLink from '../../components/ChecklistAddLink/ChecklistAddLink';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';

export default function Checklist(): JSX.Element {
  const { view, estateId, checklistId } = useParams();

  const [searchParams] = useSearchParams();
  const returnToStep = searchParams.get('returnToStep');
  const backUrl = returnToStep ? `/${view}/${estateId}/process/${returnToStep}` : `/${view}/${estateId}/checklists`;

  const [checklist, setChecklist] = useState<ChecklistChecklist>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('checklist');

  useEffect(() => {
    ApiClient.authenticated
      .getChecklist(estateId!, checklistId!, { cancelToken: 'getChecklist' })
      .then((response) => setChecklist(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getChecklist');
    };
  }, [checklistId, estateId]);

  const updateItemCallback = (itemId: string, status: boolean): void => {
    ApiClient.authenticated.setChecklistItemState(estateId!, checklistId!, itemId, status).catch((e) => {
      console.log('Failed to update checklist item state', e);
    });
  };

  const getChecklist = (): JSX.Element | null => {
    if (checklist?.Items == null || checklist.Items.length === 0) return null;

    return (
      <List title={t('list-title')} useScroll={true}>
        {checklist.Items.map((checklistItem, i) => {
          return (
            <ChecklistItem
              key={i}
              id={checklistItem.Uid!}
              title={checklistItem.Title}
              description={checklistItem.Description}
              link={checklistItem.Link}
              completed={checklistItem.Completed}
              updateItemStatusCallback={updateItemCallback}
            />
          );
        })}
      </List>
    );
  };

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
        </>
      }
    >
      <FullscreenPage title={checklist?.Name ?? ''} className="checklist" backUrl={backUrl}>
        {checklist && (
          <>
            {checklist.Title && <h2>{checklist.Title}</h2>}
            {checklist.Description && <p>{checklist.Description}</p>}

            {getChecklist()}
          </>
        )}
        <ChecklistAddLink />
      </FullscreenPage>
    </LoaderContainer>
  );
}
