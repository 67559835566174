import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';
import './i18n';
import App from './App';
import { setBaseUrl, setAccessToken } from './services/api-client';
import applicationOptions from './utils/options';
import securityService from './services/security-service';
//kommentera bort innan check-in
//import startMockApi from './mock-api';

// Set baseUrl for ApiClient
setBaseUrl(applicationOptions.baseUrl);

// Set theme class on body
document.body.classList.add(applicationOptions.theme);

securityService.checkForAuthenticationCallback();

const accessToken = securityService.getValidAccessToken();
if (!!accessToken) {
  setAccessToken(accessToken);
}

//kommentera bort innan check-in
//startMockApi(false);

const rootContainer = document.getElementById('root');
if (!rootContainer) {
  throw new Error('Root container not found.');
}
//  <BrowserRouter basename="/demomaklaren">
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
