import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from './add.svg';

export default function ChecklistAddLink(): JSX.Element {
  const { t } = useTranslation('checklist-add-link');

  return (
    <Link to="add-item" className="checklist-add-link">
      <div className="add">
        <AddIcon role="presentation" />
        <span className="add-text"> {t('add')} </span>
      </div>
    </Link>
  );
}
