import { useTranslation } from 'react-i18next';
import { EstatePrice } from '../../services/generated-api-client';
import PriceInfo from '../Price/PriceInfo';

interface Props {
  priceInfo: EstatePrice;
  biddingEnded: boolean;
}

export default function LeadingBid({ priceInfo, biddingEnded }: Props): JSX.Element {
  const { t } = useTranslation('bidding');

  return (
    <div className="leading-bid">
      <PriceInfo price={priceInfo} />
      <div className="leading-text">
        <span className="title">{biddingEnded ? t('won-bidding.title') : t('leading-title')}</span>
        {biddingEnded && <span className="description">{t('won-bidding.description')}</span>}
      </div>
    </div>
  );
}
