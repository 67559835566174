//import ApplicationOptions from './../../utils/options';
interface Props {
  description: string | undefined;
}

export default function ProcessDescription({ description }: Props): JSX.Element {
  return (
    <div className="step-description" data-testid="step-description">
      <p className="description"> {description} </p>
    </div>
  );
}
