import { useTranslation } from 'react-i18next';
import { BuySellBid } from '../../services/generated-api-client';
import { formatSwedishDate, swedishCurrencyFormatter } from '../../utils/locale-utils';

interface Props {
  bids: BuySellBid[];
  startingPrice: number;
}

export default function BidHistoryList({ bids, startingPrice }: Props): JSX.Element {
  const { t: tPrice } = useTranslation('price');

  return (
    <div className="bid-history-list">
      <ol>
        {bids
          .sort((a, b) => b.Amount! - a.Amount!)
          .map((bid) => (
            <li key={bid.Uid}>
              <span>
                <span className="date">
                  {formatSwedishDate(bid.Date ?? '')} {formatSwedishDate(bid.Date ?? '', 'time')}
                </span>
                <span className="bidder-name">{bid.BidderName}</span>
              </span>
              <span>{swedishCurrencyFormatter.format(bid.Amount!)}</span>
            </li>
          ))}
        <li>
          <span>{tPrice('price-statuses.Startingprice')}</span>
          <span>{swedishCurrencyFormatter.format(startingPrice)}</span>
        </li>
      </ol>
    </div>
  );
}
