import Viewing from '../Viewing/Viewing';
import { BuySellViewing } from '../../services/generated-api-client';
import List from '../List/List';

interface Props {
  viewings: BuySellViewing[];
  title: string;
}

export default function ViewingList({ title, viewings }: Props): JSX.Element {
  return (
    <List title={title}>
      {viewings
        .slice()
        .reverse()
        .map(
          (
            viewing, // Make a copy of the array, reverse it, and then map - old code: {viewings.map((viewing) => (
          ) => (
            // TODO 2022-02-18 Markus - Until it's decided how to do error handling in RMS-219
            <Viewing
              key={viewing.Uid}
              viewingType={viewing.ViewingType ?? 'DateAndTime'}
              comment={viewing.Comment}
              startDate={viewing.StartDate}
              endDate={viewing.EndDate}
              bookings={viewing.NumberOfBookings ?? 0}
              visitors={viewing.NumberOfVisitors ?? 0}
            />
          ),
        )}
    </List>
  );
}
