import { useEffect, useState } from 'react';
import StepDescription from '../../components/StepDescription/StepDescription';
import ChecklistList from '../../components/Lists/ChecklistList';
import DownPaymentStatus from '../../components/DownPaymentStatus/DownPaymentStatus';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard';
import Error from '../Error/ErrorPage';
import ApiClient from '../../services/api-client';
import { BuySellBuyBuyDownPaymentView } from '../../services/generated-api-client';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DocumentList from '../../components/Lists/DocumentList';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';

export default function BuyDownPaymentPage(): JSX.Element {
  const { t } = useTranslation('down-payment-page');
  const { t: tProcess } = useTranslation('process');
  const [content, setContent] = useState<BuySellBuyBuyDownPaymentView>();
  const [loading, setLoading] = useState<boolean>(true);
  const { estateId } = useParams();
  const step = 'DownPayment';

  useEffect(() => {
    ApiClient.authenticated
      .getBuyDownPaymentView(estateId!, {
        cancelToken: 'getBuyDownPaymentView',
      })
      .then((response) => setContent(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getBuyDownPaymentView');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.DownPayment')}</h1>

          <div className="process-content">
            {!!content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            {!!content.AmountToPay && <CopyToClipboard title={t('to-pay')} text={swedishCurrencyFormatter.format(content.AmountToPay)} />}

            <div className="bank-info">
              {!!content.Bank && <CopyToClipboard title={t('bank')} text={content.Bank} />}

              {!!content.ClearingNumber && <CopyToClipboard title={t('clearing-number')} text={content.ClearingNumber} />}

              {!!content.AccountNumber && <CopyToClipboard title={t('account-number')} text={content.AccountNumber} />}

              {!!content.Bankgiro && !!content.BankgiroType && (
                <CopyToClipboard title={t(content.BankgiroType.toLowerCase())} text={content.Bankgiro} />
              )}
            </div>

            {!!content.LastDepositDate ||
            !!content.LastPayoutDate ||
            !!content.IsDownPaymentDeposited ||
            !!content.IsDownPaymentPaidOut ||
            !!content.IsMembershipApproved ? (
              <>
                <h2 className="component-heading"> {t('down-payment-status')} </h2>
                <DownPaymentStatus
                  isHousingCooperative={true}
                  lastDepositDate={content.LastDepositDate}
                  lastPayoutDate={content.LastPayoutDate}
                  isDownPaymentDeposited={content.IsDownPaymentDeposited}
                  isDownPaymentPaidOut={content.IsDownPaymentPaidOut}
                  isMembershipApproved={content.IsMembershipApproved}
                />
              </>
            ) : null}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <Error text={tProcess('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
