import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useSearchParams, useNavigate } from 'react-router-dom';
import securityService from '../../services/security-service';
import ErrorPage from '../Error/ErrorPage';
import LoginOptions from './LoginOptions';
import LoginRemote from './LoginRemote';
import ApiClient from '../../services/api-client';

export default function Login(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [oidcScopeCreated, setOidcScopeCreated] = useState<boolean>(false);
  const [oidcScopeId, setOidcScopeId] = useState<string>('');
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  useEffect(() => {
    if (!oidcScopeCreated) {
      if (securityService.isAuthenticated) {
        navigate('/');
      } else {
        setOidcScopeCreated(true);
        let redir = securityService.navigateToAfterLogin;
        if (redir.endsWith('/login')) redir = redir.replace('/login', '');
        //ApiClient.public.createOidcScope({ redirectUri: securityService.navigateToAfterLogin, userAgent: navigator.userAgent }).then((response) => {
        ApiClient.public
          .createOidcScope({
            redirectUri: redir,
            userAgent: navigator.userAgent,
          })
          .then((response) => {
            setOidcScopeId(response.data);
          })
          .catch((e) => {
            navigate('/error');
          });
      }
    }
  }, [oidcScopeId, searchParams, navigate, oidcScopeCreated]);

  return (
    <>
      {oidcScopeId && (
        <ErrorBoundary fallback={<ErrorPage text={t('login-error-message')} displayHomepageLink={false} />}>
          <Routes>
            <Route index element={<LoginOptions oidcScopeId={oidcScopeId} />} />
            <Route path="login-remote/:oidcScopeId" element={<LoginRemote />} />
            <Route path="/*" element={<LoginOptions oidcScopeId={oidcScopeId} />} />
          </Routes>
        </ErrorBoundary>
      )}
    </>
  );
}
