export function joinUrl(...urlParts: string[]): string {
  return urlParts.join('/').replace(/(https?:\/\/)|(\/)+/g, '$1$2');
}

export function reduceToBaseUrl(url: string): string {
  const urlHasProtocol = /^http[s]?:\/\//.test(url);
  const urlWithProtocol = urlHasProtocol ? url : `https://${url}`;
  return new URL(urlWithProtocol).origin;
}

export function removeSearchParamFromUrl(searchParam: string): void {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(searchParam);
  window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
}
