import React, { FunctionComponent, useState } from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs, UISchemaElement, Scopable, JsonSchema, JsonSchema4 } from '@jsonforms/core';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { JsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';
import ChecklistItem from '../../components/ChecklistItem/ChecklistItem';

export const formMspxCapitalGainSellersTester: RankedTester = rankWith(10, uiTypeIs('Mspx_CapitalGainSellers'));

export const FormMspxCapitalGainSellersRenderer = (props: LayoutProps & VanillaRendererProps): JSX.Element => {
  const { data: _data, ...otherProps } = props;
  // We don't hand over data to the layout renderer to avoid rerendering it with every data change
  return <MspxMspxCapitalGainSellers {...otherProps} />;
};

interface ImprovementControlProps {
  idx: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seller: any;
  t: (key: string) => string;
  uischema: UISchemaElement;
  schema: JsonSchema;
}

const SellerRenderer = ({ idx, seller, t, uischema, schema }: ImprovementControlProps): JSX.Element => {
  const { renderers, cells, core } = useJsonForms();
  const [checked, setChecked] = useState<boolean>(GetCheckedState(core?.data, idx));

  let name = 'Okänd';
  const contactDetails = seller.contactDetails;
  if (contactDetails) {
    name = contactDetails.firstName + ' ' + contactDetails.lastName;
  }

  schema = navigate(schema, 'capitalGainCalculation');
  schema = navigate(schema, 'sellerData');
  schema = navigate(schema, '0');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function GetCheckedState(data: any, idx: number): boolean | (() => boolean) {
    const x = data?.capitalGainCalculation?.sellerData[idx]?.reversalInformation;
    return x?.acquiredNewHomeBeforeSale || x?.beenLiving3OfLast5YearsBeforeSale || x?.beenLivingOneYearPrecedingSale;
  }

  function navigate(schema: JsonSchema, property: string): JsonSchema {
    if (schema.properties) {
      return schema.properties[property];
    }
    if (schema.allOf) {
      return schema.allOf.map((a) => navigate(a, property)).filter((o) => o !== schema)[0];
    }
    if (schema.items) {
      const x1 = schema.items as JsonSchema4;
      if (x1) return x1;
      const x2 = schema.items as JsonSchema4[];
      if (x2) return x2[parseInt(property)];
    }
    return schema;
  }

  function sendK5K6Callback(itemId: string, status: boolean): void {
    setChecked(status);
  }

  return (
    <>
      <div className="control">
        <div className="capital-gain-name">{name}</div>
        <div className="capital-gain-send">
          <ChecklistItem
            id="send-k5-k6"
            title={t('CAPITAL_GAIN_SEND_K5_K6')}
            description=""
            completed={checked}
            updateItemStatusCallback={sendK5K6Callback}
          />
        </div>
      </div>
      {checked && (
        <div className="capital-gain">
          <JsonFormsDispatch
            renderers={renderers}
            cells={cells}
            uischema={uischema}
            schema={schema}
            path={'capitalGainCalculation.sellerData.' + idx}
            enabled={true}
          />
        </div>
      )}
    </>
  );
};

const MspxMspxCapitalGainSellers: FunctionComponent<LayoutProps & VanillaRendererProps> = React.memo(function GroupLayoutRendererComponent({
  schema,
  uischema,
  path,
  enabled,
  visible,
  label,
  getStyle,
  getStyleAsClassName,
}: LayoutProps & VanillaRendererProps) {
  const { i18n, core } = useJsonForms();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sellers = (core?.data?.sellers ?? []) as any[];
  const capitalGainSellerSchema = uischema.options!['detail'] as UISchemaElement & Scopable;

  function t(key: string): string {
    return i18n!.translate!(key) ?? key;
  }

  return (
    <>
      {sellers.map((seller, idx) => (
        <SellerRenderer idx={idx} seller={seller} t={t} uischema={capitalGainSellerSchema} schema={schema} />
      ))}
    </>
  );
});

export default withVanillaControlProps(withJsonFormsLayoutProps(FormMspxCapitalGainSellersRenderer));
