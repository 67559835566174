import { useTranslation } from 'react-i18next';
import Alert from '../Alert/Alert';
import LoaderContainer, { ImageBoxLoader } from '../Loader';
import useRemoteLogin from '../../utils/useRemoteLogin';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

interface Props {
  oidcScopeId: string;
}

export default function QR({ oidcScopeId }: Props): JSX.Element {
  const { t } = useTranslation('login');
  const [qrCode, setQrCode] = useState<string>('');
  const { startLogin, loginStarted, authenticationFailed } = useRemoteLogin(oidcScopeId, (qrCode) => {
    setQrCode(qrCode);
  });

  useEffect(() => {
    if (!loginStarted) {
      if (qrCode === '') {
        startLogin();
      }
    }
  }, [loginStarted, qrCode, startLogin]);

  return (
    <div className="qr-login">
      {authenticationFailed && <Alert type="warning" text={t('qr-login-timeout')} />}
      <LoaderContainer
        loading={qrCode === ''}
        loaders={
          <>
            <ImageBoxLoader />
          </>
        }
      >
        <QRCode id="qr-image" data-testid="qr-image" size={256} value={qrCode} viewBox={`0 0 256 256`} />
        <script></script>
      </LoaderContainer>
      <ol>
        <li>{t('qr-steps.1')}</li>
        <li>{t('qr-steps.2')}</li>
        <li>{t('qr-steps.3')}</li>
      </ol>
    </div>
  );
}
