import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ApiClient from '../../services/api-client';
import { BiddingLoanCommitmentInfo } from '../../services/generated-api-client';
import Alert from '../Alert/Alert';

interface Props {
  chooseBankRoute: string;
  loanCommitment: BiddingLoanCommitmentInfo;
}

export default function LoanCommitment({ chooseBankRoute, loanCommitment }: Props): JSX.Element {
  const { estateId } = useParams();
  const { t } = useTranslation('loan-commitment');
  const [alert, setAlert] = useState<boolean>(false);
  const [loanCommitmentView, setloanCommitmentView] = useState<boolean>(true);
  const navigate = useNavigate();

  const setHasOwnFinancing = (): void => {
    loanCommitment = {
      HasLoanCommitment: true,
      LoanCommitmentBank: 'Egenfinansiering',
      ToBeContacted: false,
    };

    setAlert(false);

    ApiClient.authenticated
      .upsertLoanCommitmentInfo(estateId!, loanCommitment)
      .then(() => navigate(0))
      .catch((e) => {
        console.log('Failed to upsert loan commitment', e);
        setAlert(true);
      });
  };

  const setToBeContacted = (contactUser: boolean): void => {
    loanCommitment = {
      HasLoanCommitment: false,
      LoanCommitmentBank: 'NoCommitment',
      ToBeContacted: contactUser,
    };
    ApiClient.authenticated
      .upsertLoanCommitmentInfo(estateId!, loanCommitment)
      .then(() => navigate(0))
      .catch((e) => {
        console.log('Failed to upsert loan commitment', e);
        setAlert(true);
      });
  };

  return (
    <>
      {alert && <Alert text={t('error')} type="error" />}
      {
        <div className="loan-commitment" aria-live="polite">
          <div className="title"> {loanCommitmentView ? t('loan-commitment-title') : t('want-to-be-contacted-title')} </div>
          <div className="button-container">
            <button className={loanCommitmentView ? 'main-button' : 'hidden'} onClick={(): void => navigate(chooseBankRoute)}>
              {' '}
              {t('yes-choose-bank')}{' '}
            </button>
            <button
              className={loanCommitmentView ? 'main-button' : 'hidden'}
              onClick={(): void => setloanCommitmentView(false)}
              data-testid="no-button"
            >
              {' '}
              {t('no')}{' '}
            </button>
            <button className={!loanCommitmentView ? 'main-button' : 'hidden'} onClick={(): void => setToBeContacted(true)}>
              {' '}
              {t('yes')}{' '}
            </button>
            <button className={!loanCommitmentView ? 'main-button' : 'hidden'} onClick={(): void => setToBeContacted(false)}>
              {' '}
              {t('no')}{' '}
            </button>
          </div>
          {loanCommitmentView && (
            <button className="no-loan-button" onClick={(e): void => setHasOwnFinancing()} data-testid="no-loan-button">
              {' '}
              {t('no-loan-commitment-text')}{' '}
            </button>
          )}
        </div>
      }
    </>
  );
}
