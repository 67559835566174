import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';
import securityService from '../../services/security-service';
import Login from '../Login/Login';
import LoginCallback from '../Login/LoginCallback';
import ApplicationOptions from '../../utils/options';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import FooterSMH from '../../components/Footers/FooterSMH';
import BackendErrorPage from '../Error/BackendErrorPage';
import ErrorPage from '../Error/ErrorPage';

export default function StartPage(): JSX.Element {
  const { t } = useTranslation('start-page');

  securityService.setNavigateToAfterLogin(document.location.href);

  return (
    <div className="start-page">
      {ApplicationOptions.themeLayout === 'HomePageLayout' ? (
        <TopNavBar hasNewNotifications={false} hideIcons={true} />
      ) : (
        <header className="content">
          <Link to="/">
            <img className="logo" alt={t('logo-link-text')} />
          </Link>
        </header>
      )}
      <div className="content">
        <main>
          <Routes>
            <Route index element={<Login />} />
            <Route path="error" element={<ErrorPage text={t('error')} />} />
            <Route path="error/:estateUid/:errorCode" element={<BackendErrorPage />} />
            <Route path="login/*" element={<Login />} />
            <Route path="login-callback/:oidcScopeId" element={<LoginCallback />} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </main>
      </div>
      {ApplicationOptions.theme === 'svenska-maklarhuset' ? <FooterSMH /> : ''}
    </div>
  );
}
