import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  ChartData,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { BuySellMarketingChannelImpressions } from '../../../services/generated-api-client';
import { swedishNumberFormatter } from '../../../utils/locale-utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

interface ChannelImpressionsChart {
  getChartOptions: () => _DeepPartialObject<
    CoreChartOptions<'bar'> & ElementChartOptions<'bar'> & PluginChartOptions<'bar'> & DatasetChartOptions<'bar'> & ScaleChartOptions<'bar'>
  >;
  getChartData: (channelImpressions: BuySellMarketingChannelImpressions[], addPercentValue: boolean) => ChartData<'bar', number[], unknown>;
}

//Calculate the percentage of views for each channel
function calculatePercentValue(value: number | undefined, channelValues: (number | undefined)[]): string {
  const total = channelValues.reduce((a, v) => (a ?? 0) + (v ?? 0));
  return ' (' + (value ? ((value / (total ?? 1)) * 100).toFixed(0) : '0') + '%)';
}

export default function useImpressionsPerChannel(): ChannelImpressionsChart {
  const getChartData = (
    channelImpressions: BuySellMarketingChannelImpressions[],
    addPercentValue: boolean,
  ): ChartData<'bar', number[], unknown> => {
    const channelValues = channelImpressions.map((channelImpression) => {
      return channelImpression.Value ?? 0;
    });
    //const channelNames = channelImpressions.map((channelImpression) => { return [channelImpression.Name, swedishNumberFormatter.format(channelImpression.Value ?? 0)]; });
    const channelNames = channelImpressions.map((channelImpression) => {
      return [
        channelImpression.Name,
        swedishNumberFormatter.format(channelImpression.Value ?? 0) +
          (addPercentValue ? calculatePercentValue(channelImpression.Value, channelValues) : ''),
      ];
    });

    const style = getComputedStyle(document.body);

    const data: ChartData<'bar', number[], unknown> = {
      labels: channelNames,
      datasets: [
        {
          data: channelValues,
          backgroundColor: style.getPropertyValue('--impressions-per-channel-data-color'),
          barThickness: 5,
          borderRadius: 5,
        },
      ],
    };
    return data;
  };

  const getChartOptions = (): _DeepPartialObject<
    CoreChartOptions<'bar'> & ElementChartOptions<'bar'> & PluginChartOptions<'bar'> & DatasetChartOptions<'bar'> & ScaleChartOptions<'bar'>
  > => {
    const style = getComputedStyle(document.body);
    return {
      indexAxis: 'y',
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            autoSkip: false,
            color: style.getPropertyValue('--chart-legend-label-color'),
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  };

  return { getChartData, getChartOptions };
}
