import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../services/api-client';
import { BuySellRecommendation } from '../../services/generated-api-client';
import TextInput from '../Form/TextInput';

interface Props {
  title?: string;
  description?: string;
}

export default function Recommendation({ title, description }: Props): JSX.Element {
  const { estateId } = useParams();
  const { t } = useTranslation('recommendation');
  const [errors, setErrors] = useState<string[]>([]);
  const [recommendation, setRecommendation] = useState<BuySellRecommendation>({
    Name: '',
    Email: '',
    Phone: '',
  });
  const [success, setSuccess] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  const sendRecommendation = (recommendation: BuySellRecommendation): void => {
    ApiClient.authenticated
      .sendRecommendation({ ...recommendation, EstateUid: estateId })
      .then(() => {
        setSuccess(true);
        setDisableForm(false);
        setRecommendation({ Name: '', Email: '', Phone: '' });
      })
      .catch((e) => {
        console.log('Failed to send recommendation', e);
        setErrors([t('failed-to-send')]);
        setDisableForm(false);
      });
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setDisableForm(true);
      sendRecommendation(recommendation);
    }
  }

  function handleChange(inputValue: string, inputName: string): void {
    setSuccess(false);
    setRecommendation({ ...recommendation, [inputName]: inputValue });
  }

  function validateForm(): boolean {
    const emailRegex = /^(.+)@(.+)\.(.+)$/;
    const printError = [];

    if (!recommendation.Name) {
      printError.push(t('error-name'));
    }
    if (!recommendation.Phone) {
      printError.push(t('error-phone-number'));
    }
    if (recommendation.Email) {
      if (!recommendation.Email?.match(emailRegex)) {
        printError.push(t('error-email'));
      }
    }

    setErrors(printError);
    if (printError.length === 0) {
      return true;
    }

    return false;
  }

  return (
    <div>
      <h1 className="component-heading">{t('recommend-title')}</h1>
      <div className="recommend" data-testid="recommend">
        <div className="title"> {title ?? t('title')} </div>
        <p className="description"> {description ?? t('description')} </p>
        {errors &&
          errors.map((error, index) => {
            return (
              <div key={index} className="errors">
                {' '}
                {error}{' '}
              </div>
            );
          })}
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            label={t('name')}
            name="Name"
            value={recommendation.Name!}
            onChange={(inputValue, inputName): void => handleChange(inputValue, inputName)}
            errorMode={errors.includes(t('error-name'))}
            required
          />

          <TextInput
            label={t('phone-number')}
            name="Phone"
            type="tel"
            value={recommendation.Phone!}
            onChange={(inputValue, inputName): void => handleChange(inputValue, inputName)}
            errorMode={errors.includes(t('error-phone-number'))}
            required
          />

          <TextInput
            label={t('email')}
            name="Email"
            type="email"
            value={recommendation.Email!}
            onChange={(inputValue, inputName): void => handleChange(inputValue, inputName)}
            errorMode={errors.includes(t('error-email'))}
          />

          {success && (
            <div className="success" data-testid="success">
              {' '}
              {t('success')}{' '}
            </div>
          )}
          <button disabled={disableForm} type="submit" data-testid="button">
            {' '}
            {t('button-text')}{' '}
          </button>
        </form>
      </div>
    </div>
  );
}
