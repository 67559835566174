import { Children } from 'react';

interface Props {
  title?: string;
  children: React.ReactNode;
  useScroll?: boolean | undefined;
}

export default function List({ title, children, useScroll }: Props): JSX.Element {
  const onSelect = (event: React.MouseEvent<HTMLElement>): void => {
    const i = event.target as HTMLInputElement;
    window.scrollTo({ left: 0, top: i.offsetTop, behavior: 'smooth' });
  };

  return (
    <div className="list" data-testid="list">
      {title && <h2 className="component-heading">{title}</h2>}
      <ul>
        {Children.map(children, (child, i) => (
          <li key={i} data-testid={`list-item-${i}`} onClick={onSelect}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
}
