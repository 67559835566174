import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BidHistoryChart from '../../components/BidHistory/BidHistoryChart';
import AddBid from '../../components/AddBid/AddBid';
import LeadingBid from '../../components/LeadingBidding/LeadingBidding';
import BidList from '../../components/BidHistory/BidList';
import Broker from '../../components/Broker/Broker';
import EstateInfo from '../../components/EstateInfo/EstateInfo';
import TipList from '../../components/Lists/TipList';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import ApiClient from '../../services/api-client';
import { BiddingBiddingView, BuySellBroker } from '../../services/generated-api-client';
import ErrorPage from '../Error/ErrorPage';
import LoanCommitment from '../../components/LoanCommitment/LoanCommitment';
import LoanSummary from '../../components/LoanSummary/LoanSummary';
import BiddingConstants from '../../constants/bidding';
import BiddingStatus from '../../components/BiddingStatus/BiddingStatus';

export default function BiddingPage(): JSX.Element {
  const { estateId } = useParams();
  const { t: tProcess } = useTranslation('process');
  const { t: tError } = useTranslation('error');
  const { t: tBidding } = useTranslation('bidding');
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<BiddingBiddingView>();
  const bidHistoryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ApiClient.authenticated
      .getBiddingView(estateId!, { cancelToken: 'getBiddingView' })
      .then((response) => {
        setContent(response.data);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getBiddingView');
    };
  }, [estateId]);

  const handleViewAllBidsClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    document.getElementById('bid-history')?.scrollIntoView({ behavior: 'smooth' });
  };

  const getBroker = (broker: BuySellBroker): JSX.Element => {
    return <Broker name={broker.Name} title={broker.Title} phoneNumber={broker.Phone} email={broker.Mail} image={broker.ImageUrl} />;
  };

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content?.Estate?.PriceInfo ? (
        <div className="bidding-page" data-testid="bidding-page">
          <EstateInfo
            estateAddress={content.Estate.Address?.StreetAddress}
            linkUrl={content.Estate.EstateLink}
            estateArea={content.Estate.Address?.Area}
            estateCity={content.Estate.Address?.City}
            estatePrice={content.Estate.PriceInfo?.Price}
            priceText={content.Estate.PriceInfo?.PriceStatus}
            showHeader={false}
          />

          <div className="bidding-content-container">
            <h1 className="visually-hidden">{tBidding('bidding-page-title')}</h1>

            <div className="bidding-content">
              <div className="grouped">
                {!!content.Status && !content.HasLeadingBid && (
                  <BiddingStatus status={content.Status} moreEstatesLink={content.MoreEstatesLink} />
                )}

                {content.HasLeadingBid !== null && !!content.HasLeadingBid && (
                  <LeadingBid biddingEnded={content.Status === BiddingConstants.Ended} priceInfo={content.Estate.PriceInfo} />
                )}

                {content.HasLeadingBid !== null && !content.HasLeadingBid && (
                  <AddBid
                    estateAddress={content.Estate.Address}
                    minBidRaiseAmount={content.MinBidRaise ?? 2000}
                    paused={content.Status === BiddingConstants.Paused || content.Status === BiddingConstants.Ended}
                    priceInfo={content.Estate.PriceInfo}
                  />
                )}

                {content.LoanCommitmentInfo?.LoanCommitmentBank == null && content.HasLeadingBid && (
                  <LoanCommitment chooseBankRoute="choose-bank" loanCommitment={content.LoanCommitmentInfo!} />
                )}
                <div className="bids-summary">
                  {!!content.Bids && content.Bids.length > 0 && (
                    <>
                      <BidList
                        bids={content.Bids.sort((a, b) => b.Amount! - a.Amount!).slice(0, 3)}
                        title={tBidding('highest-bids-title')}
                      />
                      <a className="scroll-to-link text-link" href="#bid-history" onClick={handleViewAllBidsClick}>
                        {tBidding('view-all-bids')}
                      </a>
                    </>
                  )}
                </div>
              </div>

              {!!content.Broker && getBroker(content.Broker)}

              {!!content.LoanCommitmentInfo && !!content.LoanCommitmentInfo.LoanCommitmentBank && (
                <LoanSummary
                  loanCommitment={content.LoanCommitmentInfo.LoanCommitmentBank!}
                  route={`choose-bank?currentChoice=${content.LoanCommitmentInfo.LoanCommitmentBank!}`}
                />
              )}

              {!!content.Bids && (
                <div ref={bidHistoryRef} id="bid-history">
                  <h2 className="component-heading">{tBidding('bid-history')}</h2>
                  <div className="grouped">
                    <div className="content-container">
                      <BidHistoryChart
                        bids={content.Bids.sort((b1, b2) => Date.parse(b1.Date!) - Date.parse(b2.Date!))}
                        startingPrice={content.Estate.PriceInfo.StartingPrice!}
                      />
                    </div>
                    <BidList bids={content.Bids} />
                  </div>
                </div>
              )}
            </div>

            <aside>
              {!!content.Broker && getBroker(content.Broker)}

              {!!content.Tips?.filter((tip) => tip.Type === 'Offer').length && (
                <TipList tips={content.Tips.filter((tip) => tip.Type === 'Offer')} textOnTopOfImage />
              )}

              {!!content.Tips?.filter((tip) => tip.Type === 'Tip').length && (
                <TipList tips={content.Tips.filter((tip) => tip.Type === 'Tip')} title={tProcess('tip-title')} />
              )}
            </aside>
          </div>
        </div>
      ) : (
        <ErrorPage text={tError('page-not-found')} />
      )}
    </LoaderContainer>
  );
}
