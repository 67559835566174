import { EffectCallback, useEffect, useState } from 'react';
import LoaderContainer, { ListLoader } from '../../components/Loader';
import ApiClient from '../../services/api-client';
import FullscreenPage from '../FullscreenPage/FullscreenPage';
import Notification from '../../components/Notification/Notification';
import { useTranslation } from 'react-i18next';
import { UserNotification as NotificationType } from '../../services/generated-api-client';
import { publishMessage } from './../../utils/message-hub';

const MS_PER_MINUTE = 60000;
const NOTIFICATION_CHECK_INTERVAL = MS_PER_MINUTE * 0.2;

export default function NotificationsPage(): JSX.Element {
  const { t } = useTranslation('notifications');
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getNotifications = (): void => {
    ApiClient.authenticated
      .getNotifications()
      .then((response) => {
        const notifications = response.data;

        notifications.sort((a, b) => {
          const aDate = new Date(a.Date || 0);
          const bDate = new Date(b.Date || 0);

          return bDate.getTime() - aDate.getTime();
        });

        setNotifications(notifications);
      })
      .then(() => setLoading(false));
  };

  useEffect((): ReturnType<EffectCallback> => {
    getNotifications();

    const intervalHandle = setInterval(() => getNotifications(), NOTIFICATION_CHECK_INTERVAL);

    return (): void => clearInterval(intervalHandle);
  }, []);

  useEffect((): void => {
    const unreadNotifications = notifications.filter((notification) => notification.Read === false);
    if (unreadNotifications.length === 0) {
      return;
    }

    const unreadNotificationIds = unreadNotifications.map((notification) => notification.Uid!);
    ApiClient.authenticated.markNotificationsAsRead(unreadNotificationIds).then(() => publishMessage('notifications-read'));
  }, [notifications]);

  return (
    <FullscreenPage title={t('title')} className="notifications">
      <LoaderContainer
        loading={loading}
        loaders={
          <>
            <ListLoader />
          </>
        }
      >
        {notifications.map((notification) => (
          <Notification
            key={notification.Uid}
            title={notification.Title}
            text={notification.Text}
            date={notification.Date}
            sender={notification.Sender}
            isRead={notification.Read ?? false}
          />
        ))}
      </LoaderContainer>
    </FullscreenPage>
  );
}
