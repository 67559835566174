import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import SecurityService from '../../services/security-service';

export default function LoginRedirect(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Login successful - navigating to ' + SecurityService.navigateToAfterLogin);
    navigate(SecurityService.navigateToAfterLogin);
  }, [navigate]);

  return <></>;
}
