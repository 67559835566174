import { ReactComponent as PhoneIcon } from '../Broker/phone.svg';
import { ReactComponent as MailIcon } from '../Broker/mail.svg';
import { ReactComponent as BuildingIcon } from './building.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as LinkedinIcon } from './linkedin.svg';

export default function FooterSMH(): JSX.Element {
  return (
    <>
      <footer className="newhome container-fluid">
        <section className="container main-footer">
          <div className="row">
            <div className="col col-3">
              <a href="/" className="footer-logo">
                <img
                  width="235"
                  height="271"
                  src="https://svenskamaklarhuset.se/wp-content/themes/smh/images/footer_logo.svg"
                  className="attachment-full size-full"
                  alt=""
                  loading="lazy"
                ></img>
              </a>
            </div>
            <div className="col col-2">
              <h4>Huvudkontoret</h4>

              <ul className="icon-list-items">
                <li className="icon-list-item">
                  <span className="icon-list-icon">
                    <BuildingIcon />
                  </span>
                  <span className="icon-list-text">Gustav III:s Boulevard 42, 169 73 Solna</span>
                </li>

                <li className="icon-list-item">
                  <span className="icon-list-icon">
                    <MailIcon className="mail-icon" />
                  </span>
                  <span className="icon-list-text">
                    <a href="mailto:info@smh.se">info@smh.se</a>{' '}
                  </span>
                </li>

                <li className="icon-list-item">
                  <a href="tel:0200386000">
                    <span className="icon-list-icon">
                      <PhoneIcon className="phone-icon" />
                    </span>
                    <span className="icon-list-text">0200-386 000</span>
                  </a>
                </li>
              </ul>

              <div className="d-flex">
                <div className="grid-item">
                  <a className="social-icon" target="_blank" href="https://www.facebook.com/svenskamaklarhuse" rel="noreferrer">
                    <span>Facebook</span>
                    <FacebookIcon />
                  </a>
                </div>
                <div className="grid-item">
                  <a
                    className="social-icon"
                    target="_blank"
                    href="https://www.youtube.com/channel/UC3wS161SE2x8mpFwHi83T1g"
                    rel="noreferrer"
                  >
                    <span>Youtube</span>
                    <YoutubeIcon />
                  </a>
                </div>
                <div className="grid-item">
                  <a className="social-icon" target="_blank" href="https://www.instagram.com/svenskamaklarhuset/" rel="noreferrer">
                    <span>Instagram</span>
                    <InstagramIcon />
                  </a>
                </div>
                <div className="grid-item">
                  <a className="social-icon" target="_blank" href="https://www.linkedin.com/company/svenska-mäklarhuset" rel="noreferrer">
                    <span>Linkedin</span>
                    <LinkedinIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-3 newhome-footer-menu">
              <h4>Snabblänkar</h4>
              <ul id="menu-nav-footer" className="nav">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-85">
                  <a href="https://svenskamaklarhuset.se/salja/">Sälja</a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-898">
                  <a href="/bobutik/smh-nyproduktion">Nyproduktion</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-896">
                  <a href="https://svenskamaklarhuset.se/utlandsboenden/">Utland</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-622">
                  <a href="https://svenskamaklarhuset.se/kontor/">Kontor</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-87">
                  <a href="https://svenskamaklarhuset.se/om-oss/">Om oss</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-895">
                  <a href="https://svenskamaklarhuset.se/karriar/">Karriär</a>
                </li>
                <li id="menu-item-19311" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19311">
                  <a href="https://svenskamaklarhuset.se/visselblasarpolicy/">Visselblåsarpolicy</a>
                </li>
              </ul>{' '}
            </div>
            <div className="col col-4 subscribe-footer">
              <h4>Nyhetsbrev</h4>
              <p>
                Prenumerera på nyheter, insikter, bostäder och nakna sanningar.{' '}
                <a href="https://svenskamaklarhuset.se/nyhetsbrev/" target="_blank" rel="noreferrer">
                  <strong>Klicka här</strong>
                </a>
                .
              </p>

              <h4>SÄLJSTARTSBREVET</h4>
              <p>
                Få förtur till säljstarter genom att prenumerera på säljstartsbrevet.{' '}
                <a href="https://svensknyproduktion.se/saljstarter/" target="_blank" rel="noreferrer">
                  <strong>Klicka här</strong>
                </a>
                .
              </p>
            </div>
          </div>
        </section>

        {/*      <span className="divider-separator"></span>
        <section className="footer-offices container">

          <div className="footer-offices-container">
            <p>
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/alanya/" target="_blank" rel="noreferrer">Alanya</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/alanya-new-search-form/" target="_blank" rel="noreferrer">Alanya new Search form</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/altea/" target="_blank" rel="noreferrer">Altea</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/annedal-mariehall/" target="_blank" rel="noreferrer">Annedal/Mariehäll</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/barcelona/" target="_blank" rel="noreferrer">Barcelona</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/botkyrka-salem/" target="_blank" rel="noreferrer">Botkyrka/Salem</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/bromma/" target="_blank" rel="noreferrer">Bromma</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/torrevieja/" target="_blank" rel="noreferrer">Costa Blanca</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/cypern/" target="_blank" rel="noreferrer">Cypern</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/essingen/" target="_blank" rel="noreferrer">Essingen</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/farsta/" target="_blank" rel="noreferrer">Farsta/Trångsund/Skogås</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/fuengirola/" target="_blank" rel="noreferrer">Fuengirola</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/grekland/" target="_blank" rel="noreferrer">Grekland</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/goteborg/" target="_blank" rel="noreferrer">Göteborg</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/halmstad/" target="_blank" rel="noreferrer">Halmstad</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/hammarby-sjostad-sickla/" target="_blank" rel="noreferrer">Hammarby Sjöstad/Sickla</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/haninge/" target="_blank" rel="noreferrer">Haninge</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/helsingborg-hoganas/" target="_blank" rel="noreferrer">Helsingborg/Höganäs/Bjuv</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/huddinge/" target="_blank" rel="noreferrer">Huddinge</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/huvudkontoret/" target="_blank" rel="noreferrer">Huvudkontoret</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/hagersten-liljeholmen/" target="_blank" rel="noreferrer">Hägersten/Liljeholmen</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/johanneshov/" target="_blank" rel="noreferrer">Johanneshov</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/jarfalla-jakobsberg/" target="_blank" rel="noreferrer">Järfälla</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/kohlanta/" target="_blank" rel="noreferrer">Koh Lanta</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/kristianstad/" target="_blank" rel="noreferrer">Kristianstad</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/kroatien/" target="_blank" rel="noreferrer">Kroatien</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/kungsholmen/" target="_blank" rel="noreferrer">Kungsholmen</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/landskrona/" target="_blank" rel="noreferrer">Landskrona</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/lidingo/" target="_blank" rel="noreferrer">Lidingö</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/living/" target="_blank" rel="noreferrer">Living</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/malaga/" target="_blank" rel="noreferrer">Malaga</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/mallorca/" target="_blank" rel="noreferrer">Mallorca</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/maklare-skane-halland/" target="_blank" rel="noreferrer">Mäklare i Skåne och Halland</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/maklare-stockholm/" target="_blank" rel="noreferrer">Mäklare i Stockholm</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/nacka-saltsjo-boo/" target="_blank" rel="noreferrer">Nacka/Saltsjö-Boo</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/nerja/" target="_blank" rel="noreferrer">Nerja</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/phuket/" target="_blank" rel="noreferrer">Phuket</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/portugal/" target="_blank" rel="noreferrer">Portugal</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/rayong/" target="_blank" rel="noreferrer">Rayong</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/smh-living/" target="_blank" rel="noreferrer">SMH LIVING</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/sigtuna/" target="_blank" rel="noreferrer">Sigtuna</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/sollentuna/" target="_blank" rel="noreferrer">Sollentuna</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/solna/" target="_blank" rel="noreferrer">Solna</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/sundbyberg/" target="_blank" rel="noreferrer">Sundbyberg</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/smh-nyproduktion/" target="_blank" rel="noreferrer">Svensk Nyproduktion</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/sodermalm/" target="_blank" rel="noreferrer">Södermalm</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/tyreso/" target="_blank" rel="noreferrer">Tyresö</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/taby/" target="_blank" rel="noreferrer">Täby</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/upplands-bro/" target="_blank" rel="noreferrer">Upplands-Bro</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/upplands-vasby/" target="_blank" rel="noreferrer">Upplands Väsby</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/uppsala/" target="_blank" rel="noreferrer">Uppsala</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/vallentuna/" target="_blank" rel="noreferrer">Vallentuna</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/vasastan-norrmalm/" target="_blank" rel="noreferrer">Vasastan/Norrmalm</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/vallingby-hasselby-spanga/" target="_blank" rel="noreferrer">Vällingby/Hässelby/Spånga</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/vasteras/" target="_blank" rel="noreferrer">Västerås</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/alvsjo-bandhagen/" target="_blank" rel="noreferrer">Älvsjö/Bandhagen</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/angelholm-bastad/" target="_blank" rel="noreferrer">Ängelholm/Båstad</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/akersberga-ljustero-roslagen/" target="_blank" rel="noreferrer">Åkersberga/Ljusterö</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/arsta-enskede/" target="_blank" rel="noreferrer">Årsta/Enskede</a>
                                          &nbsp;|&nbsp;
              <a className="item-office text-nowrap" href="https://svenskamaklarhuset.se/kontor/ostermalm-gardet/" target="_blank" rel="noreferrer">Östermalm/Gärdet</a>
            </p>
          </div>
        </section>  */}
      </footer>
    </>
  );
}
