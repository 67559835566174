import { Api } from './generated-api-client';

const apiClient = new Api();

export default apiClient;

export const setBaseUrl = (baseUrl: string): void => {
  // The generated service urls all start with a slash so the
  // base url should not end with one.
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substr(0, baseUrl.length - 1);
  }

  apiClient.baseUrl = baseUrl;
};

export const setAccessToken = (accessToken: string): void => {
  // 2022-01-17 KJ The following ignores are used to set some internals
  // on the generated client. If we want we could adjust the templates
  // used for generation instead but this works for now.

  // eslint-disable-next-line
  // @ts-ignore
  const headers = apiClient.baseApiParams.headers as HeadersInit;
  // eslint-disable-next-line
  // @ts-ignore
  apiClient.baseApiParams.headers = {
    ...headers,
    authorization: `bearer ${accessToken}`,
  };
};
