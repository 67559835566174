import { EffectCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChecklistChecklistStep } from '../../services/generated-api-client';
import ChecklistList from '../../components/Lists/ChecklistList';
import ApiClient from '../../services/api-client';
import { useParams } from 'react-router-dom';
import LoaderContainer, { ListLoader } from '../../components/Loader';
import Alert from '../../components/Alert/Alert';

export default function ChecklistsPage(): JSX.Element {
  const { t } = useTranslation('estate-page');
  const { t: tChecklist } = useTranslation('checklist');
  const { estateId } = useParams();
  const [checklistSteps, setChecklistSteps] = useState<ChecklistChecklistStep[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect((): ReturnType<EffectCallback> => {
    ApiClient.authenticated
      .getChecklistsForEstate(estateId!, {
        cancelToken: 'getChecklistsForEstate',
      })
      .then((response) => setChecklistSteps(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getChecklistsForEstate');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ListLoader />
        </>
      }
    >
      <div className="checklists">
        <div className="inner-content">
          <h1 className="page-heading">{t('checklists-title')}</h1>
          <div className="collection-content">
            {!!checklistSteps.length &&
              checklistSteps.map((checklistStep) => (
                <ChecklistList
                  key={checklistStep.StepName}
                  title={tChecklist(`checklist-titles.${checklistStep.StepName}`)}
                  checklists={checklistStep.Checklists ?? []}
                />
              ))}
          </div>
        </div>
        {!checklistSteps.length && <Alert type="info" text={t('no-checklists')} />}
      </div>
    </LoaderContainer>
  );
}
