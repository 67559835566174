import ApplicationOptions from '../../utils/options';
import StepDescriptionWithImage from '../../components/StepDescription/StepDescriptionWithImage';
interface Props {
  title: string | undefined;
  description: string | undefined;
  step?: string | undefined;
}

export default function ProcessDescription({ title, description, step }: Props): JSX.Element {
  return (
    <>
      {ApplicationOptions.themeLayout === 'HomePageLayout' ? (
        <StepDescriptionWithImage step={step} title={title} description={description} />
      ) : (
        <div className="step-description" data-testid="step-description">
          <span className="title"> {title} </span>
          <p className="description"> {description} </p>
        </div>
      )}
    </>
  );
}
