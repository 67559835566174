import { EffectCallback, useEffect, useState } from 'react';
import { BuySellTip, HomepageHomepageEstate } from '../../services/generated-api-client';
import ApiClient from '../../services/api-client';
import EstateLink from '../../components/EstateLink/EstateLink';
import List from '../../components/List/List';
import { useTranslation } from 'react-i18next';
import LoaderContainer, { HomeLoader, ImageBoxLoader } from '../../components/Loader';
import Views from '../../constants/views';
import BiddingConstants from '../../constants/bidding';
import TipList from '../../components/Lists/TipList';
import ApplicationOptions from '../../utils/options';
import HeaderHP from '../../components/Headers/HeaderHpLayout';
import HeaderDefault from '../../components/Headers/HeaderDefault';

export default function Home(): JSX.Element {
  const { t } = useTranslation('home');
  const [sellEstates, setSellEstates] = useState<HomepageHomepageEstate[]>([]);
  const [buyEstates, setBuyEstates] = useState<HomepageHomepageEstate[]>([]);
  const [biddingEstates, setBiddingEstates] = useState<HomepageHomepageEstate[]>([]);
  const [offers, setOffers] = useState<BuySellTip[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect((): ReturnType<EffectCallback> => {
    ApiClient.authenticated
      .getHomepageData({ cancelToken: 'getHomepageData' })
      .then((response) => {
        setSellEstates(response.data.EstatesToSell ?? []);
        setBuyEstates(response.data.EstatesToBuy ?? []);
        setBiddingEstates(response.data.EstatesToBidOn ?? []);
        setOffers(response.data.Offers ?? []);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }

        throw e;
      });

    return (): void => {
      ApiClient.abortRequest('getHomepageData');
    };
  }, []);

  function getRoute(estate: HomepageHomepageEstate, view: 'sell' | 'buy' | 'bidding'): string {
    if (view === 'bidding') {
      return `/${BiddingConstants.BiddingPath}/${estate.Uid}`;
    }

    const processPath = estate.EstateStep ? `/process/${estate.EstateStep}` : '';
    return `/${view === 'sell' ? Views.Sell : Views.Buy}/${estate.Uid}${processPath}`;
  }

  function getEstateLink(estate: HomepageHomepageEstate, view: 'sell' | 'buy' | 'bidding'): JSX.Element | null {
    return (
      <EstateLink
        key={estate.Uid}
        route={getRoute(estate, view)}
        address={estate.Address}
        date={estate.Sold}
        image={estate.Image}
        price={estate.PriceInfo?.Price}
        priceDifference={estate.PriceInfo?.DiffPercent}
        priceStatus={estate.PriceInfo?.PriceStatus}
      />
    );
  }

  return (
    <div className="home">
      {ApplicationOptions.themeLayout === 'HomePageLayout' && (
        <HeaderHP estateAddress={''} linkUrl={''} estateArea={''} estateCity={''} estatePrice={undefined} priceText={''} />
      )}
      {ApplicationOptions.themeLayout === 'Default' && <HeaderDefault />}
      <div className="home-page">
        <div className="estates">
          <LoaderContainer
            loading={loading}
            loaders={
              <>
                <HomeLoader />
              </>
            }
          >
            {sellEstates.length > 0 && (
              <List title={t('sell-list-title')}>{sellEstates.map((estate) => getEstateLink(estate, 'sell'))}</List>
            )}
            {buyEstates.length > 0 && <List title={t('buy-list-title')}>{buyEstates.map((estate) => getEstateLink(estate, 'buy'))}</List>}
            {biddingEstates.length > 0 && (
              <List title={t('bidding-list-title')}>{biddingEstates.map((estate) => getEstateLink(estate, 'bidding'))}</List>
            )}
            {sellEstates.length === 0 && buyEstates.length === 0 && biddingEstates.length === 0 && (
              <div className="no-estates-available">
                <p>{t('no-estates-available-text')}</p>
              </div>
            )}
          </LoaderContainer>
        </div>
        <LoaderContainer
          loading={loading}
          loaders={
            <>
              <ImageBoxLoader />
            </>
          }
        >
          {!!offers?.length && (
            <aside>
              <TipList tips={offers} textOnTopOfImage />
            </aside>
          )}
        </LoaderContainer>
      </div>
    </div>
  );
}
