import { useTranslation } from 'react-i18next';
import { swedishCurrencyFormatter } from '../../utils/locale-utils';
import { ReactComponent as OpenIcon } from './open.svg';
import ApplicationOptions from '../../utils/options';
import HeaderHP from '../../components/Headers/HeaderHpLayout';
interface Props {
  estateAddress: string | undefined;
  estateArea: string | undefined;
  estateCity: string | undefined;
  estatePrice: number | undefined;
  priceText: string | undefined;
  linkUrl?: string | undefined;
  showHeader?: boolean | undefined;
}

export default function EstateInfo({
  estateAddress,
  estateArea,
  estateCity,
  estatePrice,
  priceText,
  linkUrl,
  showHeader,
}: Props): JSX.Element {
  const { t } = useTranslation('estate-info');
  const { t: tPrice } = useTranslation('price');

  return (
    <>
      {' '}
      {ApplicationOptions.themeLayout === 'HomePageLayout' && showHeader !== false ? (
        <>
          <HeaderHP
            estateAddress={estateAddress}
            linkUrl={linkUrl}
            estateArea={estateArea}
            estateCity={estateCity}
            estatePrice={estatePrice}
            priceText={priceText}
          />
        </>
      ) : (
        <div aria-label={t('section-description')} className="estate-info">
          {estateAddress && linkUrl ? (
            <a href={linkUrl} className="address-info">
              {estateCity && (
                <span className="estate-area" data-testid="estateArea">
                  {' '}
                  {estateArea && `${estateArea},`} {estateCity}{' '}
                </span>
              )}
              <span className="estate-address" data-testid="estateAddress">
                {' '}
                {estateAddress} <OpenIcon className="open-icon" data-testid="open-icon" />{' '}
              </span>
            </a>
          ) : (
            <div className="address-info">
              {estateCity && (
                <span className="estate-area" data-testid="estateArea">
                  {' '}
                  {estateArea && `${estateArea},`} {estateCity}{' '}
                </span>
              )}
              <span className="estate-address" data-testid="estateAddress">
                {' '}
                {estateAddress}{' '}
              </span>
            </div>
          )}
          <div className="estate-pricing">
            <span className="starting-price"> {priceText ? tPrice('price-statuses.' + priceText) : tPrice('default-price-text')} </span>{' '}
            <br />
            <span className="estate-price" data-testid="estatePrice">
              {estatePrice !== undefined ? swedishCurrencyFormatter.format(estatePrice) : t('no-price-set')}
            </span>
          </div>
        </div>
      )}
    </>
  );
}
