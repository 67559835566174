import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import securityService from '../../services/security-service';
import { subscribeToTopic } from '../../utils/message-hub';

interface Props {
  visible: boolean;
  closeCallback: () => void;
}

export default function ProfileMenu({ visible, closeCallback }: Props): JSX.Element {
  const { t } = useTranslation('profile-menu');
  const [addVisibleClass, setAddVisibleClass] = useState(false);
  const [name, setName] = useState('');
  const [impersonated, setImpersonated] = useState(false);
  const navigate = useNavigate();

  const logoutClick = (): void => {
    securityService.clearStoredAccessToken();
    navigate('/');
  };

  const closeClick = (): void => {
    setAddVisibleClass(false);
    closeCallback();
  };

  useEffect((): (() => void) => {
    if (!visible) {
      return (): void => void 0;
    }

    setName(securityService.getJwtName() ?? '');
    setImpersonated(securityService.isJwtImpersonated());

    const timeoutHandle = setTimeout(() => {
      setAddVisibleClass(true);
    }, 0);

    return (): void => clearTimeout(timeoutHandle);
  }, [visible]);

  useEffect((): (() => void) => {
    subscribeToTopic('fullscreen-page-opened', () => {
      setAddVisibleClass(false);
      closeCallback();
    });

    return (): void => void 0;
  }, [closeCallback]);

  return (
    <>
      {visible && (
        <section onClick={closeClick} className={`profile-menu ${addVisibleClass ? 'visible' : ''}`}>
          <ul>
            <li>
              <div className="name">{name}</div>
            </li>
            {!impersonated && (
              <li>
                <button className="logout-button" onClick={logoutClick}>
                  {t('logout-button')}
                </button>
              </li>
            )}
            <li>
              <button className="close-button">{t('close-button')}</button>
            </li>
          </ul>
        </section>
      )}
    </>
  );
}
