import { useCallback, useEffect, useRef, useState } from 'react';
import apiClient from '../services/api-client';
import { BankIdAuthStatus } from '../services/generated-api-client';
import { useAsyncError } from './useAsyncError';
import { useNavigate } from 'react-router-dom';

interface RemoteLogin {
  loginStarted: boolean;
  authenticationFailed: boolean;
  startLogin: () => Promise<BankIdAuthStatus>;
  stopAuthStatusCheck: () => void;
}

export default function useRemoteLogin(oidcScopeId: string, qrCodeUpdated?: (str: string) => void): RemoteLogin {
  const [loginStarted, setLoginStarted] = useState<boolean>(false);
  const [authenticationFailed, setAuthenticationFailed] = useState<boolean>(false);
  const { setError } = useAsyncError();
  const mountedRef = useRef<boolean>(true);
  const authStatusIntervalRef = useRef<NodeJS.Timer>();
  const navigate = useNavigate();

  const stopAuthStatusCheck = useCallback((): void => {
    if (authStatusIntervalRef.current == null) return;

    clearInterval(authStatusIntervalRef.current);
    setLoginStarted(false);
  }, []);

  useEffect(() => {
    return () => stopAuthStatusCheck();
  }, [stopAuthStatusCheck]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  /**
   * Calls api function startLogin and passes on BankIdAuthResponse in promise
   * @returns Promise resolving to pass along BankIdAuthResponse from api call startLogin. If rejected, passes along error
   */
  const startLogin = (): Promise<BankIdAuthStatus> =>
    new Promise<BankIdAuthStatus>((resolve, reject) => {
      apiClient.public
        .startLogin(oidcScopeId)
        .then((response) => {
          if (!mountedRef.current) return;

          setAuthenticationFailed(false);
          startAuthStatusCheck();
          setLoginStarted(true);
          resolve(response.data);

          if (response.data?.QrString && qrCodeUpdated) {
            qrCodeUpdated(response.data.QrString);
          }
        })
        .catch((error) => {
          reject(error);
          console.error(error);
          setError(error);
        });
    });

  const startAuthStatusCheck = (): void => {
    if (oidcScopeId == null) return;

    if (authStatusIntervalRef.current != null) return;
    authStatusIntervalRef.current = setInterval(() => {
      apiClient.public
        .getAuthStatus(oidcScopeId)
        .then((response) => {
          const data = response.data;
          if (!mountedRef.current) return;

          if (data?.QrString && qrCodeUpdated) {
            qrCodeUpdated(data.QrString);
          }

          if (data?.Success !== null) {
            stopAuthStatusCheck();

            if (data.Success) {
              navigate('/login-callback/' + oidcScopeId);
            } else {
              setAuthenticationFailed(true);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1500);
  };

  return {
    loginStarted,
    authenticationFailed,
    startLogin,
    stopAuthStatusCheck,
  };
}
