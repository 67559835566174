import { EffectCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuySellTip } from '../../services/generated-api-client';
import ApiClient from '../../services/api-client';
import Tip from '../../components/Tip/Tip';
import List from '../../components/List/List';
import { useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import LoaderContainer, { ListLoader } from '../../components/Loader';

export default function OffersPage(): JSX.Element {
  const { t } = useTranslation('estate-page');
  const { estateId } = useParams();
  const [offers, setOffers] = useState<BuySellTip[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect((): ReturnType<EffectCallback> => {
    ApiClient.authenticated
      .getOffersForEstate(estateId!, { cancelToken: 'getOffersForEstate' })
      .then((response) => setOffers(response.data))
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      ApiClient.abortRequest('getOffersForEstate');
    };
  }, [estateId]);

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ListLoader />
        </>
      }
    >
      <div className="inner-content offers-page">
        <h1 className="page-heading">{t('offers-title')}</h1>
        <div className="collection-content">
          <List>
            {!!offers.length &&
              offers.map((offer) => (
                <Tip
                  key={offer.Uid}
                  title={offer.Title}
                  description={offer.Description}
                  linkText={offer.LinkText}
                  linkUrl={offer.LinkUrl}
                  image={offer.ImageUrl}
                />
              ))}
          </List>
          {!offers.length && <Alert type="info" text={t('no-offers')} />}
        </div>
      </div>
    </LoaderContainer>
  );
}
