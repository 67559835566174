//import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  text: string;
}

export default function Infobox({ title, text }: Props): JSX.Element {
  return (
    <div className="copy-to-clipboard" data-testid="infobox">
      <p className="title"> {title} </p>
      <button data-testid="button">
        <span> {text} </span>
      </button>
    </div>
  );
}
