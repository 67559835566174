import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatSwedishDate, swedishCurrencyFormatter } from '../../utils/locale-utils';
import PriceDifference from '../Price/PriceDifference';

interface Props {
  image: string | undefined;
  address: string | undefined;
  priceStatus: string | undefined;
  price: number | undefined;
  priceDifference: number | undefined;
  date: string | null | undefined;
  route: string;
}

export default function EstateLink({ image, address, priceStatus, price, priceDifference, date, route }: Props): JSX.Element {
  const { t } = useTranslation('estate-link');
  const { t: tPrice } = useTranslation('price');

  return (
    <Link to={route} className="estate-link" data-testid="estate-link">
      {image && <img role="presentation" src={image} alt="" />}
      <div className="information">
        <span className="address"> {address ?? t('default-address-text')} </span>
        {price && (
          <span className="price-text" data-testid="price-text">
            {priceStatus ? tPrice('price-statuses.' + priceStatus) : tPrice('default-price-text')} {swedishCurrencyFormatter.format(price)}
          </span>
        )}
        {priceDifference ? <PriceDifference difference={priceDifference} /> : null}
        {date ? (
          <div className="date-sold" data-testid="estate-sold">
            {' '}
            {t('sold')} {formatSwedishDate(date, 'short')}{' '}
          </div>
        ) : (
          <div className="in-progress" data-testid="estate-status">
            {' '}
            {t('in-progress')}{' '}
          </div>
        )}
      </div>
    </Link>
  );
}
