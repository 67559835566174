import { Route, Routes } from 'react-router-dom';
import ProcessNavBar from '../../components/ProcessNavBar/ProcessNavBar';
import ProcessStep from './ProcessStep';
import useProcessPage from './useProcessPage';

interface Props {
  currentStep: string | undefined;
}

export default function ProcessPage({ currentStep }: Props): JSX.Element {
  const { getSteps } = useProcessPage();

  return (
    <section className="process-page" data-testid="process-page">
      <ProcessNavBar steps={getSteps(currentStep ?? '')} />
      <Routes>
        <Route path=":step" element={<ProcessStep />} />
      </Routes>
    </section>
  );
}
