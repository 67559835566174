import { useTranslation } from 'react-i18next';
import { formatNumberWithFractionDigits } from '../../utils/locale-utils';

interface Props {
  difference: number;
}

export default function PriceDifference({ difference }: Props): JSX.Element {
  const { t } = useTranslation('price');

  function getLabel(): string {
    return difference > 0 ? t('price-increased', { difference: difference }) : t('price-decreased', { difference: Math.abs(difference) });
  }

  if (difference === 0) {
    return <></>;
  }

  return (
    <span className="price-difference" aria-label={getLabel()}>
      {difference > 0 ? '↑' : '↓'}
      {formatNumberWithFractionDigits(difference, 0, 2)}%
    </span>
  );
}
