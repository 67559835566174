export const swedishCurrencyFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const swedishDateLong: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'long',
  day: '2-digit',
};

const swedishDateLongFormat = new Intl.DateTimeFormat('sv-SE', swedishDateLong);

const swedishTime: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

const swedishTimeFormat = new Intl.DateTimeFormat('sv-SE', swedishTime);

const swedishDateShort: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

const swedishDateShortFormat = new Intl.DateTimeFormat('sv-SE', swedishDateShort);

const swedishDateCompact: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
};

const swedishDateCompactFormat = new Intl.DateTimeFormat('sv-SE', swedishDateCompact);

export function formatSwedishDate(date: string | undefined, type: 'long' | 'short' | 'compact' | 'time' = 'long'): string | null {
  const parsedDate = Date.parse(date ?? '');
  if (isNaN(parsedDate)) {
    console.warn('Invalid date format received: ' + date);
    return null;
  }

  switch (type) {
    case 'long':
      return swedishDateLongFormat.format(parsedDate);

    case 'time':
      return swedishTimeFormat.format(parsedDate);

    case 'short':
      return swedishDateShortFormat.format(parsedDate);

    case 'compact':
      return swedishDateCompactFormat.format(parsedDate);
  }
}

export const swedishNumberFormatter = new Intl.NumberFormat('sv-SE');

export function formatNumberWithFractionDigits(number: number, minimumFractionDigits: number, maximumFractionDigits: number): string {
  const numberFormatter = new Intl.NumberFormat('sv-SE', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
  return numberFormatter.format(number);
}
