import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProcessStep } from '../../components/ProcessNavBar/ProcessNavBar';
import Steps from '../../constants/steps';
import Views from '../../constants/views';

const sellStepTitles = [
  Steps.EstateData,
  Steps.Preparations,
  Steps.Marketing,
  Steps.ViewingBidding,
  Steps.Contract,
  Steps.DownPayment,
  Steps.Entry,
  Steps.AfterEntry,
];

const buyStepTitles = [Steps.EstateData, Steps.Contract, Steps.DownPayment, Steps.Entry, Steps.AfterEntry];

interface Process {
  getSteps: (currentStep: string) => ProcessStep[];
}

export default function useProcessPage(): Process {
  const { view } = useParams();
  const { t } = useTranslation('process');

  function getSteps(currentStep: string): ProcessStep[] {
    const stepTitles = view?.toUpperCase() === Views.Sell.toUpperCase() ? sellStepTitles : buyStepTitles;

    const steps: ProcessStep[] = stepTitles.map((stepTitle) => {
      return {
        title: t(`step-titles.${stepTitle}`),
        route: stepTitle,
        isCurrentStep: stepTitle.toUpperCase() === currentStep.toUpperCase(),
      };
    });

    return steps;
  }

  return { getSteps };
}
