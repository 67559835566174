import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as MailIcon } from './mail.svg';
import { useTranslation } from 'react-i18next';
import ApplicationOptions from './../../utils/options';

interface Props {
  image: string | undefined;
  name: string | undefined;
  title: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
}

export default function Broker({ image, name, title, phoneNumber, email }: Props): JSX.Element {
  const { t } = useTranslation('broker');

  const mailTo = 'mailto:' + email + '?subject=' + t('email-subject');
  const phoneTo = 'tel:' + phoneNumber;

  return (
    <div className="broker" data-testid="broker">
      {image && <img src={image} role="presentation" alt="" />}
      <div className="content">
        {name && <div className="name"> {name} </div>}
        {title && <div className="title">{title}</div>}
        <div className="contact-info">
          {phoneNumber && (
            <div className="phone-number">
              {ApplicationOptions.themeLayout === 'HomePageLayout' && ApplicationOptions.theme !== 'erik-olsson' ? (
                <div className="icon-lable">{t('phone-label') + ': '}</div>
              ) : (
                <PhoneIcon className="phone-icon" aria-label={t('phone-label')} />
              )}
              <a href={phoneTo}> {phoneNumber} </a>
            </div>
          )}
          {email && (
            <div className="email">
              {ApplicationOptions.themeLayout === 'HomePageLayout' && ApplicationOptions.theme !== 'erik-olsson' ? (
                <div className="icon-lable">{t('email-label') + ':'}</div>
              ) : (
                <MailIcon className="mail-icon" aria-label={t('email-label')} />
              )}
              <a href={mailTo}> {email} </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
