import { useTranslation } from 'react-i18next';
import { BuySellTip, BuySellBroker } from '../../services/generated-api-client';
import Broker from '../Broker/Broker';
import TipList from '../Lists/TipList';

interface Props {
  broker: BuySellBroker | undefined;
  tips: BuySellTip[] | undefined;
}

export default function ProcessStepAside({ broker, tips }: Props): JSX.Element {
  const { t: tProcess } = useTranslation('process');

  return (
    <aside className="process-aside">
      {!!broker && (
        <Broker name={broker.Name} title={broker.Title} phoneNumber={broker.Phone} email={broker.Mail} image={broker.ImageUrl} />
      )}

      {!!tips?.filter((tip) => tip.Type === 'Offer').length && (
        <TipList tips={tips.filter((tip) => tip.Type === 'Offer')} textOnTopOfImage />
      )}

      {!!tips?.filter((tip) => tip.Type === 'Tip').length && (
        <TipList tips={tips.filter((tip) => tip.Type === 'Tip')} title={tProcess('tip-title')} />
      )}
    </aside>
  );
}
