import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiClient from '../../services/api-client';

enum DownloadStatus {
  NotDownloading = 1,
  DownloadingImage,
  PendingDownloadImage,
}

interface Props {
  downloadUrls: string[];
  names: string[];
}

export default function DownloadImagesButton({ downloadUrls, names }: Props): JSX.Element {
  const { t } = useTranslation('download-images-button');
  const [status, setStatus] = useState<DownloadStatus>(DownloadStatus.NotDownloading);
  const [downloadImageNbr, setDownloadImageNbr] = useState<number>(0);
  const [downloadsUid, setDownloadsUid] = useState<string>('');
  const [cancelled, setCancelled] = useState<boolean>(false);

  const saveAllImages = (): void => {
    setStatus(DownloadStatus.DownloadingImage);
    setCancelled(false);
    setDownloadImageNbr(0);

    apiClient.public.startNewDownloads({ maxNumberOfDownloads: 1 }).then((downloadsUid) => {
      setDownloadsUid(downloadsUid.data);
      saveFile(downloadsUid.data, 0, 0);
    });
  };

  const cancelDownload = (): void => {
    setCancelled(true);
    setStatus(DownloadStatus.NotDownloading);
  };

  const downloadNextImage = (): void => {
    setStatus(DownloadStatus.DownloadingImage);
    saveFile(downloadsUid, downloadImageNbr, 0);
  };

  const saveFile = (downloadsUid: string, idx: number, counter: number): void => {
    const maxCount = 500;
    if (cancelled) {
      setStatus(DownloadStatus.NotDownloading);
      return;
    } else if (counter > maxCount) {
      alert('Reached maximum number of download requests(' + maxCount + ')');
      setStatus(DownloadStatus.NotDownloading);
    } else if (idx >= downloadUrls.length) {
      setStatus(DownloadStatus.NotDownloading);
    } else {
      apiClient.public
        .startNewDownload(downloadsUid)
        .then((downloadUid) => {
          if (!downloadUid?.data) {
            setTimeout(() => saveFile(downloadsUid, idx, counter + 1), 1000);
          } else {
            const imgUrl = downloadUrls[idx] + (downloadUrls[idx].indexOf('?') > 0 ? '&' : '?') + 'downloadUid=' + downloadUid.data;
            const name = names[idx];
            //Trick:  To override same-site limit on a normal download-link, we download image separatly and create a objectUrl on local page. This will force browser to download instead of navigate
            fetch(imgUrl)
              .then((resp) => resp.blob())
              .then((blob) => {
                setDownloadImageNbr(idx);
                setStatus(DownloadStatus.DownloadingImage);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setTimeout(() => saveFile(downloadsUid, idx + 1, counter + 1), 20);
                //setDownloadImageNbr(idx + 1);
                //setStatus(DownloadStatus.PendingDownloadImage);
              })
              .catch((e) => {
                console.log(e);
                setDownloadImageNbr(idx);
                setStatus(DownloadStatus.PendingDownloadImage);
              });
          }
        })
        .catch((e) => {
          if (e.name !== 'TypeError') {
            console.log(e);
          }
          setDownloadImageNbr(idx);
          setStatus(DownloadStatus.PendingDownloadImage);
        });
    }
  };

  return (
    <>
      {status === DownloadStatus.NotDownloading && <button onClick={saveAllImages}>{t('download-images')}</button>}
      {status === DownloadStatus.DownloadingImage && (
        <button onClick={cancelDownload}>{t('downloading-images', { count: downloadImageNbr + 1 })}</button>
      )}
      {status === DownloadStatus.PendingDownloadImage && (
        <button onClick={downloadNextImage}>{t('download-image', { count: downloadImageNbr + 1 })}</button>
      )}
    </>
  );
}
