import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  displayHomepageLink?: boolean;
}

export default function ErrorPage({ text, displayHomepageLink = true }: Props): JSX.Element {
  const { t } = useTranslation('error');

  return (
    <div className="error">
      <h1>{text}</h1>
      {displayHomepageLink && <Link to="/">{t('link-text')}</Link>}
    </div>
  );
}
