import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../services/api-client';
import { ErrorInfo } from '../../services/generated-api-client';
import { useParams } from 'react-router-dom';

export default function BackendErrorPage(): JSX.Element {
  const { estateUid } = useParams();
  const { errorCode } = useParams();
  const { t } = useTranslation('error');
  const [content, setContent] = useState<ErrorInfo>({
    ErrorCode: parseInt(errorCode ?? ''),
    Title: t('app-error'),
    Description: errorCode,
  });

  useEffect(() => {
    ApiClient.public.getErrorInfo(estateUid ?? '00000000-0000-0000-0000-000000000000', parseInt(errorCode ?? '')).then((response) => {
      setContent(response.data);
    });

    return (): void => {
      ApiClient.abortRequest('getBuyAfterEntryView');
    };
  }, [errorCode, estateUid]);

  return (
    <div className="error">
      <h1>{content?.Title}</h1>
      <span>{content?.Description}</span>
    </div>
  );
}
