interface Props {
  value: string;
  label: string;
  name: string;
  onChange: (inputValue: string, inputName: string) => void;
  placeholder?: string;
  errorMode?: boolean;
  required?: boolean;
  type?: 'text' | 'textarea' | 'tel' | 'email';
}

export default function TextInput({
  value,
  label,
  name,
  onChange,
  placeholder,
  errorMode = false,
  required = false,
  type = 'text',
}: Props): JSX.Element {
  return (
    <div className="text-input-container">
      <label htmlFor={`input-${name}`}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      {type !== 'textarea' ? (
        <input
          id={`input-${name}`}
          className={errorMode ? 'error' : ''}
          name={name}
          aria-required={required}
          aria-invalid={errorMode}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e): void => onChange(e.target.value, e.target.name)}
        />
      ) : (
        <textarea
          id={`input-${name}`}
          className={errorMode ? 'error' : ''}
          name={name}
          aria-required={required}
          aria-invalid={errorMode}
          value={value}
          placeholder={placeholder}
          onChange={(e): void => onChange(e.target.value, e.target.name)}
        />
      )}
    </div>
  );
}
