import { EffectCallback, lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import securityService from './services/security-service';
import Home from './pages/Home/Home';
import ApiClient from './services/api-client';
import TopNavBar from './components/TopNavBar/TopNavBar';
import FooterSMH from './components/Footers/FooterSMH';
import FooterFastighetsm from './components/Footers/FooterFastighetsm';
import EstatePage from './pages/Estate/EstatePage';
import NotificationsPage from './pages/Notifications/NotificationsPage';
import ErrorPage from './pages/Error/ErrorPage';
import { subscribeToTopic } from './utils/message-hub';
import BiddingPage from './pages/Bidding/BiddingPage';
import BiddingConstants from './constants/bidding';
import ChooseLoanCommitmentBank from './pages/ChooseLoanCommitmentBank/ChooseLoanCommitmentBank';
import StartPage from './pages/StartPage/StartPage';
import OnboardingPage from './pages/Onboarding/OnboardingPage';
import LoginRedirect from './pages/Login/LoginRedirect';
import ApplicationOptions from './utils/options';

const ComponentGallery = lazy(() => import('./pages/ComponentGallery/ComponentGallery'));

const MS_PER_MINUTE = 60000;
const NOTIFICATION_CHECK_INTERVAL = MS_PER_MINUTE * 0.2;

function App(): JSX.Element {
  const { t } = useTranslation('error');
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
  const [fullscreenPageOpen, setFullscreenPageOpen] = useState<boolean>(false);
  const [notificationToken, setNotificationToken] = useState<string>('abc');
  const navigate = useNavigate();

  useEffect((): ReturnType<EffectCallback> => {
    const updateUnreadNotifications = (): void => {
      const accessToken = securityService.getAccessToken();
      if (accessToken == null) {
        return;
      }
      ApiClient.authenticated
        .getNotificationsChangeToken()
        .then((response) => {
          if (notificationToken === response.data) {
            return;
          }
          setNotificationToken(response.data);
          ApiClient.authenticated.hasUnreadNotifications().then((response) => {
            if (!response.data) {
              return;
            }
            setHasNewNotifications(true);
          });
        })
        .catch((e) => {
          if (e.status === 401) {
            ApiClient.public
              .refreshToken(accessToken)
              .then((jwt) => {
                if (jwt.status === 200) {
                  const accessToken = jwt.data;
                  if (accessToken) {
                    const parsedAccessToken = securityService.parseAccessToken(jwt.data);
                    if (parsedAccessToken) {
                      if (securityService.validateJwtToken(parsedAccessToken)) {
                        securityService.storeAccessToken(accessToken);
                        return;
                      }
                    }
                  }
                }
                securityService.clearStoredAccessToken();
                navigate('/login');
              })
              .catch(() => {
                securityService.clearStoredAccessToken();
                navigate('/login');
              });
          } else {
            throw e;
          }
        });
    };

    if (securityService.isAuthenticated) {
      updateUnreadNotifications();

      const intervalHandle = setInterval(() => updateUnreadNotifications(), NOTIFICATION_CHECK_INTERVAL);

      return (): void => clearInterval(intervalHandle);
    }
  }, [navigate, notificationToken]);

  useEffect((): ReturnType<EffectCallback> => {
    const unsubscribe = subscribeToTopic('notifications-read', () => setHasNewNotifications(false));

    return (): void => unsubscribe();
  }, []);

  useEffect((): (() => void) => {
    const unsubscribeFullscreenPageOpened = subscribeToTopic('fullscreen-page-opened', () => {
      setFullscreenPageOpen(true);
    });
    const unsubscribeFullscreenPageClosed = subscribeToTopic('fullscreen-page-closed', () => {
      setFullscreenPageOpen(false);
    });

    return (): void => {
      unsubscribeFullscreenPageOpened();
      unsubscribeFullscreenPageClosed();
    };
  }, []);
  // ta bort false innan check-in
  return (
    <>
      {securityService.isAuthenticated ? (
        <>
          {!fullscreenPageOpen && (
            <header className="main-header">
              <TopNavBar hasNewNotifications={hasNewNotifications} />
            </header>
          )}
          <main>
            <Suspense fallback={null}>
              <Routes>
                <Route index element={<Home />} />
                <Route path="onboarding/:source/:contactId/:view/:estateId" element={<OnboardingPage />} />
                <Route path="login/*" element={<LoginRedirect />} />
                <Route path="login-callback/*" element={<LoginRedirect />} />
                <Route path=":view/:estateId/*" element={<EstatePage />} />
                <Route path={`${BiddingConstants.BiddingPath}/:estateId`} element={<BiddingPage />} />
                <Route path={`${BiddingConstants.BiddingPath}/:estateId/choose-bank`} element={<ChooseLoanCommitmentBank />} />
                <Route path="gallery" element={<ComponentGallery />} />
                <Route path="notifications" element={<NotificationsPage />} />
                <Route path="*" element={<ErrorPage text={t('page-not-found')} />} />
              </Routes>
            </Suspense>
          </main>
          {ApplicationOptions.theme === 'svenska-maklarhuset' && !fullscreenPageOpen ? <FooterSMH /> : ''}
          {ApplicationOptions.theme === 'fastighetsmaklarna' && !fullscreenPageOpen ? <FooterFastighetsm /> : ''}
        </>
      ) : (
        <StartPage />
      )}
    </>
  );
}
export default App;
