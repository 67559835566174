import { ReactComponent as CalendarIcon } from './calendaricon.svg';

interface Props {
  title: string | undefined;
  pushText: string | undefined;
  subText: string | undefined;
  subTextLink: string | undefined;
}

export default function Event({ title, pushText, subText, subTextLink }: Props): JSX.Element {
  return (
    <div className="event" data-testid="event">
      <CalendarIcon className="icon" role="presentation" />
      {title && <div className="title"> {title} </div>}
      {pushText && <div className="push-text"> {pushText} </div>}
      {subText && (
        <div className="sub-text">
          {subTextLink ? (
            <a href={subTextLink} className="text-link">
              {subText}
            </a>
          ) : (
            subText
          )}
        </div>
      )}
    </div>
  );
}
