export default function FooterSMH(): JSX.Element {
  return (
    <>
      <footer>
        <div className="footer">
          <img
            className="footer_img"
            src="https://statistik.boostad.net/img/FASTIGHET/IMAGE/fastighetsm_footer.svg"
            alt="city landscape graphic element"
          />
        </div>
      </footer>
    </>
  );
}
