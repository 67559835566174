import { Link, useParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  items: number;
  completedItems: number;
  checklistId: string;
}

export default function ChecklistLink({ name, items, completedItems, checklistId }: Props): JSX.Element {
  const { t } = useTranslation('checklist-link');

  const { view, estateId, step } = useParams();
  const query = step ? `?returnToStep=${step}` : '';
  const route = `/${view}/${estateId}/checklists/${checklistId}${query}`;

  let classNames = 'items';
  if (items === completedItems && items > 0) {
    classNames += ' checklist-finished';
  } else if (completedItems > 0) {
    classNames += ' checklist-started';
  }

  let itemText = `${completedItems}/${items}`;
  if (completedItems === 0) {
    itemText = items.toString();
  }

  return (
    <Link to={route} className="checklist-link" data-testid="checklist-link">
      <span className="title"> {name} </span>
      <div className="content">
        <span className={classNames}> {itemText} </span>
        <ArrowIcon aria-label={t('link-label', { title: name })} />
      </div>
    </Link>
  );
}
