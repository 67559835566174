import { ReactComponent as NotificationIcon } from './notification.svg';
import { useTranslation } from 'react-i18next';
import { formatSwedishDate } from '../../utils/locale-utils';

interface Props {
  title: string | undefined;
  text: string | undefined;
  date: string | undefined;
  sender: string | undefined;
  isRead: boolean;
}

export default function Notification({ title, text, date, sender, isRead }: Props): JSX.Element {
  const { t } = useTranslation('notification');

  return (
    <div className="notification" data-testid="notification">
      <div>
        <NotificationIcon
          className={isRead ? 'icon' : 'icon unread'}
          role="img"
          aria-label={isRead ? t('read-notification') : t('unread-notification')}
        />
      </div>
      <div className="content">
        <div className="title"> {title ? title : t('no-title')} </div>
        <p className="text"> {text} </p>
        <span className="date">
          {' '}
          {formatSwedishDate(date || '', 'short')} {formatSwedishDate(date || '', 'time')}{' '}
        </span>
        <span className="sender"> &middot; {sender ? sender : t('no-sender')} </span>
      </div>
    </div>
  );
}
