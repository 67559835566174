import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  DoughnutControllerChartOptions,
  ChartData,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { BuySellMarketingChannelImpressions } from '../../services/generated-api-client';
import { getChartColors } from '../../utils/chart-utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

interface ViewsPerChannelChart {
  getChartOptions: () => _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      DoughnutControllerChartOptions &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions<'doughnut'>
  >;
  getChartData: (channels: BuySellMarketingChannelImpressions[]) => ChartData<'doughnut', number[], unknown>;
  getTotalViews: (channels: BuySellMarketingChannelImpressions[]) => number;
  getChannels: (channels: BuySellMarketingChannelImpressions[]) => Channel[];
}

interface Channel {
  name: string;
  value: number;
  color: string;
}

export default function useViewsPerChannel(): ViewsPerChannelChart {
  const getChannels = (channels: BuySellMarketingChannelImpressions[]): Channel[] => {
    const colors = getChartColors();

    const names = channels.map((channelName, i) => {
      return {
        name: channelName.Name ?? '',
        value: channelName.Value ?? 0,
        color: colors[i % colors.length],
      };
    });

    return names;
  };

  const getTotalViews = (channels: BuySellMarketingChannelImpressions[]): number => {
    return channels.reduce((current, total) => (current += total.Value ?? 0), 0);
  };

  const getChartData = (channels: BuySellMarketingChannelImpressions[]): ChartData<'doughnut', number[], unknown> => {
    const channelNames = channels.map((channel) => {
      return [channel.Name];
    });
    const channelValues = channels.map((channel) => {
      return channel.Value ?? 0;
    });

    const backgroundColors = [
      ...channels.map((channel) => getChannels(channels).filter((bidder) => bidder.name === channel.Name)[0].color),
    ];

    const data: ChartData<'doughnut', number[], unknown> = {
      labels: channelNames,
      datasets: [
        {
          data: channelValues,
          borderWidth: 1,
          backgroundColor: backgroundColors,
          borderColor: 'white',
        },
      ],
    };

    return data;
  };

  const getChartOptions = (): _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      DoughnutControllerChartOptions &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions<'doughnut'>
  > => {
    return {
      cutout: '75%', //tjocklek på doughnut
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  };

  return { getChartData, getChartOptions, getTotalViews, getChannels };
}
