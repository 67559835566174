import React, { FunctionComponent } from 'react';
import { GroupLayout, LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';
import { VanillaRendererProps, withVanillaControlProps } from '@jsonforms/vanilla-renderers';

export const formGroupTester: RankedTester = rankWith(10, uiTypeIs('Group'));

export const GroupLayoutRenderer = (props: LayoutProps & VanillaRendererProps): JSX.Element => {
  const { data: _data, ...otherProps } = props;
  // We don't hand over data to the layout renderer to avoid rerendering it with every data change
  return <GroupLayoutRendererComponent {...otherProps} />;
};

interface UISchemaElementLocal {
  scope: string;
  label: string;
  description: string;
}

const GroupLayoutRendererComponent: FunctionComponent<LayoutProps & VanillaRendererProps> = React.memo(
  function GroupLayoutRendererComponent({
    schema,
    uischema,
    path,
    enabled,
    visible,
    getStyle,
    getStyleAsClassName,
  }: LayoutProps & VanillaRendererProps) {
    const group = uischema as GroupLayout;
    const { renderers, cells, i18n } = useJsonForms();
    const elementsSize = group.elements ? group.elements.length : 0;
    const classNames = getStyleAsClassName!('group.layout');
    const childClassNames = ['group-layout-item'].concat(getStyle!('group.layout.item', elementsSize)).join(' ');

    const uiSchemaLocal = uischema as unknown as UISchemaElementLocal;

    function translate(key?: string): string {
      if (i18n) {
        if (i18n.translate) {
          if (key) {
            return i18n.translate(key) ?? '';
          }
        }
      }
      return key ?? '';
    }
    const translatedLabel = translate(uiSchemaLocal.label);
    const translatedDescription = translate(uiSchemaLocal.description);
    const descriptionAdditionKey = uiSchemaLocal.description + '_ADDITION';
    const translatedDescriptionAdditionTmp = translate(descriptionAdditionKey);
    const translatedDescriptionAddition =
      translatedDescriptionAdditionTmp === descriptionAdditionKey ? null : translatedDescriptionAdditionTmp;

    return (
      <fieldset className={classNames} hidden={visible === undefined || visible === null ? false : !visible}>
        {translatedLabel && <legend className={getStyleAsClassName!('group.label')}>{translatedLabel}</legend>}
        {translatedDescription && <div className="group-description">{translatedDescription}</div>}
        {translatedDescriptionAddition && <div className="group-description-addition">{translatedDescriptionAddition}</div>}
        {group.elements.map((child, index) => {
          return (
            <div className={childClassNames} key={`${path}-${index}`}>
              <JsonFormsDispatch renderers={renderers} cells={cells} uischema={child} schema={schema} path={path} enabled={enabled} />
            </div>
          );
        })}
      </fieldset>
    );
  },
);

export default withVanillaControlProps(withJsonFormsLayoutProps(GroupLayoutRenderer));
