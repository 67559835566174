import List from '../List/List';
import { DocumentDocument } from '../../services/generated-api-client';
import DocumentLink from '../DocumentLink/DocumentLink';

interface Props {
  documents: DocumentDocument[];
  title: string;
}

export default function DocumentList({ documents, title }: Props): JSX.Element {
  return (
    <List title={title}>
      {documents.map(
        (document) => document.Name && document.Url && <DocumentLink key={document.Uid} title={document.Name} link={document.Url} />,
      )}
    </List>
  );
}
