import { useEffect, useState } from 'react';
import StepDescription from '../../components/StepDescription/StepDescription';
import ChecklistList from '../../components/Lists/ChecklistList';
import Carousel from '../../components/Carousel/Carousel';
import NumberInfo from '../../components/NumberInfo/NumberInfo';
import ExternalListing from '../../components/ExternalListing/ExternalListing';
import Error from '../Error/ErrorPage';
import ApiClient from '../../services/api-client';
import { BuySellSellSellMarketingView, BuySellSellSellMarketingViewGraph } from '../../services/generated-api-client';
import LoaderContainer, { ImageBoxLoader, ListLoader } from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import List from '../../components/List/List';
import NumberInfoContainer from '../../components/NumberInfo/NumberInfoContainer';
import ProcessStepAside from '../../components/ProcessStepAside/ProcessStepAside';
import DocumentList from '../../components/Lists/DocumentList';
import FormList from '../../components/Lists/FormList';
import ApplicationOptions from './../../utils/options';
import ImpressionsContainer from '../../components/Impressions/ImpressionsContainer';
import Spinner from '../../components/Spinner/Spinner';

import ImpressionsChart from '../../components/Impressions/ImpressionsChart/ImpressionsChart';
import ImpressionsPerChannel from '../../components/Impressions/ImpressionsPerChannel/ImpressionsPerChannel';
import ViewsPerChannel from '../../components/ViewsPerChannel/ViewsPerChannel';

export default function MarketingPage(): JSX.Element {
  const { t } = useTranslation('sell-marketing-page');
  const { t: tProcess } = useTranslation('process');
  const [content, setContent] = useState<BuySellSellSellMarketingView>();
  const [graphData, setGraphData] = useState<BuySellSellSellMarketingViewGraph>();
  const [loading, setLoading] = useState<boolean>(true);
  const [graphDataLoaded, setGraphDataLoaded] = useState<boolean>(false);
  const { estateId } = useParams();
  const step = 'Marketing';

  useEffect(() => {
    console.log('useEffect1');
    ApiClient.authenticated
      .getSellMarketingView(estateId!, { cancelToken: 'getSellMarketingView' })
      .then((response) => {
        console.log('setContent');
        setContent(response.data);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return;
        }
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [estateId]);

  useEffect(() => {
    console.log('useEffect2');
    if (!!content) {
      console.log('loading graph');
      ApiClient.authenticated
        .getSellMarketingViewGraph(estateId!, {}, { cancelToken: 'getSellMarketingViewGraph' })
        .then((response) => {
          console.log('setGraphData');
          // setGraphData({
          //   WebsiteViewsPerChannel: [{
          //     Name: 'test',
          //     Value: 100
          //   }]
          // });
          setGraphData(response.data);
        })
        .catch((e) => {
          setGraphData({});
          if (e.name === 'AbortError') {
            return;
          }
          console.error(e);
        });
    }
  }, [estateId, content]);

  useEffect(() => {
    console.log('useEffect3');
    if (graphData) {
      setGraphDataLoaded(true);
    }
  }, [graphData]);

  function hasSlides(): boolean {
    if (graphData) {
      if (graphData.Impressions) {
        if (graphData.Impressions.length > 0) {
          if (graphData.Impressions[0].DataPoints) {
            if (graphData.Impressions[0].DataPoints.length > 0) {
              return true;
            }
          }
        }
      }
      if (graphData.ImpressionsPerChannel) {
        if (graphData.ImpressionsPerChannel.length > 0) {
          return true;
        }
      }
      if (graphData.WebsiteViewsPerChannel) {
        if (graphData.WebsiteViewsPerChannel.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <LoaderContainer
      loading={loading}
      loaders={
        <>
          <ImageBoxLoader />
          <ListLoader />
          <ImageBoxLoader />
        </>
      }
    >
      {content ? (
        <div className="process-step">
          <h1 className="visually-hidden">{tProcess('step-titles.Marketing')}</h1>

          <div className="process-content">
            {!!content.Description && (
              <StepDescription step={step} title={content.Description.Title} description={content.Description.Description} />
            )}

            {!graphDataLoaded && (
              <ImpressionsContainer title="">
                <div className="center">
                  <Spinner enabled={!graphDataLoaded}></Spinner>
                  {t('loading')}
                </div>
              </ImpressionsContainer>
            )}
            {!!graphDataLoaded && (
              <>
                {!hasSlides() && (
                  <ImpressionsContainer title="">
                    <div className="center">{t('no-data')}</div>
                  </ImpressionsContainer>
                )}
                {hasSlides() && !ApplicationOptions.onlyViewsPerChannel && (
                  <Carousel
                    slides={[
                      graphData?.Impressions?.length && <ImpressionsChart datasets={graphData.Impressions} />,
                      graphData?.ImpressionsPerChannel?.length && (
                        <ImpressionsPerChannel channelImpressions={graphData.ImpressionsPerChannel} />
                      ),
                      graphData?.WebsiteViewsPerChannel?.length && <ViewsPerChannel channels={graphData.WebsiteViewsPerChannel} />,
                    ].filter((x) => !!x)}
                  />
                )}

                {hasSlides() && ApplicationOptions.onlyViewsPerChannel && graphData?.WebsiteViewsPerChannel?.length && (
                  <ViewsPerChannel channels={graphData.WebsiteViewsPerChannel} />
                )}
              </>
            )}

            <NumberInfoContainer>
              {content.NumberOfInterestedPeople !== null && (
                <NumberInfo title={t('interest')} number={content.NumberOfInterestedPeople} description={t('interest-description')} />
              )}
              {content.TotalNumberOfBookedViewings !== null && (
                <NumberInfo title={t('viewings')} number={content.TotalNumberOfBookedViewings} description={t('viewings-description')} />
              )}
            </NumberInfoContainer>

            {!!content.ExternalListings?.length && (
              <List title={t('external-listing-title')}>
                {content.ExternalListings.map((externalListing) => (
                  <ExternalListing
                    key={externalListing.Url!}
                    name={t('external-listings.' + externalListing.IconUrl!)}
                    linkUrl={externalListing.Url!}
                    type={externalListing.IconUrl!}
                  />
                ))}
              </List>
            )}

            {!!content.Checklists?.length && <ChecklistList checklists={content.Checklists} title={tProcess('checklist-title')} />}

            {!!content.Documents?.length && <DocumentList documents={content.Documents} title={tProcess('documents-title')} />}

            {!!content.Forms?.length && <FormList forms={content.Forms} title={tProcess('forms-title')} />}
          </div>

          <ProcessStepAside broker={content.Broker} tips={content.Tips} />
        </div>
      ) : (
        <Error text={t('content-load-error-message')} />
      )}
    </LoaderContainer>
  );
}
